import { formatDate, getLastSeen } from "../../utils/functions/formatDate";

import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCreator } from "../../store/creator";
import { apiUrl } from "../../utils/config";
import { usePrepPath } from "../../utils/routeHandler";
import DeleteDialog from "../delete-dialog";

export default function CreatorsTable({ tableData, addCreatorHandle }) {
  const dispatch = useDispatch();

  const users = tableData;
  const deletePubModalRef = useRef("");
  const navigate = useNavigate();
  const creatorDetailsPath = usePrepPath("details/");
  const redirectTocreatorDetails = (id) => navigate(creatorDetailsPath + id);

  const [creatorId, setCreatorId] = useState();

  function handleDeleteCreator() {
    dispatch(
      deleteCreator({
        id: creatorId,
      })
    );
    return deletePubModalRef.current.toggleModal(false);
  }

  return (
    <div className="sm:px-6 py-8">
      <DeleteDialog
        ref={deletePubModalRef}
        title="Delete Publisher"
        text="Are you sure you want to DELETE this publisher? This publishers will be be permanently removed from the database. This action cannot be undone."
        handleDelete={() => handleDeleteCreator()}
      />
      <div className="sm:flex sm:items-center justify-between">
        <p className="mt-2 text-sm text-gray-700">
          View and manage all creators on the platform
        </p>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={addCreatorHandle}
            type="button"
            className="block rounded-md bg-[#000066] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:[#1a1a75] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add Publisher
          </button>
        </div>
      </div>
      <div className=" flow-root">
        <div className="-mx-4 -my-2  overflow-x-auto  sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date joined
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Last Seen
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users?.map((creator) => (
                  <tr key={creator.user.email}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img
                            className="h-11 w-11 rounded-full object-cover"
                            src={
                              creator.user?.profile?.photo_file
                                ? `${apiUrl}/users/${creator.user.id}/download-photo`
                                : creator.user?.profile?.avatar_url
                            }
                            alt={creator.user.profile.first_name_raw}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {`${creator.user.profile.first_name_raw} ${creator.user.profile.last_name_raw}`}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {creator.user.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {formatDate(creator.user.access_begin)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {getLastSeen(creator.user.last_login)}
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <div className="text-[#000066] hover:text-indigo-900 gap-x-5 flex">
                        <span>
                          <img
                            onClick={() => redirectTocreatorDetails(creator.id)}
                            className="cursor-pointer"
                            src="images/icons/eye.svg"
                            alt="eye icon"
                          />
                          <span className="opacity-0">Details</span>
                        </span>
                        <span>
                          <img
                            onClick={() => {
                              setCreatorId(creator.id);
                              deletePubModalRef.current.toggleModal(true);
                            }}
                            className="cursor-pointer"
                            src="images/icons/delete-gray.svg"
                            alt="bin icon"
                          />
                          <span className="opacity-0">Delete</span>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
