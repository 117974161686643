import { StarIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Rating({ likes, views }) {
  const RATING_SCALE = 5;
  const rating = getRating({ likes, views, RATING_SCALE: RATING_SCALE });

  return (
    <div className="flex items-center">
      {[...Array(RATING_SCALE).keys()].map((index) => (
        <StarIcon
          key={index}
          data-testid={index < rating ? "rate-true" : "rate-false"}
          className={classNames(
            index < rating ? "text-yellow-400" : "text-gray-300",
            "h-5 w-5 flex-shrink-0"
          )}
          aria-hidden="true"
        />
      ))}
    </div>
  );
}

export function getRating({ likes, views, RATING_SCALE }) {
  const rating = Math.floor((parseInt(likes) / parseInt(views)) * RATING_SCALE);
  return rating;
}
