import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axios } from "../utils/http";
import { apiUrl } from "../utils/config";
import { TopicsApiFactory, Configuration } from "vyse-api-client";
import { togglePageLoader } from "../components/loader";
import { notify } from "../components/alert";

const config = new Configuration({
  basePath: apiUrl,
});

export const getTopics = createAsyncThunk(
  "readers/getTopics",
  async (payload) => {
    try {
      let response = await TopicsApiFactory(config, undefined, axios).getTopics(
        "DESC",
        "DATE_CREATED",
        true,
        undefined,
        undefined,
        undefined,
        payload?.range?.from,
        payload?.range?.to
      );

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
      }
    }
  }
);

export const getInactiveTopics = createAsyncThunk(
  "readers/getInactiveTopics",
  async (payload) => {
    try {
      let response = await TopicsApiFactory(config, undefined, axios).getTopics(
        "DESC",
        "DATE_CREATED",
        false,
        undefined,
        undefined,
        undefined,
        payload?.range?.from,
        payload?.range?.to
      );

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
      }
    }
  }
);

export const getSingleTopic = createAsyncThunk(
  "readers/getSingleTopic",
  async (payload) => {
    try {
      let response = await TopicsApiFactory(config, undefined, axios).getTopic(
        payload.id
      );
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
      }
    }
  }
);

export const getTopicStats = createAsyncThunk(
  "readers/getTopicStats",
  async (payload) => {
    try {
      let response = await TopicsApiFactory(
        config,
        undefined,
        axios
      ).getTopicsStatistics(payload.id);
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
      }
    }
  }
);

export const addTopic = createAsyncThunk(
  "readers/addTopic",
  async (payload) => {
    try {
      let response = await TopicsApiFactory(
        config,
        undefined,
        axios
      ).createTopic(payload);

      if (response.status === 200) {
        notify("success", "Topic has been created successfully");
      }
      return response.status;
    } catch (error) {
      notify("error", error.response?.data?.detail);
    }
  }
);

export const updateTopic = createAsyncThunk(
  "readers/updateTopic",
  async (payload) => {
    try {
      let response = await TopicsApiFactory(
        config,
        undefined,
        axios
      ).updateTopic(payload.id, payload.update);

      if (response.status === 200) {
        notify("success", "Topic has been updated successfully");
      }
      return response.status;
    } catch (error) {
      notify("error", error.response?.data?.detail);

      if (!error.response?.data?.detail) {
      }
    }
  }
);

export const topicSlice = createSlice({
  name: "topics",
  initialState: {
    allTopics: [],
    inactiveTopics: [],
    singleTopic: {},
    totalTopics: 0,
    totalInactiveTopics: 0,
    topicStat: {},
    addTopicStatus: "",
    updateTopicStatus: "",
  },
  extraReducers: (builder) => {
    builder.addCase(getTopics.pending, togglePageLoader);
    builder.addCase(getTopics.fulfilled, (state, action) => {
      state.allTopics = action.payload.topics;
      state.totalTopics = action.payload.total;
      togglePageLoader();
    });
    builder.addCase(getInactiveTopics.pending);
    builder.addCase(getInactiveTopics.fulfilled, (state, action) => {
      state.inactiveTopics = action.payload.topics;
      state.totalInactiveTopics = action.payload.total;
      // togglePageLoader();
    });

    builder.addCase(getSingleTopic.pending, togglePageLoader);
    builder.addCase(getSingleTopic.fulfilled, (state, action) => {
      state.singleTopic = action.payload;
      togglePageLoader();
    });

    builder.addCase(getTopicStats.pending);
    builder.addCase(getTopicStats.fulfilled, (state, action) => {
      state.topicStat = action.payload;
    });

    builder.addCase(addTopic.pending, togglePageLoader);
    builder.addCase(addTopic.fulfilled, (state, action) => {
      state.addTopicStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(updateTopic.pending, togglePageLoader);
    builder.addCase(updateTopic.fulfilled, (state, action) => {
      state.updateTopicStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });
  },
});

export default topicSlice.reducer;
