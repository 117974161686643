import { useNavigate } from "react-router-dom";
import { convertUnicodeToReference } from "../../utils/functions/util";
import { usePrepPath } from "../../utils/routeHandler";

export default function TopicTable({ topics, handleAddTopicModal }) {
  // const removeUserRef = useRef();
  const navigate = useNavigate();
  const topicDetailsPath = usePrepPath("details/");
  const redirectToTopicDetails = (id) => navigate(topicDetailsPath + id);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Topics
          </h1>
          <p className="mt-2 text-sm text-gray-700">A list of all the topics</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={() => handleAddTopicModal()}
            type="button"
            className="block rounded-md bg-[#030366] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#030366] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#030366]"
          >
            Add topic
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Required for Readers
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Details</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {topics?.map((topic) => (
                  <tr key={topic.id}>
                    <td className="flex items-start gap-x-3  px-3 py-5 text-sm w-full">
                      <div className="text-gray-900">{topic.title}</div>
                      <div className=" text-gray-500">
                        {convertUnicodeToReference(topic.emoji_code)}
                      </div>
                    </td>
                    <td className="px-3 py-5 text-sm text-gray-500">
                      {topic.is_active ? (
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Active
                        </span>
                      ) : (
                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
                          Not active
                        </span>
                      )}
                    </td>
                    <td className="px-3 py-5 text-sm text-gray-500">
                      {topic.required_for_readers ? "Required" : "Not required"}
                    </td>
                    <td className="relative  py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <div
                        onClick={() => redirectToTopicDetails(topic.id)}
                        className="text-[#030366] hover:text-[#030366] cursor-pointer"
                      >
                        Detials<span className="sr-only">, {topic.title}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
