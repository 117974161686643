import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { getTopics } from "../store/topics";
import { formatDate } from "../utils/functions/formatDate";
import {
  convertUnicodeToReference,
  usePrevious,
} from "../utils/functions/util";

const AllTopics = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const creatorUpdateStatus = useSelector(
    (state) => state.creators.creatorUpdateStatus
  );

  const [open, setOpen] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  const [addUrl, setAddUrl] = useState(false);

  const allTopics = useSelector((state) => state.topics.allTopics);

  useEffect(() => {
    dispatch(getTopics());
  }, [dispatch]);

  function handleOpenUrlInput(index) {
    setAddUrl(index);
    setApiUrl("");
  }

  const prevStatus = usePrevious(creatorUpdateStatus);
  useEffect(() => {
    if (prevStatus !== creatorUpdateStatus) {
      setApiUrl("");
      setAddUrl(false);
      setOpen(false);
    }
  }, [creatorUpdateStatus]);

  useImperativeHandle(ref, () => ({
    toggleModal() {
      setOpen(!open);
    },
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className=" fixed inset-0 flex items-center justify-center">
          <div className="flex w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <ul className="w-full lg:w-3/5 max-h-[80vh]  divide-y rounded-xl divide-gray-100 overflow-x-auto bg-white round  px-8 py-12 relative">
              <div className="absolute right-0 top-0  pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <h2 className="font-semibold text-lg">All Topics</h2>
              {allTopics?.map((topic, index) => (
                <>
                  <li key={topic.id} className="overflow-hidden ">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        props.handleAddTopic(topic.id, apiUrl);
                      }}
                    >
                      <div className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                          <div
                            onClick={() => handleOpenUrlInput(null)}
                            className="flex items-start gap-x-3"
                          >
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {topic.title}
                            </p>
                            <span>
                              {convertUnicodeToReference(topic.emoji_code)}
                            </span>
                          </div>
                          <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p className="truncate">
                              Created by {topic.created}
                            </p>
                            <svg
                              viewBox="0 0 2 2"
                              className="h-0.5 w-0.5 fill-current"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            <p className="whitespace-nowrap">
                              on {formatDate(topic.date_created)}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                          {addUrl === index ? (
                            <div className="flex items-center gap-x-2">
                              <button
                                onClick={() => setAddUrl(false)}
                                type="button"
                                className="rounded-full bg-[#000066] p-1 text-white shadow-sm hover:bg-[#000066] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000066]"
                              >
                                <XMarkIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                type="submit"
                                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block coursor:pointer"
                              >
                                Save topic
                                <span className="sr-only">, {topic.name}</span>
                              </button>
                            </div>
                          ) : (
                            <button
                              type="button"
                              onClick={() => handleOpenUrlInput(index)}
                              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block coursor:pointer"
                            >
                              Add Topic Url
                              <span className="sr-only">, {topic.name}</span>
                            </button>
                          )}
                        </div>
                      </div>

                      {addUrl === index && (
                        <div
                          className={`${
                            addUrl === index
                              ? "max-h-200px px-3 pb-1.5 pt-2.5"
                              : "p-0 h-0"
                          } duration-500 transition-all rounded-md  shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600}`}
                        >
                          <label
                            htmlFor="url"
                            className="text-left block text-xs font-medium text-gray-900"
                          >
                            URL
                          </label>

                          <input
                            required
                            type="url"
                            name="apiUrl"
                            id="apiUrl"
                            value={apiUrl}
                            onChange={(e) => setApiUrl(e.target.value)}
                            className="block w-full border-0 p-0 text-gray-900 focus:outline-none placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="https://www.yourtopicurl.com"
                          />
                        </div>
                      )}
                    </form>
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default AllTopics;
