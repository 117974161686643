import { useLocation } from "react-router-dom";

export function usePrepPath(childPath) {
  const location = useLocation();

  let pathArray = location.pathname.split("/");
  let parentPath = pathArray[1];

  return `/${parentPath}/${childPath}`;
}
