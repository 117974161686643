import React, {
  useEffect,
  //  useState
} from "react";
import PostLogin from "../../layout/post-login";
import { formatDate, getLastSeen } from "../../utils/functions/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUser } from "../../store/users";
import { getUserRole } from "../../utils/functions/util";
import { apiUrl } from "../../utils/config";

export default function UserDetails() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.singleUser);
  const params = useParams();

  useEffect(() => {
    document.title = "User Details - Admin Vyse News";
    dispatch(getUser({ id: params.userId }));
  }, [dispatch, params]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <PostLogin>
      {Object.keys(user).length !== 0 && (
        <>
          <div className="pb-8 lg:py-11 px-0 sm:px-5">
            <header>
              {/* Heading */}
              <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-[#e6e6f0] px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 border-b border-[#c4c4c4] items-center justify-center">
                <div>
                  <div className="flex items-center gap-x-3">
                    <h1 className="flex gap-x-3 text-base leading-7">
                      <span className="font-semibold text-[#101828]">User</span>
                      <span className="text-gray-600">/</span>
                      <span className="font-semibold text-[#101828]">
                        Details
                      </span>
                    </h1>
                  </div>
                  <p className="mt-2 text-xs leading-6 text-gray-600">
                    Manage user and their personal information
                  </p>
                </div>
                <div className="order-first flex-none rounded-full bg-[#000066]/10 px-2 py-1 text-xs font-medium text-[#000066] ring-1 ring-inset ring-[#000066]/30 sm:order-none">
                  {getUserRole(user)}
                </div>
              </div>

              {/* Stats */}

              <div className="grid grid-cols-2 bg-[#e6e6f0]  lg:grid-cols-4">
                <div
                  className={classNames(
                    "col-span-2 flex items-center border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8 gap-x-4"
                  )}
                >
                  <img
                    className="  h-16 w-16 flex-none rounded-full bg-gray-50"
                    src={
                      user?.profile?.photo_file
                        ? `${apiUrl}/users/${user.id}/download-photo`
                        : user?.profile?.avatar_url
                    }
                    alt={
                      user.profile?.first_name_raw +
                      " " +
                      user.profile?.last_name_raw
                    }
                  />

                  <div className="min-w-0">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {user.profile?.first_name_raw}{" "}
                      {user.profile?.last_name_raw}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {user.email}
                    </p>
                  </div>
                </div>

                <div
                  className={classNames(
                    "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                  )}
                >
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    Date joined
                  </p>
                  <p className="mt-2 flex items-center gap-x-2">
                    <span className="text-sm font-semibold leading-6 text-gray-900">
                      {formatDate(user.access_begin)}
                    </span>
                  </p>
                </div>
                <div
                  className={classNames(
                    "border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                  )}
                >
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    Last seen
                  </p>
                  <p className="mt-2 flex items-center gap-x-2">
                    <span className="text-sm font-semibold leading-6 text-gray-900">
                      {getLastSeen(user.last_login)}
                    </span>
                  </p>
                </div>
              </div>
            </header>

            {/* profile */}
            <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
              <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Profile
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    User's information
                  </p>

                  <form
                    className={`mt-6 border-t border-gray-200 text-sm leading-6`}
                  >
                    <div className="py-6 sm:flex border-b border-gray-100">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        First name
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {user?.profile?.first_name_raw}
                        </div>
                      </dd>
                    </div>
                    <div className="py-6 sm:flex border-b border-gray-100">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Last name
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {user?.profile?.last_name_raw}
                        </div>
                      </dd>
                    </div>
                    <div className="py-6 sm:flex border-b border-gray-100">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Email address
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">{user?.email}</div>
                      </dd>
                    </div>
                    <div className="py-6 sm:flex border-b border-gray-100">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Gender
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {user?.profile?.gender ?? "Empty"}
                        </div>
                      </dd>
                    </div>

                    <div className="py-6 sm:flex border-b border-gray-100">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Country of residence
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {user?.profile?.country_of_residence ?? "Empty"}
                        </div>
                      </dd>
                    </div>
                    <div className="py-6 sm:flex border-b border-gray-100">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Date of birth
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {user?.profile?.dob ?? "Empty"}
                        </div>
                      </dd>
                    </div>
                    <div className="py-6 sm:flex border-b border-gray-100">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                        Instagram handle
                      </dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="text-gray-900">
                          {user?.profile?.instagram_handle ?? "Empty"}
                        </div>
                      </dd>
                    </div>
                  </form>
                </div>
              </div>
            </main>
          </div>
        </>
      )}
    </PostLogin>
  );
}
