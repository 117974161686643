import { Tab } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CalendarIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { formatDate } from "../../utils/functions/formatDate";
import { Link } from "react-router-dom";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import AddNewVersion from "./new-version-form";
import { useEffect } from "react";
import { deleteVersion, getVersions } from "../../store/features";
import { useRef } from "react";
import UpdateVersionForm from "./version-update";
import DeleteDialog from "../delete-dialog";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PageTab({ range, openFeatureModal }) {
  const dispatch = useDispatch();
  const features = useSelector((state) => state.features.allfeatures);
  const totalfeature = useSelector((state) => state.features.totalfeatures);
  const versions = useSelector((state) => state.features.allversion);
  const totalversion = useSelector((state) => state.features.totalversions);
  const addNewFeautureStatus = useSelector(
    (state) => state.features.addNewFeautureStatus
  );

  const [openNewVersionForm, setOpenNewVersionForm] = useState(false);
  const [openUpdateVersionForm, setUpdateVersionForm] = useState(null);
  const [versionNumber, setVersionNumber] = useState("");

  useEffect(() => {
    dispatch(getVersions({ range }));
    setOpenNewVersionForm(false);
  }, [dispatch, range, addNewFeautureStatus]);

  const deleteVersionRef = useRef();
  const handleDeleteVersion = () => {
    dispatch(deleteVersion({ versionNumber }));
    return deleteVersionRef.current.toggleModal(false);
  };

  return (
    <Fragment>
      <DeleteDialog
        title="Delete Version"
        text=" Are you sure you want to DELETE this version? This version will be be permanently removed from the database. This action cannot be undone."
        handleDelete={handleDeleteVersion}
        ref={deleteVersionRef}
      />

      <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none mb-6">
        <Tab.Group as="div">
          <div className="border-b border-gray-200">
            <Tab.List className="-mb-px flex space-x-12">
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? "border-[#030366] text-[#030366]"
                      : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                    "whitespace-nowrap border-b-2 py-2.5 text-sm font-medium flex gap-1.5 items-center"
                  )
                }
              >
                Features
                <span className="inline-flex items-center justify-center rounded-full bg-[#e6e6f0] p-1 h-5 w-5 text-xs font-semibold text-gray-600 ring-1 ring-inset ring-[#4d4d94]/20">
                  {totalfeature}
                </span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? "border-[#030366] text-[#030366]"
                      : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                    "whitespace-nowrap border-b-2 py-2.5 text-sm font-medium flex gap-1.5 items-center"
                  )
                }
              >
                Versions
                <span className="inline-flex items-center justify-center rounded-full bg-[#e6e6f0] p-1 h-5 w-5 text-xs font-semibold text-gray-600 ring-1 ring-inset ring-[#4d4d94]/20">
                  {totalversion}
                </span>
              </Tab>
            </Tab.List>
          </div>

          <Tab.Panels as={Fragment}>
            <Tab.Panel className="text-sm text-gray-500">
              <div className="w-full flex flex-col-reverse lg:flex-row justify-between mt-6 mb-6 items-start gap-2">
                {/* <FeaturesFilter /> */}
                <button
                  onClick={() => openFeatureModal(true)}
                  type="button"
                  className="block rounded-md bg-[#030366] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#030366] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#030366]"
                >
                  Add feature
                </button>
              </div>
              <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-2 xl:gap-x-8">
                {features?.map((feature) => (
                  <div
                    key={feature.id}
                    className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 flex flex-col"
                  >
                    <dl className="flex flex-wrap">
                      <div className="flex-auto pl-6 pt-6">
                        <dt className="text-base font-semibold leading-6 text-gray-900">
                          {feature?.title}
                        </dt>
                        <dd className="mt-1 text-sm font-semibold leading-6 text-gray-900">
                          {feature?.feature_version?.version_number}
                        </dd>
                      </div>
                      <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                        <dt className="flex-none">
                          <CalendarIcon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          <time dateTime="2023-01-31">
                            {formatDate(feature?.date_released)}
                          </time>
                        </dd>
                      </div>
                      <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                        <dt className="flex-none">
                          <InformationCircleIcon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          {feature?.description}
                        </dd>
                      </div>
                    </dl>
                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                      <Link
                        to={`/features/details/${feature.id}`}
                        className="text-sm font-semibold leading-6 text-gray-900"
                      >
                        View <span aria-hidden="true">&rarr;</span>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </Tab.Panel>

            <Tab.Panel className="-mb-10">
              <div className="w-full flex flex-col-reverse lg:flex-row justify-between mt-6 mb-6 items-start gap-2">
                {/* <VersionFilter /> */}
                <button
                  onClick={() => setOpenNewVersionForm(true)}
                  type="button"
                  className="block rounded-md bg-[#030366] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#030366] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#030366]"
                >
                  New version
                </button>
              </div>
              {openNewVersionForm && (
                <AddNewVersion toggleForm={setOpenNewVersionForm} />
              )}
              <ul className="divide-y divide-gray-100 w-full">
                {versions.map((version, index) => (
                  <li key={version.id}>
                    <div className="flex justify-between gap-x-6 py-5">
                      <div
                        // to={`/features/details/${version.id}`}
                        className="flex gap-2 "
                      >
                        <span className="text-sm">Version</span>
                        <p className="text-sm font-semibold">
                          {version?.version_number}
                        </p>
                      </div>

                      <div className="flex gap-x-4">
                        <span onClick={() => setUpdateVersionForm(index)}>
                          <PencilSquareIcon className="h-6 w-6 text-gray-700" />
                        </span>
                        <span
                          onClick={() => {
                            deleteVersionRef.current.toggleModal(true);
                            setVersionNumber(version?.version_number);
                          }}
                        >
                          <TrashIcon className="h-6 w-6 text-red-700" />
                        </span>
                      </div>
                    </div>
                    {openUpdateVersionForm === index && (
                      <UpdateVersionForm
                        versionId={version?.id}
                        toggleForm={setUpdateVersionForm}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Fragment>
  );
}
