import { useDispatch, useSelector } from "react-redux";
import PostLogin from "../../layout/post-login";
import { useEffect, useRef, useState } from "react";
import {
  deleteFeature,
  getFeature,
  updateFeature,
  updateVersion,
} from "../../store/features";
import { useParams } from "react-router-dom";
import DeleteDialog from "../../components/delete-dialog";
import { notify } from "../../components/alert";

export default function FeatureDetails() {
  const dispatch = useDispatch();
  const feature = useSelector((state) => state.features.singlefeature);

  const prams = useParams();
  const [featureDetails, setFeatureDetails] = useState({
    title: "",
    description: "",
    link: "",
    date_released: "",
    version_number: "",
  });

  const [featureUpdate, setFeatureUpdate] = useState({});
  const [editVersion, setEditVersion] = useState(false);

  useEffect(() => {
    dispatch(getFeature({ featureId: prams.featureId }));
  }, [prams, dispatch]);

  useEffect(() => {
    setFeatureDetails((prev) => ({
      ...prev,
      title: feature?.title,
      description: feature?.description,
      link: null,
      date_released: feature?.date_released,
      version_number: feature?.feature_version?.version_number,
    }));
  }, [feature]);

  const handleSetFeatureDetails = (e) => {
    const { name, value } = e.target;

    setFeatureDetails({ ...featureDetails, [name]: value.trim() });
    setFeatureUpdate({ ...featureUpdate, [name]: value.trim() });
  };

  const deleteModalRef = useRef("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const prevFeatureDetails = {
      title: feature?.title,
      description: feature?.description,
      link: null,
      date_released: feature?.date_released,
    };

    const { version_number, ...rest } = featureDetails;
    if (JSON.stringify(rest) === JSON.stringify(prevFeatureDetails)) {
      notify("info", "You haven't made any change to this feature yet");
      return;
    }

    dispatch(
      updateFeature({
        featureId: feature.id,
        featureUpdate: featureUpdate,
      })
    );
    return dispatch(getFeature({ featureId: prams.featureId }));
  };

  const handleVersionUpdate = () => {
    dispatch(
      updateVersion({
        versionId: feature.feature_version?.id,
        versionUpdate: featureDetails.version_number,
      })
    );
  };

  const params = useParams();
  const handleDeletefeature = () => {
    dispatch(deleteFeature({ featureId: params.featureId }));
  };

  return (
    <PostLogin>
      {/* <DeleteFeature ref={deleteModalRef} /> */}
      <DeleteDialog
        title="Delete Feature"
        text="Are you sure you want to DELETE this feature? This feature will be be permanently removed from the database. This action cannot be undone."
        handleDelete={handleDeletefeature}
        ref={deleteModalRef}
      />
      <div>
        <div className="bg-[#e6e6f0]/50 h-60 rounded-lg flex items-center justify-center">
          <div>
            <h1 className="text-xl text-gray-700 font-semibold">
              {feature?.title}
            </h1>
            <div className="text-sm flex items-center gap-x-2 mt-1">
              <span className="text-gray-400 ">Version:</span>
              <span className="text-gray-600 font-medium">
                {feature?.feature_version?.version_number}
              </span>
            </div>
          </div>
        </div>

        <>
          <div className="px-4 sm:px-0">
            <p className="mt-6 max-w-2xl text-base leading-6 text-gray-700">
              Features details.
            </p>
            <p className="mt-2 max-w-2xl text-sm leading-6 text-gray-700">
              features version is only allowed to be updated separately.
            </p>
          </div>
          <form
            className="mt-2 border-t border-gray-100"
            onSubmit={(e) => handleSubmit(e)}
          >
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Title
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <input
                    name="title"
                    className="bg-[#e6e6f0]/50 flex-grow p-2 border-0 ring-1 ring-inset ring-gray-100 rounded-lg focus:ring-1 focus:ring-inset focus:border-0 focus:ring-gray-200"
                    value={featureDetails?.title}
                    onChange={(e) => handleSetFeatureDetails(e)}
                  />
                </dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Version
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <input
                    name="version_number"
                    disabled={!editVersion}
                    className="bg-[#e6e6f0]/50 flex-grow p-2 border-0 ring-1 ring-inset ring-gray-100 rounded-lg focus:ring-1 focus:ring-inset focus:border-0 focus:ring-gray-200"
                    value={featureDetails.version_number}
                    onChange={(e) => handleSetFeatureDetails(e)}
                    onBlur={() => setEditVersion(false)}
                  />
                  <span className="ml-4 flex-shrink-0">
                    {editVersion ? (
                      <button
                        onClick={() => handleVersionUpdate()}
                        type="button"
                        className="rounded-md bg-white font-medium text-[#030366] p-1.5 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        onClick={() => setEditVersion(true)}
                        type="button"
                        className="rounded-md bg-white font-medium text-[#030366] p-1.5 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Edit version
                      </button>
                    )}
                  </span>
                </dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Date released
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <input
                    name="date_released"
                    className="bg-[#e6e6f0]/50 flex-grow p-2 border-0 ring-1 ring-inset ring-gray-100 rounded-lg focus:ring-1 focus:ring-inset focus:border-0 focus:ring-gray-200"
                    type="date"
                    value={featureDetails?.date_released}
                    onChange={(e) => handleSetFeatureDetails(e)}
                  />
                </dd>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  description
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <textarea
                    name="description"
                    className="h-40 bg-[#e6e6f0]/50 flex-grow p-2 border-0 ring-1 ring-inset ring-gray-100 rounded-lg focus:ring-1 focus:ring-inset focus:border-0 focus:ring-gray-200"
                    value={featureDetails?.description}
                    onChange={(e) => handleSetFeatureDetails(e)}
                  />
                </dd>
              </div>
              <div className="flex w-full justify-end">
                <button
                  type="submit"
                  disabled={editVersion}
                  className={`flex justify-center rounded-md bg-[#030366] p-2 text-sm font-medium leading-6 text-white shadow-sm hover:bg-[#030366]${
                    editVersion ? "cusor-disabled bg-[#030366]/60" : null
                  }`}
                >
                  Update changes
                </button>
              </div>

              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <h3 className="text-sm font-medium text-red-700">DELETE</h3>
                <p className="mt-4 text-sm text-gray-500">
                  Deleting a feature is a permanent action
                  <button
                    onClick={() => deleteModalRef.current?.toggleModal(true)}
                    type="button"
                    className="mt-4 rounded-md block bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                  >
                    Delete feature
                  </button>
                </p>
              </div>
            </dl>
          </form>
        </>
      </div>
    </PostLogin>
  );
}
