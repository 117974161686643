import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axios } from "../utils/http";
import { apiUrl } from "../utils/config";
import { ReadersApiFactory, Configuration } from "vyse-api-client";
import { togglePageLoader } from "../components/loader";

const config = new Configuration({
  basePath: apiUrl,
});

export const getAllReaders = createAsyncThunk(
  "readers/getAllReaders",
  async (payload) => {
    try {
      let response = await ReadersApiFactory(
        config,
        undefined,
        axios
      ).getReaders(
        payload.orderDirection,
        payload.from,
        payload.to,
        payload.anonynmousReaders,
        true
      );
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getAnonymousReaders = createAsyncThunk(
  "readers/getAnonymousReaders",
  async (payload) => {
    try {
      let response = await ReadersApiFactory(
        config,
        undefined,
        axios
      ).getReaders(
        payload.orderDirection,
        payload.from,
        payload.to,
        true,
        true
      );

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getKnownReaders = createAsyncThunk(
  "readers/getKnownReaders",
  async (payload) => {
    try {
      let response = await ReadersApiFactory(
        config,
        undefined,
        axios
      ).getReaders(
        payload.orderDirection,
        payload.from,
        payload.to,
        false,
        true
      );

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getSingleReader = createAsyncThunk(
  "readers/getSingleReader",
  async (payload) => {
    try {
      let response = await ReadersApiFactory(
        config,
        undefined,
        axios
      ).getReader(payload.id);
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const readerSlice = createSlice({
  name: "readers",
  initialState: {
    useLoader: true,
    singleReader: {},
    allReaders: [],
    totalReaders: 0,
    anonynmousReaders: [],
    totalAnonymousReaders: 0,
    knownReaders: [],
    totalKnownReaders: 0,
    readersTableData: [],
    totalTableItems: 0,
  },
  reducers: {
    setTableData: (state, action) => {
      state.readersTableData = action.payload.data;
      state.totalTableItems = action.payload.totalItem;
    },

    activeLoader: (state, action) => {
      state.useLoader = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllReaders.pending, (state, action) => {
      // togglePageLoader();
    });
    builder.addCase(getAllReaders.fulfilled, (state, action) => {
      state.allReaders = action.payload.readers;
      state.totalReaders = action.payload.total;

      // togglePageLoader();
    });

    builder.addCase(getAnonymousReaders.pending, (state, action) => {
      // togglePageLoader();
    });
    builder.addCase(getAnonymousReaders.fulfilled, (state, action) => {
      state.anonynmousReaders = action.payload.readers;
      state.totalAnonymousReaders = action.payload.total;

      // togglePageLoader();
    });

    builder.addCase(getKnownReaders.pending, (state, action) => {
      // togglePageLoader();
    });
    builder.addCase(getKnownReaders.fulfilled, (state, action) => {
      state.knownReaders = action.payload.readers;
      state.totalKnownReaders = action.payload.total;

      // togglePageLoader();
    });

    builder.addCase(getSingleReader.pending, togglePageLoader);
    builder.addCase(getSingleReader.fulfilled, (state, action) => {
      state.singleReader = action.payload;
      togglePageLoader();
    });
  },
});

export const { setTableData, activeLoader } = readerSlice.actions;
export default readerSlice.reducer;
