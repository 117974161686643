import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/auth";
import TextInput from "../../components/text-input";

// import Loader from "../../components/loader";
import Alert from "../../components/alert";

export default function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(null);

  document.title = "Login - Admin Vyse News";
  const loginHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(login({ email, password }));
    setLoading(false);
  };

  return (
    <>
      {/* <Loader /> */}
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <Alert />
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            src="/images/Logo.png"
            alt="company's logo"
            height={15}
            width={60}
            className="mb-8 m-auto "
          />
          <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            action="#"
            onSubmit={(e) => loginHandler(e)}
          >
            <div>
              <div className="mt-2">
                <TextInput
                  type={"email"}
                  label={"Email"}
                  value={email}
                  setterFunction={setEmail}
                  required
                />
              </div>
            </div>

            <div>
              <div className="mt-2">
                <TextInput
                  type={"password"}
                  label={"Password"}
                  value={password}
                  setterFunction={setPassword}
                  required
                />
              </div>
              <div className="flex items-center justify-between mt-6">
                <div className="text-sm">
                  <Link
                    to="/"
                    className="font-semibold text-[#000066] hover:text-indigo-500"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full items-center justify-center rounded-md bg-[#000066] h-12 w-12 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#000066] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="relative">
                      <div className="h-8 w-8 rounded-full border-t-[4px] border-b-[4px] border-[#6691be]"></div>
                      <div className="absolute top-0 left-0 h-8 w-8 rounded-full border-t-[4px] border-b-[4px] border-[#e6edf4] animate-[spin_.8s_linear_infinite]"></div>
                    </div>
                  </div>
                ) : (
                  "Sign in"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
