import { useEffect, useRef, useState } from "react";
import PostLogin from "../../layout/post-login";
import { getInactiveTopics, getTopics } from "../../store/topics";
import { useDispatch, useSelector } from "react-redux";
import TopicTable from "../../components/topics/topics-table";
import Pagination from "../../components/new-pagination";
import TopicsStatCard from "../../components/topics/topics-stat";
import AddNewTopic from "../../components/topics/new-topic-form";
import Alert from "../../components/alert";
import { usePrevious } from "../../utils/functions/util";
import InActiveTopics from "../../components/topics/inactive-topics";

export default function Topics() {
  const dispatch = useDispatch();

  const [range, setRange] = useState({ from: 0, to: 10 });

  const allTopics = useSelector((state) => state.topics.allTopics);
  const totalTopics = useSelector((state) => state.topics.totalTopics);
  const totalInactiveTopics = useSelector(
    (state) => state.topics.totalInactiveTopics
  );
  const updateTopicStatus = useSelector(
    (state) => state.topics.updateTopicStatus
  );

  useEffect(() => {
    document.title = "Topics - Vyse News Topics";
    dispatch(getTopics({ range }));
    dispatch(getInactiveTopics({ range }));
  }, [dispatch, range, updateTopicStatus]);

  const addNewTopicRef = useRef("");
  const inactiveTopicModalRef = useRef("");

  return (
    <PostLogin>
      <Alert />
      <AddNewTopic ref={addNewTopicRef} />
      <InActiveTopics ref={inactiveTopicModalRef} range={range} />
      <div className=" sm:py-11 sm:px-10">
        <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
          Topics
        </h3>
        <TopicsStatCard
          openInactiveTopicsModal={() =>
            inactiveTopicModalRef.current.toggleModal(true)
          }
          stat1={totalTopics}
          stat2={totalInactiveTopics}
        />
      </div>
      <TopicTable
        topics={allTopics}
        handleAddTopicModal={() => addNewTopicRef.current.toggleModal()}
      />
      <Pagination
        totalItems={totalTopics}
        itemsOnDisplay={allTopics?.length}
        range={range}
        setRange={setRange}
        tableName="Topics"
      />
    </PostLogin>
  );
}
