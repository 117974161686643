import {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { createFeature, getFeatures } from "../../store/features";

const AddNewFeature = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal(value) {
      setOpen(value);
    },
  }));
  const addFeatureStatus = useSelector(
    (state) => state.features.addFeatureStatus
  );

  useEffect(() => {
    dispatch(getFeatures({ from: 0, to: 10 }));
    setOpen(false);
  }, [addFeatureStatus]);

  const [featureInfo, setFeatureInfo] = useState({
    title: "",
    version_number: "",
    description: "",
    link: "",
    date_released: "",
  });

  function handleOnChange(e) {
    const { name, value } = e.target;
    setFeatureInfo({
      ...featureInfo,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(createFeature(featureInfo));
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Add Feature
                    </Dialog.Title>
                    <form
                      className="w-full text-left"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="mt-10 gap-y-4 w-full flex flex-col">
                        <div className="w-full">
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Title
                          </label>
                          <div className="mt-2">
                            <input
                              required
                              type="text"
                              name="title"
                              id="title"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                          </div>
                        </div>

                        <div className="w-full">
                          <label
                            htmlFor="link"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Link
                          </label>
                          <div className="mt-2">
                            <input
                              required
                              type="text"
                              name="link"
                              id="link"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="versionNumber"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Version Number
                          </label>
                          <div className="mt-2">
                            <input
                              required
                              id="versionNumber"
                              name="version_number"
                              placeholder="1.0.123"
                              type="text"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                          <label
                            htmlFor="dateRelease"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Date release
                          </label>
                          <div className="mt-2">
                            <input
                              required
                              type="date"
                              name="date_released"
                              id="dateRelease"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Description
                          </label>
                          <div className="mt-2">
                            <input
                              required
                              type="text"
                              name="description"
                              id="description"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                          onClick={() => setOpen(false)}
                          type="button"
                          className="text-sm w-[50%] font-semibold leading-6 text-gray-900"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-[#000066] w-[50%] px-3 py-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000066]"
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default AddNewFeature;
