import { NoSymbolIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { convertUnicodeToReference } from "../../utils/functions/util";
import { formatDate } from "../../utils/functions/formatDate";
import { updateTopic } from "../../store/topics";

const InActiveTopics = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const inactiveTopics = useSelector((state) => state.topics.inactiveTopics);

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal(value) {
      setOpen(value);
    },
  }));

  const makeTopicActive = (topicId) => {
    dispatch(
      updateTopic({
        id: topicId,
        update: {
          make_active: true,
        },
      })
    );
  };

  return (
    <>
      {open && (
        <div className="z-50 fixed inset-0  grid place-items-end sm:place-items-center">
          <div
            onClick={() => setOpen(false)}
            className="absolute h-full w-full bg-black/30"
          ></div>
          <div className="relative z-[60] min-h-[80%] sm:max-h-[90%] w-full sm:w-[80%] lg:w-[55%]  mx-auto bg-white p-5 lg:p-14 overflow-auto rounded-md">
            <XMarkIcon
              onClick={() => setOpen(false)}
              className="cursor-pointer absolute top-5 right-5 sm:top-10 sm:right-10 h-6 w-6 text-gray-900 hover:ring-2 hover:ring-offset-2 rounded-md"
            />
            <p className="text-center font-semibold text-base sm:text-lg text-gray-900 mb-8">
              Inactive Topics
            </p>
            <ul className="divide-y divide-gray-100">
              {inactiveTopics.length === 0 && (
                <p className="text-center mt-11 text-gray-500 flex gap-x-2 justify-center">
                  <NoSymbolIcon className="h-6 w-6" /> No Inactive topics
                </p>
              )}
              {inactiveTopics.map((topic) => (
                <li key={topic.id} className="">
                  <div className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {topic.title}
                        </p>
                        <span>
                          {convertUnicodeToReference(topic.emoji_code)}
                        </span>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="truncate">Created by {topic.created}</p>
                        <svg
                          viewBox="0 0 2 2"
                          className="h-0.5 w-0.5 fill-current"
                        >
                          <circle cx={1} cy={1} r={1} />
                        </svg>
                        <p className="whitespace-nowrap">
                          on {formatDate(topic.date_created)}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-x-1.5">
                      <button
                        onClick={() =>
                          (window.location.href = `/topics/details/${topic.id}`)
                        }
                        type="button"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block coursor:pointer"
                      >
                        View
                        <span className="sr-only">, {topic.name}</span>
                      </button>
                      <button
                        onClick={() => makeTopicActive(topic.id)}
                        type="button"
                        className="rounded-md  bg-[#030366] px-2.5 py-1.5 text-sm  text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:block coursor:pointer"
                      >
                        Activate
                        <span className="sr-only">, {topic.name}</span>
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
});

export default InActiveTopics;
