import { useDispatch, useSelector } from "react-redux";
import StoriesGrid from "../../components/stories/stories-grid";
import PostLogin from "../../layout/post-login";
import { useEffect, useState } from "react";
import { getAllStories } from "../../store/stories";

import StoriesFilter from "../../components/stories/stories-filter";
import Pagination from "../../components/new-pagination";
import { togglePageLoader } from "../../components/loader";

export default function Stories() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    direction: "DESC",
    date: "DATE_CREATED",
    title: "",
    creator_id: undefined,
    topics_id: [],
    return_alert_readers: undefined,
    return_featured: undefined,
    created_by_user_id: undefined,
    returnOnlyPublished: undefined,
  });

  const stories = useSelector((state) => state.stories.allStories);
  const totalStories = useSelector((state) => state.stories.totalStories);

  const [range, setRange] = useState({ from: 0, to: 20 });

  useEffect(() => {
    togglePageLoader();
    document.title = "Stories - Admin Vyse News";
    dispatch(getAllStories({ ...query, ...range }));
    togglePageLoader();
  }, [dispatch, range, query]);

  return (
    <PostLogin>
      <div className="">
        <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
          Stories
        </h3>
        {/* <StoriesFilter storiesQuery={query} setStoriesQuery={setQuery} /> */}
        <StoriesFilter storiesQuery={query} setStoriesQuery={setQuery} />
        <StoriesGrid allStories={stories} />
        <Pagination
          totalItems={totalStories}
          itemsOnDisplay={stories.length}
          range={range}
          setRange={setRange}
          tableName="Stoires"
          itemsPerPage={range?.to}
        />
      </div>
    </PostLogin>
  );
}
