import { useEffect, useRef } from "react";

export const getRole = (obj) => {
  if (obj?.is_creator) {
    return "Creator";
  } else if (obj?.is_reader) {
    return "Reader";
  } else if (obj?.is_super_admin) {
    return "Super admin";
  }
};

export const getAnonimity = (arr) => {
  const isAnonymous = arr.filter((user) => user?.user?.is_anonymous);
  return isAnonymous.length;
};

export const logout = (state) => {
  localStorage.removeItem("token");
  window.location.href = "";
};

export const convertUnicodeToReference = (unicode) => {
  if (!unicode || unicode === "NaN") return;
  const emoji = [];
  unicode.split(" ").forEach((element) => {
    emoji.push(String.fromCodePoint(parseInt(element.replace("U+", ""), 16)));
  });
  return emoji.join("");
};

export const emojiUnicode = (emoji) => {
  if (emoji === "") {
    return undefined;
  }
  let code;
  if (emoji.length === 1) {
    code = emoji.charCodeAt(0);
  }
  code =
    (emoji.charCodeAt(0) - 0xd800) * 0x400 +
    (emoji.charCodeAt(1) - 0xdc00) +
    0x10000;
  if (code < 0) {
    code = emoji.charCodeAt(0);
  }
  return code.toString("16");
};

export const getUserRole = (userObj) => {
  switch (true) {
    case userObj.is_super_admin:
      return "Is Super Admin";
    case userObj.is_creator:
      return "Is Creator";
    case userObj.is_reader:
      return "Is Reader";
    default:
      break;
  }
};

export const usePrevious = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getParsedJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (error) {
    return undefined;
  }
};

export function sleepFor(secs) {
  return new Promise((resolve) => setTimeout(resolve, secs * 1000));
}
