import { useState } from "react";
import Loader from "../components/loader";
import Sidebar from "../components/new-sidebar";
import Topbar from "../components/topbar";
import Alert from "../components/alert";

export default function PostLogin({ children }) {
  const [sidebarIsOpen, toggleSidebarIsOpen] = useState(false);

  return (
    <div className="relative flex">
      <Loader />
      <Alert />

      <Sidebar
        sidebarOpen={sidebarIsOpen}
        setSidebarOpen={() => toggleSidebarIsOpen(!sidebarIsOpen)}
      />
      <div className="w-full min-h-screen lg:w-4/5 pb-10 lg:ml-72">
        <Topbar handleSideBarOpen={() => toggleSidebarIsOpen(!sidebarIsOpen)} />
        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </div>
  );
}
