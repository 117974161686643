import { useDispatch } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { updateVersion } from "../../store/features";

const UpdateVersionForm = ({ toggleForm, versionId }) => {
  const dispatch = useDispatch();

  const [versionNumber, setVersionNumber] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateVersion({ versionId, versionNumber }));
    return toggleForm(null);
  }

  return (
    <div className="w-full" key={versionId}>
      <form className="w-full flex gap-x-6" onSubmit={(e) => handleSubmit(e)}>
        <div className="w-[80%]">
          <label
            htmlFor="versionNumber"
            className="block text-xs font-medium leading-6 text-gray-900"
          >
            New Version Number
          </label>
          <div className="mt-2">
            <input
              required
              id="versionNumber"
              placeholder="Enter a new version number"
              type="text"
              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
              onChange={(e) => setVersionNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-2">
          <span
            onClick={() => toggleForm(null)}
            className="grid place-items-center rounded-full p-1 bg-[#030366] bg-gray-100 p-1.5 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <XMarkIcon className="h-4 w-4 text-gray-500" />
          </span>
          <button
            type="submit"
            className="rounded-md bg-[#000066] w-24 px-2 py-2 text-sm font-semibold text-white shadow-sm"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateVersionForm;
