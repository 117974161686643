import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreatorsApiFactory,
  Configuration,
  UsersApiFactory,
} from "vyse-api-client";
import { axios } from "../utils/http";
import { apiUrl } from "../utils/config";
import { togglePageLoader } from "../components/loader";
import { notify } from "../components/alert";
import { toggleUserActiveStatus } from "./users";

const config = new Configuration({
  basePath: apiUrl,
});

export const getAllCreators = createAsyncThunk(
  "creators/getAllCreators",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).getCreators(
        payload.orderDirection,
        payload.from,
        payload.to,
        undefined,
        null
      );
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getInActiveCreators = createAsyncThunk(
  "creators/getInAvtivePublisher",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).getCreators(
        payload.orderDirection,
        payload.from,
        payload.to,
        undefined,
        false
      );
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getSingleCreator = createAsyncThunk(
  "creators/getSingleCreator",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).getCreator(payload.id);

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getCreatorStat = createAsyncThunk(
  "creators/getCreatorStat",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).getCreatorStatistics(payload.id);
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getCreatorFollowers = createAsyncThunk(
  "creators/getCreatorFollowers",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).totalFollowers(payload.id);
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const addCreator = createAsyncThunk(
  "creator/addCreator",
  async (payload) => {
    try {
      let response = await UsersApiFactory(config, undefined, axios).createUser(
        payload.user,
        payload.signupToken
      );
      if (response.status === 200) {
        notify("success", "Publisher has been created succusfully");
      }
      return response.status;
    } catch (error) {
      notify("error", error.response?.data?.detail);
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const addCreatorsTopic = createAsyncThunk(
  "creators/addCreatorsTopic",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).addCreatorTopic(payload);
      if (response.status === 200) {
        notify("success", "Topic has been add to successfull");
      }
      return response.status;
    } catch (error) {
      if (error.response?.data?.detail) {
        notify("error", error.response?.data?.detail);
        return error.response?.data?.detail;
      }
    }
  }
);

export const removeCreatorsTopic = createAsyncThunk(
  "creators/removeCreatorsTopic",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).removeCreatorTopic(payload.id);
      if (response.status === 200) {
        notify("success", "Topic removed!");
      }
      return response.status;
    } catch (error) {
      if (error.response?.data?.detail) {
        notify("error", error.response?.data?.detail);
        return error.response?.data?.detail;
      }
    }
  }
);

export const updateCreatorTopic = createAsyncThunk(
  "creator/updateTopic",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).updateCreatorTopic(payload.creatorTopicId, {
        api_url: payload.apiUrl,
        topic_id: payload.topicId,
      });
      if (response.status === 200) {
        notify("success", "Topic Edited!");
      }
      return response.status;
    } catch (error) {
      if (error.response?.data?.detail) {
        notify("error", error.response?.data?.detail);
        return error.response?.data?.detail;
      }
    }
  }
);

export const uploadCreatorImage = createAsyncThunk(
  "creators/uploadImage",
  async (payload) => {
    try {
      let response = await UsersApiFactory(
        config,
        undefined,
        axios
      ).uploadUserPhoto(payload.userId, payload.imgFile);
      if (response.status === 200) {
        window.location.reload();
        notify("success", "Image has been updated successfully");
      }
      return response.status;
    } catch (error) {
      if (error.response?.data?.detail) {
        notify("error", error.response?.data?.detail);
        return error.response?.data?.detail;
      }
    }
  }
);

export const deleteCreator = createAsyncThunk(
  "creators/deleteCreator",
  async (payload) => {
    try {
      let response = await CreatorsApiFactory(
        config,
        undefined,
        axios
      ).deleteCreator(payload.id);
      if (response.status === 200) {
        notify("success", "Creator deleted!");
      }
      return response.status;
    } catch (error) {
      if (error.response?.data?.detail) {
        notify("error", error.response?.data?.detail);
        return error.response?.data?.detail;
      }
    }
  }
);

export const creatorSlice = createSlice({
  name: "creators",
  initialState: {
    allCreators: [],
    inActiveCreators: [],
    singleCreator: {},
    totalCreators: 0,
    totalInActiveCreators: 0,
    singleCreatorStat: {},
    singleCreatorFollower: 0,
    addCreatorStatus: null,
    creatorUpdateStatus: null,
    creatorImg: "",
  },

  extraReducers: (builder) => {
    // builder.addCase(getAllCreators.pending, togglePageLoader);
    builder.addCase(getAllCreators.fulfilled, (state, action) => {
      state.allCreators = action.payload.creators;
      state.totalCreators = action.payload.total;
      // togglePageLoader();
    });

    // builder.addCase(getInActiveCreators.pending);
    builder.addCase(getInActiveCreators.fulfilled, (state, action) => {
      state.inActiveCreators = action.payload.creators;
      state.totalInActiveCreators = action.payload.total;
    });

    builder.addCase(getSingleCreator.pending);
    builder.addCase(getSingleCreator.fulfilled, (state, action) => {
      state.singleCreator = action.payload;
    });

    builder.addCase(getCreatorStat.pending);
    builder.addCase(getCreatorStat.fulfilled, (state, action) => {
      state.singleCreatorStat = action.payload;
    });

    builder.addCase(getCreatorFollowers.pending);
    builder.addCase(getCreatorFollowers.fulfilled, (state, action) => {
      state.singleCreatorFollower = action.payload;
    });

    builder.addCase(addCreator.pending, togglePageLoader);
    builder.addCase(addCreator.fulfilled, (state, action) => {
      state.addCreatorStatus = action.payload;
      togglePageLoader();
    });

    builder.addCase(addCreatorsTopic.pending, togglePageLoader);
    builder.addCase(addCreatorsTopic.fulfilled, (state, action) => {
      state.creatorUpdateStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(removeCreatorsTopic.pending, togglePageLoader);
    builder.addCase(removeCreatorsTopic.fulfilled, (state, action) => {
      state.creatorUpdateStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(uploadCreatorImage.pending, togglePageLoader);
    builder.addCase(uploadCreatorImage.fulfilled, (state, action) => {
      state.creatorUpdateStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(updateCreatorTopic.pending, togglePageLoader);
    builder.addCase(updateCreatorTopic.fulfilled, (state, action) => {
      state.creatorUpdateStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(toggleUserActiveStatus.pending, togglePageLoader);
    builder.addCase(toggleUserActiveStatus.fulfilled, (state, action) => {
      if (action.payload === 200) {
        notify("success", "Publisher status have been updated");
      }
      state.creatorUpdateStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(deleteCreator.pending, togglePageLoader);
    builder.addCase(deleteCreator.fulfilled, (state, action) => {
      state.creatorUpdateStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });
  },
});

export default creatorSlice.reducer;
