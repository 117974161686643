import {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";

import { FaceSmileIcon } from "@heroicons/react/20/solid";
import EmojiPicker from "emoji-picker-react";
import { emojiUnicode, usePrevious } from "../../utils/functions/util";
import { addTopic, getTopics } from "../../store/topics";

const AddNewTopic = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal() {
      setOpen(!open);
    },
  }));
  const addTopicStatus = useSelector((state) => state.topics.addTopicStatus);

  const prevStatus = usePrevious(addTopicStatus);
  useEffect(() => {
    if (addTopicStatus !== prevStatus) {
      dispatch(getTopics({ range: { from: 0, to: 10 } }));
      setOpen(false);
    }
  }, [dispatch, addTopicStatus]);

  const [topicInfo, setTopicInfo] = useState({
    title: "",
    refCode: "",
    requiredForReaders: "",
  });

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [emojiCode, setEmojiCode] = useState("");

  function handleOnChange(e) {
    const { name, value } = e.target;
    setTopicInfo({ ...topicInfo, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(
      addTopic({
        title: topicInfo.title,
        emoji_code: emojiUnicode(emojiCode),
        ref_code: topicInfo.refCode,
        required_for_readers: topicInfo.requiredForReaders ? true : false,
      })
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full h-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Add Topic
                    </Dialog.Title>
                    <form className="w-full" onSubmit={(e) => handleSubmit(e)}>
                      <div className="mt-10 gap-y-8 w-full flex flex-col">
                        <div className="w-full">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Title
                          </label>
                          <div className="mt-2">
                            <input
                              value={topicInfo?.title}
                              required
                              type="text"
                              name="title"
                              id="title"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                          </div>
                        </div>

                        <div className="relative">
                          <label
                            htmlFor="emojiCode"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Emoji Code
                          </label>
                          <div className="flex items-center  gap-x-2">
                            <input
                              autoComplete="false"
                              value={emojiCode}
                              required
                              type="text"
                              name="title"
                              id="title"
                              className="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-lg sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                            <FaceSmileIcon
                              onClick={() =>
                                setShowEmojiPicker(!showEmojiPicker)
                              }
                              className="h-8 w-8 text-[#ffc83d]"
                            />
                          </div>
                          {showEmojiPicker && (
                            <div className="absolute w-full">
                              <EmojiPicker
                                width={"100%"}
                                onEmojiClick={(e) => {
                                  setEmojiCode(e.emoji);
                                  setShowEmojiPicker(false);
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="refCode"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Ref code
                          </label>
                          <div className="mt-2">
                            <input
                              value={topicInfo?.refCode}
                              required
                              id="refCode"
                              name="refCode"
                              type="refCode"
                              autoComplete="refCode"
                              className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-[#000066] sm:text-sm sm:leading-6"
                              onChange={(e) => handleOnChange(e)}
                            />
                          </div>
                        </div>

                        <div className="flex items-center gap-x-2">
                          <input
                            id="requiredForReaders"
                            aria-describedby="requiredForReaders-description"
                            name="requiredForReaders"
                            type="checkbox"
                            onChange={(e) =>
                              setTopicInfo({
                                ...topicInfo,
                                [e.target.name]: e.target.checked,
                              })
                            }
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor="requiredForReaders"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Required for readers
                          </label>
                        </div>
                      </div>

                      <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                          onClick={() => setOpen(false)}
                          type="button"
                          className="text-sm w-[50%] font-semibold leading-6 text-gray-900"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded-md bg-[#000066]  w-[50%] px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000066]"
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default AddNewTopic;
