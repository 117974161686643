import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../../components/emptyState";
import { togglePageLoader } from "../../components/loader";
import Pagination from "../../components/new-pagination";

import ReadersStatCard from "../../components/readers/readers-stat";
import ReadersTable from "../../components/readers/readers-table";
import PostLogin from "../../layout/post-login";
import {
  getAllReaders,
  getAnonymousReaders,
  getKnownReaders,
} from "../../store/reader";

import ReadersFilter from "../../components/readers/readers-filter";

export default function AllReaders() {
  const dispatch = useDispatch();

  const totalReader = useSelector((state) => state.readers.totalReaders);

  const totalAnonynmousReaders = useSelector(
    (state) => state.readers.totalAnonymousReaders
  );

  const totalKnownReaders = useSelector(
    (state) => state.readers.totalKnownReaders
  );

  const [range, setRange] = useState({ from: 0, to: 10 });

  const [query, setQuery] = useState({
    direction: "DESC",
    date: "DATE_CREATED",
  });

  const [tableData, setTableData] = useState({ data: [], totalItem: 0 });
  const paginationRef = useRef("");

  useEffect(() => {
    togglePageLoader();
    function fetchAllData() {
      dispatch(getAllReaders({ ...range, orderDirection: query.direction }));
      dispatch(
        getAnonymousReaders({ ...range, orderDirection: query.direction })
      );
      dispatch(getKnownReaders({ ...range, orderDirection: query.direction }));
    }
    document.title = "Readers - Admin Vyse News";
    fetchAllData();
    togglePageLoader();
  }, []);

  return (
    <PostLogin>
      {totalReader === 0 ? (
        <EmptyState StateText={"Reader"} />
      ) : (
        <div className="pb-8 sm:py-11">
          <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
            Readers
          </h3>
          <ReadersStatCard
            stat1={totalReader}
            stat2={totalAnonynmousReaders}
            stat3={totalKnownReaders}
          />
          <div className="w-full bg-white border border-[#EAECF0] rounded-lg shadow-sm mt-12">
            <ReadersFilter
              readersQuery={query}
              setReadersQuery={setQuery}
              setTableData={setTableData}
              range={range}
            />
            <ReadersTable tableData={tableData?.data} />
            <Pagination
              ref={paginationRef}
              totalItems={tableData?.totalItem}
              itemsOnDisplay={tableData?.data?.length}
              range={range}
              setRange={setRange}
              tableName="readers"
            />
          </div>
        </div>
      )}
    </PostLogin>
  );
}
