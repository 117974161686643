import React, { useState } from "react";

export default function TextInput({
  type,
  value,
  label,
  required,
  placeholder,
  setterFunction,
}) {
  const componentId = Math.floor(Math.random() * 100000) + 1;
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  return (
    <>
      {type === "password" ? (
        <div>
          <label
            htmlFor={"password" + componentId}
            className="inline-block text-[13px] mb-2.5 text-[#101828]"
          >
            {label}
          </label>
          <div className="relative">
            <input
              id={"password" + componentId}
              type={passwordIsVisible ? "text" : "password"}
              className="block w-full rounded-md border-0 px-5 pr-12 py-[14px] text-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={placeholder ? placeholder : "﹡﹡﹡﹡﹡﹡﹡﹡"}
              defaultValue={value}
              required={required}
              onChange={(e) =>
                typeof setterFunction != "undefined" &&
                setterFunction(e.target.value)
              }
            />

            <div className="absolute top-1/3 right-[15px]">
              {!passwordIsVisible ? (
                <img
                  src="/images/icons/visibility.png"
                  alt="visible"
                  width={16}
                  height={13}
                  className="cursor-pointer"
                  onClick={() => setPasswordIsVisible(true)}
                />
              ) : (
                <img
                  src="/images/icons/visibility_off.png"
                  alt="not visible"
                  width={16}
                  height={13}
                  className="cursor-pointer"
                  onClick={() => setPasswordIsVisible(false)}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <label
            htmlFor={type + componentId}
            className="inline-block text-[13px] mb-2.5 text-[#101828]"
          >
            {label}
          </label>
          <input
            id={type + componentId}
            type={type}
            className="block w-full rounded-md border-0 px-5 pr-12 py-[14px] text-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            defaultValue={value}
            required={required}
            onChange={(e) =>
              typeof setterFunction != "undefined" &&
              setterFunction(e.target.value)
            }
          />
        </div>
      )}
    </>
  );
}
