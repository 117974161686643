import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../utils/config";
import { usePrepPath } from "../../utils/routeHandler";
import { useDispatch } from "react-redux";
import { sendPushNotification } from "../../store/stories";

export default function StoriesGrid({ allStories }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const readerDetailsPath = usePrepPath("details/");
  const redirectToStoriesDetails = (id) => navigate(readerDetailsPath + id);

  const handleSendPushNotificationToAlerReaders = (storyId) => {
    dispatch(sendPushNotification({ storyId }));
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
        <div className="mt-8 grid grid-cols-1  gap-y-12 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 grid-flow-row sm:auto-rows-min lg:auto-rows-min">
          {allStories.map((story) => (
            <div key={story?.id + Math.random()}>
              <div
                onClick={() => redirectToStoriesDetails(story?.id)}
                className="lg:h-96 relative cursor-pointer"
              >
                <div className="relative h-72 w-full overflow-hidden rounded-lg ">
                  <img
                    loading="lazy"
                    src={
                      story?.creator.user?.profile?.photo_file
                        ? `${apiUrl}/stories/${story?.id}/download-photo`
                        : story?.creator.user?.profile?.avatar_url
                    }
                    alt={story?.creator.user.profile.first_name_raw}
                    className="h-full w-full object-cover object-center hover:scale[0.5px]"
                  />
                </div>
                <div className="relative mt-4">
                  <h3
                    className="story_preveiw_text text-sm font-medium text-gray-900"
                    dangerouslySetInnerHTML={{ __html: story?.title_raw }}
                  />
                  {/* <p className="mt-1 text-sm text-gray-500">{story?.color}</p> */}
                </div>
                <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                  />
                  <p className="relative text-lg font-semibold text-white">
                    {story?.total_views} views
                  </p>
                </div>
              </div>
              <div className="mt-6">
                <button
                  onClick={() =>
                    handleSendPushNotificationToAlerReaders(story?.id)
                  }
                  type="button"
                  className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 w-full"
                >
                  Trigger alert
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
