import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/auth/login";
import AllUsers from "./pages/users/all-users";
import Error404 from "./pages/404";
import Maintainance from "./pages/maintainance";
import AllCreators from "./pages/creators/all-creators";
import AllReaders from "./pages/readers/all-readers";
import UserDetails from "./pages/users/users-details";
import { ProtectedRoute } from "./components/protected-route";
import ReadersDetails from "./pages/readers/new-readers-details";
import CreatorsDetails from "./pages/creators/creator-details";
import Topics from "./pages/topics/all-topics";
import TopicDetails from "./pages/topics/topics-details";
import Stories from "./pages/stories/all-stories";
import StoryDetails from "./pages/stories/story-details";
import Features from "./pages/features/all-features";
import FeatureDetails from "./pages/features/feature-details";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/users",

    element: <ProtectedRoute children={<AllUsers />} />,
  },
  {
    path: "/users/details/:userId",
    element: <ProtectedRoute children={<UserDetails />} />,
  },
  {
    path: "/publishers",
    element: <ProtectedRoute children={<AllCreators />} />,
  },
  {
    path: "/publishers/details/:publisherId",
    element: <ProtectedRoute children={<CreatorsDetails />} />,
  },
  {
    path: "/readers",
    element: <ProtectedRoute children={<AllReaders />} />,
  },
  {
    path: "/readers/details/:readerId",
    element: <ProtectedRoute children={<ReadersDetails />} />,
  },
  {
    path: "/topics",
    element: <ProtectedRoute children={<Topics />} />,
  },
  {
    path: "/topics/details/:topicId",
    element: <ProtectedRoute children={<TopicDetails />} />,
  },
  {
    path: "/stories",
    element: <ProtectedRoute children={<Stories />} />,
  },
  {
    path: "/stories/details/:storyId",
    element: <ProtectedRoute children={<StoryDetails />} />,
  },
  {
    path: "/features",
    element: <ProtectedRoute children={<Features />} />,
  },
  {
    path: "/features/details/:featureId",
    element: <ProtectedRoute children={<FeatureDetails />} />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
  {
    path: "/maintainance",
    element: <Maintainance />,
  },
]);

export { router };
