import { formatDate, getLastSeen } from "../../utils/functions/formatDate";
import RemoveUser from "./remover-user";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePrepPath } from "../../utils/routeHandler";
import { getRole } from "../../utils/functions/util";
import { apiUrl } from "../../utils/config";

export default function UserTable({ tableData }) {
  const users = tableData;
  const removeUserRef = useRef();
  const navigate = useNavigate();
  const userDetailsPath = usePrepPath("details/");
  const redirectToUserDetails = (id) => navigate(userDetailsPath + id);

  return (
    <div className="sm:px-6 py-8">
      <RemoveUser ref={removeUserRef} />

      <p className="text-sm text-gray-700">
        View and manage all users on the platform
      </p>

      <div className="flow-root">
        <div className="mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date Joined
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Last Seen
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Role
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users?.map((user) => (
                  <tr key={user.email}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <div className="h-11 w-11 flex-shrink-0">
                          <img
                            className="h-11 w-11 rounded-full object-cover"
                            src={
                              user?.profile?.photo_file
                                ? `${apiUrl}/users/${user.id}/download-photo`
                                : user?.profile?.avatar_url
                            }
                            alt={`${user.profile.first_name_raw} ${user.profile.last_name_raw}`}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {`${user.profile.first_name_raw} ${user.profile.last_name_raw}`}
                          </div>
                          <div className="mt-1 text-gray-500">{user.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {formatDate(user.access_begin)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {getLastSeen(user.last_login)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">{getRole(user)}</div>
                      <div className="mt-1 text-gray-500">
                        {user.is_anonymous ? "Anonymous" : "Not Anonymous"}
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        redirectToUserDetails(user.id);
                      }}
                      className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                    >
                      <img
                        className="cursor-pointer"
                        src="images/icons/eye.svg"
                        alt="eye icon"
                      />
                      <span className="opacity-0">Details</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
