import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

const Pagination = forwardRef((props, ref) => {
  const {
    totalItems,
    itemsOnDisplay,
    range,
    setRange,
    tableName,
    itemsPerPage = 10,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  useImperativeHandle(ref, () => ({
    setCurrentPage(value) {
      updateCurrentPage(value);
    },
  }));

  useEffect(() => {
    setNumberOfPages(Math.ceil(totalItems / parseInt(itemsPerPage)));
    updateCurrentPage(currentPage);
  }, [totalItems, currentPage]);

  const updateCurrentPage = (value) => {
    let from = (value - 1) * itemsPerPage;
    let to = itemsPerPage;

    setRange({ from, to });
  };

  const switchPage = (value) => {
    setCurrentPage(value);
    updateCurrentPage(value);
  };

  const nextPage = () => {
    if (currentPage + 1 > numberOfPages) {
      setCurrentPage(numberOfPages);
    } else {
      setCurrentPage(currentPage + 1);
      updateCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage - 1 < 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
      updateCurrentPage(currentPage - 1);
    }
  };

  if (totalItems > 0) {
    return (
      <div className="flex flex-col lg:flex-row justify-between py-3 px-8">
        <div className="flex flex-col lg:flex-row items-center space-x-2 text-xs">
          Page
          <select
            value={currentPage}
            onChange={(e) => switchPage(e.target.value)}
            className="py-2 px-2 bg-white text-gray-600 font-medium rounded hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50 inline-flex items-center"
          >
            {Array.from({ length: numberOfPages }, (_, index) => (
              <option value={index + 1} key={index}>
                {index + 1}
              </option>
            ))}
          </select>
          <p className="text-gray-500 mt-4 lg:mt-0 text-xs">
            Showing {range.from + 1} to {range.from + itemsOnDisplay} of{" "}
            {totalItems} {tableName}
          </p>
        </div>
        <nav
          aria-label="Pagination"
          className="flex justify-center items-center text-gray-600 mt-8 lg:mt-0 text-xs"
        >
          <svg
            onClick={() => previousPage()}
            xmlns="http://www.w3.org/2000/svg"
            className="p-2 mr-4 rounded hover:bg-gray-100"
            height={40}
            width={45}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <svg
            className="p-2 ml-4 rounded hover:bg-gray-100"
            onClick={() => nextPage()}
            height={40}
            width={45}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </nav>
      </div>
    );
  }
});

export default Pagination;
