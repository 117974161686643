import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthApiFactory, Configuration } from "vyse-api-client";
import { apiUrl } from "../utils/config";
import { notify } from "../components/alert";

const config = new Configuration({
  basePath: apiUrl,
});

export const login = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    let data = new FormData();
    data.append("username", payload.email);
    data.append("password", payload.password);

    try {
      let response = await AuthApiFactory(config).login(
        payload.email,
        payload.password
      );
      localStorage.setItem(
        "token",
        `${response.data.token_type} ${response.data.access_token}`
      );

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        throw error;
      }

      return rejectWithValue(error.response?.data?.detail);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: {},
    errRes: "",
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      window.location.href = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      window.location.href = "/users";
    });
    builder.addCase(login.rejected, (state, action) => {
      notify("error", action.payload ? action.payload : action.error.message);
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
