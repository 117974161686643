import React, { useEffect, useState } from "react";
import UsersTable from "../../components/users/users-table";
import PostLogin from "../../layout/post-login";
import Pagination from "../../components/new-pagination";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../store/users";
import EmptySate from "../../components/emptyState";
import "../../styles/index.scss";
import StatCard from "../../components/users/users-stat";
import { getAllReaders } from "../../store/reader";
import { getAllCreators } from "../../store/creator";
import { togglePageLoader } from "../../components/loader";

export default function AllUsers() {
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.users.allUsers);
  const totalUsers = useSelector((state) => state.users.totalUsers);
  const totalReader = useSelector((state) => state.readers.totalReaders);
  const totalCreators = useSelector((state) => state.creators.totalCreators);

  const [range, setRange] = useState({ from: 0, to: 10 });

  useEffect(() => {
    togglePageLoader();
    document.title = "Users - Admin Vyse News";
    dispatch(getAllReaders({ ...range, orderDirection: "DESC" }));
    dispatch(getAllCreators({ ...range, orderDirection: "DESC" }));
    togglePageLoader();
  }, []);

  useEffect(() => {
    dispatch(getAllUsers({ range }));
  }, [dispatch, range]);

  return (
    <PostLogin>
      {totalUsers === 0 ? (
        <EmptySate StateText={"No user found"} />
      ) : (
        <div className="py-11">
          <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
            Users
          </h3>
          <StatCard
            stat1={totalUsers}
            stat2={totalReader}
            stat3={totalCreators}
          />
          <div className=" w-full bg-white border border-[#EAECF0] rounded-lg shadow-sm mt-12">
            <UsersTable tableData={allUsers} />

            <Pagination
              totalItems={totalUsers}
              itemsOnDisplay={allUsers?.length}
              range={range}
              setRange={setRange}
              tableName="users"
            />
          </div>
        </div>
      )}
    </PostLogin>
  );
}
