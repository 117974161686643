import { Link } from "react-router-dom";

export default function Maintainance() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-8 sm:py-16 lg:px-8">
        <div className="text-center">
          <div className="text-base font-semibold text-indigo-600">
            <img
              src="/images/icons/maintenance.svg"
              alt="maintenance"
              height={200}
              width={350}
              className="mb-8 m-auto"
            />
          </div>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Under Maintianance
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600 px-3.5  md:text-lg">
            Sorry for the inconvenience but we’re performing some maintenance at
            the moment. If you need to you can always.
            <Link to="#" className="text-[#000066] hover:underline">
              contact us
            </Link>
            ,otherwise we’ll be back online shortly!.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-[#000066] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000066] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </Link>
            <Link to="#" className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
