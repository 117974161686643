export const formatDate = (date, formart = null) => {
  if (!date) {
    return "∞";
  }
  const newFormat = new Date(date);

  const day = newFormat.toLocaleDateString("en-US", { day: "numeric" });
  const month = newFormat.toLocaleDateString("en-US", { month: "short" });
  const year = newFormat.toLocaleDateString("en-US", {
    year: "numeric",
  });

  if (formart === "yyyy-mm-dd") {
    const month = newFormat.toLocaleDateString("en-US", { month: "2-digit" });
    return [year, month, day].join("-");
  }
  return `${day}${nthNumber(day)} ${month}, ${year}`;
};

export const getLastSeen = (date) => {
  if (!date) {
    return "Hasn't logged in";
  }
  const today = new Date();
  const lastLoginDate = new Date(date);
  const MILLISECONDS_PER_SEC = 1000;

  const SEC = 60;
  const SEC_PER_MIN = 60;
  const SEC_PER_HOUR = 3_600;
  const SEC_PER_DAY = 86_400;
  const SEC_PER_MONTH = 2_592_000;
  const SEC_PER_YEAR = 31_536_000;

  let diff = Math.abs(today - lastLoginDate) / MILLISECONDS_PER_SEC;

  let years = Math.floor(diff / SEC_PER_YEAR);
  diff %= SEC_PER_YEAR;

  let months = Math.floor(diff / SEC_PER_MONTH);
  diff %= SEC_PER_MONTH;

  let days = Math.floor(diff / SEC_PER_DAY);
  diff %= SEC_PER_DAY;

  let hours = Math.floor(diff / SEC_PER_HOUR);
  diff %= SEC_PER_HOUR;

  let minutes = Math.floor(diff / SEC_PER_MIN);
  let seconds = Math.floor(diff % SEC);

  return formatLastSeen({ years, months, days, minutes, hours, seconds });
};

const formatLastSeen = ({ years, months, days, minutes, hours, seconds }) => {
  switch (true) {
    case years === 0 &&
      months === 0 &&
      days === 0 &&
      hours === 0 &&
      minutes === 0:
      // return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
      return `Just now`;
    case years === 0 && months === 0 && days === 0 && hours === 0:
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    case years === 0 && months === 0 && days === 0:
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    case years === 0 && months === 0:
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    case years === 0:
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    default:
      return `${years} ${years === 1 ? "year" : "years"} ago`;
  }
};

export const nthNumber = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
