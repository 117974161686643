import PostLogin from "../../layout/post-login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../../utils/functions/formatDate";
import { getSingleTopic, getTopicStats, updateTopic } from "../../store/topics";
import {
  convertUnicodeToReference,
  emojiUnicode,
  usePrevious,
} from "../../utils/functions/util";
import { getUser } from "../../store/users";

import { FaceSmileIcon, PencilIcon } from "@heroicons/react/24/outline";
import EmojiPicker from "emoji-picker-react";
import Alert, { notify } from "../../components/alert";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TopicDetails() {
  const params = useParams();
  const topic = useSelector((state) => state.topics.singleTopic);
  const topicStat = useSelector((state) => state.topics.topicStat);
  const topicCreator = useSelector((state) => state.users.singleUser);
  const updateStatus = useSelector((state) => state.topics.updateTopicStatus);
  const dispatch = useDispatch();

  const [topicInfo, setTopicInfo] = useState({
    title: "",
    ref_code: "",
    required_for_readers: "",
  });
  const [topicUpdate, setTopicUpdate] = useState({});

  const [emojiCode, setEmojiCode] = useState("");
  const [disableEdit, setDisableEdith] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  function handleOnChange(e) {
    const { name, value, type, checked } = e.target;

    setTopicInfo({
      ...topicInfo,
      [name]: type === "checkbox" ? checked : value.trim(),
    });
    setTopicUpdate({
      ...topicUpdate,
      [name]: type === "checkbox" ? checked : value.trim(),
    });
  }

  useEffect(() => {
    document.title = "Topic Details - Admin Vyse News";
    dispatch(getTopicStats({ id: params.topicId }));
    dispatch(getSingleTopic({ id: params.topicId }));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(getUser({ id: topic?.created_by_user?.id }));
    setTopicInfo({
      ...setTopicInfo,
      title: topic?.title,
      ref_code: topic?.ref_code,
      required_for_readers: topic?.required_for_readers,
      make_active: topic?.is_active,
      emoji_code: topic?.emoji_code,
    });
    setEmojiCode(convertUnicodeToReference(topic?.emoji_code));
  }, [dispatch, topic]);

  const prevStatus = usePrevious(updateStatus);
  useEffect(() => {
    if (updateStatus !== prevStatus) {
      dispatch(getTopicStats({ id: params.topicId }));
      dispatch(getSingleTopic({ id: params.topicId }));
    }
  }, [dispatch, updateStatus]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const prevTopicDetails = {
      title: topic?.title,
      ref_code: topic?.ref_code,
      required_for_readers: topic?.required_for_readers,
      make_active: topic?.is_active,
      emoji_code: topic?.emoji_code,
    };

    if (JSON.stringify(topicInfo) === JSON.stringify(prevTopicDetails)) {
      notify("info", "You haven't made any change to this topic yet");
      return;
    }
    dispatch(
      updateTopic({
        id: params.topicId,
        update: topicUpdate,
      })
    );
  };

  return (
    <PostLogin>
      {Object.keys(topic).length !== 0 &&
        Object.keys(topicStat).length !== 0 && (
          <>
            <Alert />
            <header>
              <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-[#e6e6f0] px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 border-b border-[#c4c4c4] items-center justify-center">
                <div>
                  <div className="flex items-center gap-x-3">
                    <h1 className="flex gap-x-3 text-base leading-7">
                      <span className="font-semibold text-[#101828]">
                        {topic.title}
                      </span>
                      <span className="text-gray-600">/</span>
                      <span className="font-semibold text-[#101828]">
                        {convertUnicodeToReference(topic.emoji_code)}
                      </span>
                    </h1>
                  </div>
                  <p className="mt-2 text-xs leading-6 text-gray-600"></p>
                </div>
                <div className="cursor-pointer order-first flex-none rounded-full bg-[#000066]/10 px-2 py-1 text-xs font-medium text-[#000066] ring-1 ring-inset ring-[#000066]/30 sm:order-none">
                  Topic
                </div>
              </div>

              {/* Stats */}

              <div className="grid grid-cols-2 bg-[#e6e6f0] sm:grid-cols-2 lg:grid-cols-4">
                <div
                  className={classNames(
                    "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                  )}
                >
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    Date Created
                  </p>
                  <p className="mt-2 flex items-center gap-x-2">
                    <span className="text-sm font-semibold leading-6 text-gray-900">
                      {formatDate(topic.date_created)}
                    </span>
                  </p>
                </div>
                <div
                  className={classNames(
                    "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                  )}
                >
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    Created by
                  </p>
                  <p className="mt-2 flex items-center gap-x-2">
                    <span className="text-sm font-semibold leading-6 text-gray-900">
                      {topicCreator?.profile?.first_name_raw +
                        " " +
                        topicCreator?.profile?.last_name_raw}
                    </span>
                  </p>
                </div>
                <div
                  className={classNames(
                    "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                  )}
                >
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    Readers
                  </p>
                  <p className="mt-2 flex items-center gap-x-2">
                    <span className="text-sm font-semibold leading-6 text-gray-900">
                      {topicStat?.topics[0]?.readers}
                    </span>
                  </p>
                </div>
                <div
                  className={classNames(
                    "border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                  )}
                >
                  <p className="text-sm font-medium leading-6 text-gray-600">
                    Publishers
                  </p>
                  <p className="mt-2 flex items-center gap-x-2">
                    <span className="text-sm font-semibold leading-6 text-gray-900">
                      {topicStat?.topics[0]?.creators}
                    </span>
                  </p>
                </div>
              </div>
            </header>

            <div className="border-b border-gray-900/10 w-full mx-auto mt-8 ">
              <div className="col-span-4">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Update Topic
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Topic can be edited and updated, click edit button to enable
                  input edit
                </p>
              </div>

              <form className="w-full my-8" onSubmit={(e) => handleSubmit(e)}>
                {/* <button
                  onClick={() => setDisableEdith(!disableEdit)}
                  type="button"
                  className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-[#030366] px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Edit
                </button> */}

                <div className="gap-y-8 gap-x-8 w-full md:w-[80%] grid grid-cols-1 md:grid-cols-2">
                  <div className="">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Title
                    </label>

                    <input
                      autoComplete="false"
                      // disabled={disableEdit ?? false}
                      value={topicInfo?.title}
                      required
                      type="text"
                      name="title"
                      id="title"
                      className={`${
                        disableEdit ? "cursor-not-allowed bg-[#e6e6f0]" : null
                      } block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="emojiCode"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Emoji Code
                    </label>
                    <div className="flex items-center  gap-x-2">
                      <input
                        autoComplete="false"
                        // disabled={disableEdit ?? false}
                        value={emojiCode}
                        required
                        type="text"
                        name="emoji"
                        id="title"
                        className={`${
                          disableEdit ? "cursor-not-allowed bg-[#e6e6f0]" : null
                        } block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
                        // onChange={(e) => handleOnChange(e)}
                      />
                      <FaceSmileIcon
                        onClick={() =>
                          !disableEdit && setShowEmojiPicker(!showEmojiPicker)
                        }
                        className="h-8 w-8 text-[#ffc83d]"
                      />
                    </div>
                    {showEmojiPicker && (
                      <div className="absolute w-full">
                        <EmojiPicker
                          width="100%"
                          onEmojiClick={(e) => {
                            setEmojiCode(e.emoji);
                            setShowEmojiPicker(false);
                            setTopicUpdate((prev) => ({
                              ...prev,
                              emoji_code: emojiUnicode(e.emoji),
                            }));
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="">
                    <label
                      htmlFor="ref_code"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Ref code
                    </label>

                    <input
                      autoComplete="false"
                      // disabled={disableEdit ?? false}
                      value={topicInfo?.ref_code}
                      required
                      id="ref_code"
                      name="ref_code"
                      type="text"
                      className={`${
                        disableEdit ? "cursor-not-allowed bg-[#e6e6f0]" : null
                      } block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>

                  <div className="flex items-center gap-x-2">
                    <input
                      autoComplete="false"
                      // disabled={disableEdit ?? false}
                      checked={topicInfo?.required_for_readers}
                      id="required_for_readers"
                      aria-describedby="required_for_readers-description"
                      name="required_for_readers"
                      type="checkbox"
                      onChange={(e) => handleOnChange(e)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="required_for_readers"
                      className={`${
                        disableEdit ? "cursor-not-allowed" : null
                      } block text-sm font-medium leading-6 text-gray-900`}
                    >
                      Required for readers
                    </label>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <input
                      autoComplete="false"
                      // disabled={disableEdit ?? false}
                      checked={topicInfo?.make_active}
                      id="make_active"
                      aria-describedby="make_active-description"
                      name="make_active"
                      type="checkbox"
                      onChange={(e) => handleOnChange(e)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="make_active"
                      className={`${disableEdit ? "cursor-not-allowed" : null} 
                      block text-sm font-medium leading-6 text-gray-900`}
                    >
                      Make topic active
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  className=" mt-4 block bg-[#030366] w-full md:w-[50%] lg:w-[200px] rounded-md border-0 p-3 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  Save Changes
                </button>
              </form>
            </div>
          </>
        )}
    </PostLogin>
  );
}
