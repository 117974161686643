import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { formatDate } from "../../utils/functions/formatDate";
import {
  HandThumbUpIcon,
  MagnifyingGlassCircleIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import Rating from "../rating";
import { apiUrl } from "../../utils/config";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteStory,
  getAbuseReport,
  getStory,
  sendPushNotification,
  updateStory,
} from "../../store/stories";
import DeleteDialog from "../delete-dialog";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Story() {
  const dispatch = useDispatch();
  const param = useParams();
  const storyDetails = useSelector((state) => state.stories.story);
  const storyReport = useSelector((state) => state.stories.report);

  useEffect(() => {
    dispatch(getStory({ id: param.storyId }));
    dispatch(getAbuseReport({ storyId: param.storyId }));
  }, [dispatch, param]);

  const deleteDialog = useRef("");

  const handleSendPushNotificationToAlerReaders = () => {
    dispatch(sendPushNotification({ storyId: param.storyId }));
  };

  const handleUpdateStory = () => {
    dispatch(
      updateStory({
        storyId: param.storyId,
        body: JSON.stringify({ featured: true }),
      })
    );
    return dispatch(getStory({ id: param.storyId }));
  };

  const params = useParams();

  const handleDeleteStory = () => {
    dispatch(deleteStory({ storyId: params.storyId }));
    return deleteDialog.current.toggleModal(true);
  };

  return (
    <div className="bg-white">
      <DeleteDialog
        handleDelete={handleDeleteStory}
        title="Delete Story"
        text="Are you sure you want to DELETE this story? This story will be be permanently removed from the database. This action cannot be undone."
        ref={deleteDialog}
      />

      <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {/* Story */}
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Story image */}
          <div className="lg:col-span-4 lg:row-end-1">
            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
              <img
                loading="lazy"
                src={`${apiUrl}/stories/${storyDetails?.id}/download-photo`}
                alt={
                  "Sample of 30 icons with friendly and fun details in outline, filled, and brand color styles."
                }
                className="object-cover object-center"
              />
            </div>
          </div>

          {/* Product details */}
          <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1
                  className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl"
                  dangerouslySetInnerHTML={{ __html: storyDetails?.title_raw }}
                />
                <div className="flex items-center gap-x-2 mt-4">
                  <img
                    loading="lazy"
                    src={
                      storyDetails?.creator?.user?.profile?.photo_file
                        ? `${apiUrl}/users/${storyDetails?.creator?.user.id}/download-photo`
                        : storyDetails?.creator?.user?.profile?.avatar_url
                    }
                    alt={storyDetails?.creator?.user?.profile.first_name_raw}
                    className="h-8 w-8 rounded-full bg-gray-100"
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Published by{" "}
                    {storyDetails?.creator?.user?.profile?.first_name_raw +
                      " " +
                      storyDetails?.creator?.user?.profile?.last_name_raw}{" "}
                    on{" "}
                    <time dateTime={storyDetails?.date_created}>
                      {formatDate(storyDetails?.date_created)}
                    </time>
                  </p>
                </div>
              </div>

              <div>
                <Rating
                  views={storyDetails?.total_views}
                  likes={storyDetails?.total_likes}
                />
              </div>
            </div>

            <p
              className="mt-6 text-gray-500"
              dangerouslySetInnerHTML={{ __html: storyDetails?.description }}
            ></p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              <button
                onClick={() => handleSendPushNotificationToAlerReaders()}
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#030366] px-4 py-3 text-sm font-medium text-white"
              >
                Trigger notification
              </button>
              <button
                onClick={() => handleUpdateStory()}
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#030366]/5 px-4 py-3 text-sm font-medium text-[#030366]"
              >
                Mark featured
              </button>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Highlights</h3>
              <div className="prose prose-sm mt-4 text-gray-500">
                <ul className="flex flex-col gap-4 text-base">
                  <li className="flex gap-x-10">
                    <span className="flex gap-x-2 items-center">
                      <MagnifyingGlassCircleIcon className="h-4 w-4" /> Views:
                    </span>
                    <span className="font-semibold">
                      {storyDetails?.total_views}
                    </span>
                  </li>
                  <li className="flex gap-x-10">
                    <span className="flex gap-x-2 items-center">
                      <ShareIcon className="h-4 w-4" /> Shares:
                    </span>
                    <span className="font-semibold">
                      {storyDetails?.total_shares}
                    </span>
                  </li>
                  <li className="flex gap-x-10">
                    <span className="flex gap-x-2 items-center">
                      <HandThumbUpIcon className="h-4 w-4" /> Likes:
                    </span>
                    <span className="font-semibold">
                      {storyDetails?.total_likes}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-red-700">DELETE</h3>
              <p className="mt-4 text-sm text-gray-500">
                Deleting a story is a permanent action
                <button
                  onClick={() => deleteDialog.current.toggleModal(true)}
                  type="button"
                  className="mt-4 rounded-md block bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                >
                  Delete story
                </button>
              </p>
            </div>
          </div>

          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
            <Tab.Group as="div">
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-[#030366] text-[#030366]"
                          : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                        "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                      )
                    }
                  >
                    Story Content
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-[#030366] text-[#030366]"
                          : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                        "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                      )
                    }
                  >
                    Reports
                  </Tab>
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="sr-only">Story content</h3>

                  <p
                    className="prose prose-sm mt-2 max-w-none text-gray-500 show-links"
                    dangerouslySetInnerHTML={{
                      __html: storyDetails?.content_html,
                    }}
                  />
                </Tab.Panel>

                <Tab.Panel className="-mb-10">
                  {storyReport?.map((report, reviewIdx) => (
                    <div
                      key={report.id}
                      className="flex space-x-4 text-sm text-gray-500"
                    >
                      <div
                        className={classNames(
                          reviewIdx === 0 ? "" : "border-t border-gray-200",
                          "py-10"
                        )}
                      >
                        <h3 className="font-medium text-gray-900">
                          {report.main_reason}
                        </h3>
                      </div>
                    </div>
                  ))}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
