/* tslint:disable */
/* eslint-disable */
/**
 * dbulletin HTTP API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: admin@vyse.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'token_type'?: string;
}
/**
 * 
 * @export
 * @interface AccountDeletionFeedbackOut
 */
export interface AccountDeletionFeedbackOut {
    /**
     * 
     * @type {string}
     * @memberof AccountDeletionFeedbackOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeletionFeedbackOut
     */
    'reader_id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeletionFeedbackOut
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeletionFeedbackOut
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface AppResponseModel
 */
export interface AppResponseModel {
    /**
     * 
     * @type {string}
     * @memberof AppResponseModel
     */
    'detail': string;
}
/**
 * 
 * @export
 * @interface BodyEditRoleRolesRoleIdPut
 */
export interface BodyEditRoleRolesRoleIdPut {
    /**
     * 
     * @type {string}
     * @memberof BodyEditRoleRolesRoleIdPut
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BodyEditRoleRolesRoleIdPut
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ChangePasswordWithToken
 */
export interface ChangePasswordWithToken {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordWithToken
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordWithToken
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface CommentIn
 */
export interface CommentIn {
    /**
     * 
     * @type {string}
     * @memberof CommentIn
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CommentIn
     */
    'parent_comment_id'?: string;
}
/**
 * 
 * @export
 * @interface CommentOut
 */
export interface CommentOut {
    /**
     * 
     * @type {string}
     * @memberof CommentOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommentOut
     */
    'story_id': string;
    /**
     * 
     * @type {string}
     * @memberof CommentOut
     */
    'parent_comment_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentOut
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof CommentOut
     */
    'up_votes_counts': number;
    /**
     * 
     * @type {number}
     * @memberof CommentOut
     */
    'down_votes_counts': number;
    /**
     * 
     * @type {string}
     * @memberof CommentOut
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentOut
     */
    'date_modified'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentOut
     */
    'reader_id': string;
    /**
     * 
     * @type {ReaderOut}
     * @memberof CommentOut
     */
    'reader': ReaderOut;
    /**
     * 
     * @type {number}
     * @memberof CommentOut
     */
    'child_comments_counts'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommentOut
     */
    'voted_up'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentOut
     */
    'voted_down'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentOut
     */
    'hidden'?: boolean;
}
/**
 * 
 * @export
 * @interface CreatorOut
 */
export interface CreatorOut {
    /**
     * 
     * @type {string}
     * @memberof CreatorOut
     */
    'id': string;
    /**
     * 
     * @type {UserOut}
     * @memberof CreatorOut
     */
    'user': UserOut;
    /**
     * 
     * @type {string}
     * @memberof CreatorOut
     */
    'website_url'?: string;
    /**
     * 
     * @type {Array<CreatorTopicOut>}
     * @memberof CreatorOut
     */
    'creator_topics': Array<CreatorTopicOut>;
}
/**
 * 
 * @export
 * @interface CreatorOutWithApiURL
 */
export interface CreatorOutWithApiURL {
    /**
     * 
     * @type {string}
     * @memberof CreatorOutWithApiURL
     */
    'id': string;
    /**
     * 
     * @type {UserOut}
     * @memberof CreatorOutWithApiURL
     */
    'user': UserOut;
    /**
     * 
     * @type {string}
     * @memberof CreatorOutWithApiURL
     */
    'website_url'?: string;
    /**
     * 
     * @type {Array<CreatorTopicOutWithApiURL>}
     * @memberof CreatorOutWithApiURL
     */
    'creator_topics': Array<CreatorTopicOutWithApiURL>;
}
/**
 * 
 * @export
 * @interface CreatorStatistics
 */
export interface CreatorStatistics {
    /**
     * 
     * @type {number}
     * @memberof CreatorStatistics
     */
    'total_shares': number;
    /**
     * 
     * @type {number}
     * @memberof CreatorStatistics
     */
    'total_views': number;
    /**
     * 
     * @type {number}
     * @memberof CreatorStatistics
     */
    'total_likes': number;
}
/**
 * 
 * @export
 * @interface CreatorTopicIn
 */
export interface CreatorTopicIn {
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicIn
     */
    'api_url': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicIn
     */
    'topic_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicIn
     */
    'creator_id'?: string;
}
/**
 * 
 * @export
 * @interface CreatorTopicOut
 */
export interface CreatorTopicOut {
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicOut
     */
    'id': string;
    /**
     * 
     * @type {TopicOut}
     * @memberof CreatorTopicOut
     */
    'topic': TopicOut;
}
/**
 * 
 * @export
 * @interface CreatorTopicOutWithApiURL
 */
export interface CreatorTopicOutWithApiURL {
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicOutWithApiURL
     */
    'api_url': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicOutWithApiURL
     */
    'id': string;
    /**
     * 
     * @type {TopicOut}
     * @memberof CreatorTopicOutWithApiURL
     */
    'topic': TopicOut;
}
/**
 * 
 * @export
 * @interface CreatorTopicUpdate
 */
export interface CreatorTopicUpdate {
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicUpdate
     */
    'api_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorTopicUpdate
     */
    'topic_id'?: string;
}
/**
 * 
 * @export
 * @interface CreatorUpdate
 */
export interface CreatorUpdate {
    /**
     * 
     * @type {string}
     * @memberof CreatorUpdate
     */
    'website_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorUpdate
     */
    'server_base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorUpdate
     */
    'server_username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorUpdate
     */
    'server_password'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DeviceType = {
    Ios: 'IOS',
    Android: 'ANDROID'
} as const;

export type DeviceType = typeof DeviceType[keyof typeof DeviceType];


/**
 * 
 * @export
 * @interface FeatureCreate
 */
export interface FeatureCreate {
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'version_number': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'date_released'?: string;
}
/**
 * 
 * @export
 * @interface FeatureOut
 */
export interface FeatureOut {
    /**
     * 
     * @type {string}
     * @memberof FeatureOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureOut
     */
    'title': string;
    /**
     * 
     * @type {VersionOut}
     * @memberof FeatureOut
     */
    'feature_version'?: VersionOut;
    /**
     * 
     * @type {string}
     * @memberof FeatureOut
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureOut
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureOut
     */
    'date_released'?: string;
}
/**
 * 
 * @export
 * @interface FeatureUpdate
 */
export interface FeatureUpdate {
    /**
     * 
     * @type {string}
     * @memberof FeatureUpdate
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureUpdate
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureUpdate
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureUpdate
     */
    'date_released'?: string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ImageFileVersion = {
    Thumbnail: 'THUMBNAIL',
    Featured: 'FEATURED'
} as const;

export type ImageFileVersion = typeof ImageFileVersion[keyof typeof ImageFileVersion];


/**
 * 
 * @export
 * @interface ManyAccountDeletionFeedback
 */
export interface ManyAccountDeletionFeedback {
    /**
     * 
     * @type {number}
     * @memberof ManyAccountDeletionFeedback
     */
    'total': number;
    /**
     * 
     * @type {Array<AccountDeletionFeedbackOut>}
     * @memberof ManyAccountDeletionFeedback
     */
    'feedbacks': Array<AccountDeletionFeedbackOut>;
}
/**
 * 
 * @export
 * @interface ManyCommentOut
 */
export interface ManyCommentOut {
    /**
     * 
     * @type {number}
     * @memberof ManyCommentOut
     */
    'total': number;
    /**
     * 
     * @type {Array<CommentOut>}
     * @memberof ManyCommentOut
     */
    'comments': Array<CommentOut>;
}
/**
 * 
 * @export
 * @interface ManyCreatorsOut
 */
export interface ManyCreatorsOut {
    /**
     * 
     * @type {number}
     * @memberof ManyCreatorsOut
     */
    'total': number;
    /**
     * 
     * @type {Array<CreatorOut>}
     * @memberof ManyCreatorsOut
     */
    'creators': Array<CreatorOut>;
}
/**
 * 
 * @export
 * @interface ManyFeaturesOut
 */
export interface ManyFeaturesOut {
    /**
     * 
     * @type {number}
     * @memberof ManyFeaturesOut
     */
    'total': number;
    /**
     * 
     * @type {Array<FeatureOut>}
     * @memberof ManyFeaturesOut
     */
    'features': Array<FeatureOut>;
}
/**
 * 
 * @export
 * @interface ManyMiniStoryOut
 */
export interface ManyMiniStoryOut {
    /**
     * 
     * @type {number}
     * @memberof ManyMiniStoryOut
     */
    'total': number;
    /**
     * 
     * @type {Array<MiniStoryOut>}
     * @memberof ManyMiniStoryOut
     */
    'stories': Array<MiniStoryOut>;
}
/**
 * 
 * @export
 * @interface ManyNotificationsOut
 */
export interface ManyNotificationsOut {
    /**
     * 
     * @type {number}
     * @memberof ManyNotificationsOut
     */
    'total': number;
    /**
     * 
     * @type {Array<NotificationOut>}
     * @memberof ManyNotificationsOut
     */
    'notifications': Array<NotificationOut>;
}
/**
 * 
 * @export
 * @interface ManyReaderCreatorOut
 */
export interface ManyReaderCreatorOut {
    /**
     * 
     * @type {number}
     * @memberof ManyReaderCreatorOut
     */
    'total': number;
    /**
     * 
     * @type {Array<ReaderCreatorOut>}
     * @memberof ManyReaderCreatorOut
     */
    'data': Array<ReaderCreatorOut>;
}
/**
 * 
 * @export
 * @interface ManyReaderStreaksOut
 */
export interface ManyReaderStreaksOut {
    /**
     * 
     * @type {number}
     * @memberof ManyReaderStreaksOut
     */
    'total': number;
    /**
     * 
     * @type {Array<ReaderStreakOut>}
     * @memberof ManyReaderStreaksOut
     */
    'streaks': Array<ReaderStreakOut>;
}
/**
 * 
 * @export
 * @interface ManyReadersOut
 */
export interface ManyReadersOut {
    /**
     * 
     * @type {number}
     * @memberof ManyReadersOut
     */
    'total': number;
    /**
     * 
     * @type {Array<ReaderOut>}
     * @memberof ManyReadersOut
     */
    'readers': Array<ReaderOut>;
}
/**
 * 
 * @export
 * @interface ManyReferredReadersOut
 */
export interface ManyReferredReadersOut {
    /**
     * 
     * @type {number}
     * @memberof ManyReferredReadersOut
     */
    'total': number;
    /**
     * 
     * @type {Array<ReferredReadersOut>}
     * @memberof ManyReferredReadersOut
     */
    'referred_readers': Array<ReferredReadersOut>;
}
/**
 * 
 * @export
 * @interface ManyReportsOut
 */
export interface ManyReportsOut {
    /**
     * 
     * @type {number}
     * @memberof ManyReportsOut
     */
    'total': number;
    /**
     * 
     * @type {Array<ReportOut>}
     * @memberof ManyReportsOut
     */
    'reports': Array<ReportOut>;
}
/**
 * 
 * @export
 * @interface ManyRolesOut
 */
export interface ManyRolesOut {
    /**
     * 
     * @type {Array<RoleOut>}
     * @memberof ManyRolesOut
     */
    'roles': Array<RoleOut>;
    /**
     * 
     * @type {number}
     * @memberof ManyRolesOut
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ManySharedStoryLinkViewOut
 */
export interface ManySharedStoryLinkViewOut {
    /**
     * 
     * @type {number}
     * @memberof ManySharedStoryLinkViewOut
     */
    'total': number;
    /**
     * 
     * @type {Array<SharedStoryLinkViewOut>}
     * @memberof ManySharedStoryLinkViewOut
     */
    'shared_story_link_views': Array<SharedStoryLinkViewOut>;
}
/**
 * 
 * @export
 * @interface ManyStoriesOut
 */
export interface ManyStoriesOut {
    /**
     * 
     * @type {number}
     * @memberof ManyStoriesOut
     */
    'total': number;
    /**
     * 
     * @type {Array<StoryOut>}
     * @memberof ManyStoriesOut
     */
    'stories': Array<StoryOut>;
}
/**
 * 
 * @export
 * @interface ManySystemScopeOut
 */
export interface ManySystemScopeOut {
    /**
     * 
     * @type {Array<SystemScopeOut>}
     * @memberof ManySystemScopeOut
     */
    'scopes': Array<SystemScopeOut>;
}
/**
 * 
 * @export
 * @interface ManyTopicsOut
 */
export interface ManyTopicsOut {
    /**
     * 
     * @type {number}
     * @memberof ManyTopicsOut
     */
    'total': number;
    /**
     * 
     * @type {Array<TopicOut>}
     * @memberof ManyTopicsOut
     */
    'topics': Array<TopicOut>;
}
/**
 * 
 * @export
 * @interface ManyTopicsStatistics
 */
export interface ManyTopicsStatistics {
    /**
     * 
     * @type {Array<TopicStatistics>}
     * @memberof ManyTopicsStatistics
     */
    'topics': Array<TopicStatistics>;
}
/**
 * 
 * @export
 * @interface ManyUserPushNotificationToken
 */
export interface ManyUserPushNotificationToken {
    /**
     * 
     * @type {number}
     * @memberof ManyUserPushNotificationToken
     */
    'total': number;
    /**
     * 
     * @type {Array<UserPushNotificationTokenOut>}
     * @memberof ManyUserPushNotificationToken
     */
    'tokens': Array<UserPushNotificationTokenOut>;
}
/**
 * 
 * @export
 * @interface ManyUserRolesOut
 */
export interface ManyUserRolesOut {
    /**
     * 
     * @type {number}
     * @memberof ManyUserRolesOut
     */
    'total': number;
    /**
     * 
     * @type {Array<UserRoleOut>}
     * @memberof ManyUserRolesOut
     */
    'user_roles': Array<UserRoleOut>;
}
/**
 * 
 * @export
 * @interface ManyUsersInDB
 */
export interface ManyUsersInDB {
    /**
     * 
     * @type {number}
     * @memberof ManyUsersInDB
     */
    'total'?: number;
    /**
     * 
     * @type {Array<UserOut>}
     * @memberof ManyUsersInDB
     */
    'data': Array<UserOut>;
}
/**
 * 
 * @export
 * @interface ManyVersionOut
 */
export interface ManyVersionOut {
    /**
     * 
     * @type {number}
     * @memberof ManyVersionOut
     */
    'total': number;
    /**
     * 
     * @type {Array<VersionOut>}
     * @memberof ManyVersionOut
     */
    'versions': Array<VersionOut>;
}
/**
 * 
 * @export
 * @interface MigrateGuestUser
 */
export interface MigrateGuestUser {
    /**
     * 
     * @type {string}
     * @memberof MigrateGuestUser
     */
    'new_email': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateGuestUser
     */
    'new_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateGuestUser
     */
    'new_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateGuestUser
     */
    'chosenPassword': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateGuestUser
     */
    'chosenPassword2': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateGuestUser
     */
    'referral_token'?: string;
}
/**
 * 
 * @export
 * @interface MiniCreatorOut
 */
export interface MiniCreatorOut {
    /**
     * 
     * @type {string}
     * @memberof MiniCreatorOut
     */
    'id': string;
    /**
     * 
     * @type {SrcUsersSchemasMiniUserOut}
     * @memberof MiniCreatorOut
     */
    'user': SrcUsersSchemasMiniUserOut;
}
/**
 * 
 * @export
 * @interface MiniFileObjectOut
 */
export interface MiniFileObjectOut {
    /**
     * 
     * @type {string}
     * @memberof MiniFileObjectOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MiniFileObjectOut
     */
    'original_file_name': string;
}
/**
 * 
 * @export
 * @interface MiniProfileOut
 */
export interface MiniProfileOut {
    /**
     * 
     * @type {string}
     * @memberof MiniProfileOut
     */
    'last_name_raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof MiniProfileOut
     */
    'first_name_raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof MiniProfileOut
     */
    'avatar_url': string;
    /**
     * 
     * @type {MiniFileObjectOut}
     * @memberof MiniProfileOut
     */
    'photo_file'?: MiniFileObjectOut;
}
/**
 * 
 * @export
 * @interface MiniRoleOut
 */
export interface MiniRoleOut {
    /**
     * 
     * @type {string}
     * @memberof MiniRoleOut
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MiniRoleOut
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface MiniStoryCreatorOut
 */
export interface MiniStoryCreatorOut {
    /**
     * 
     * @type {UserOutForCreator}
     * @memberof MiniStoryCreatorOut
     */
    'user': UserOutForCreator;
}
/**
 * 
 * @export
 * @interface MiniStoryOut
 */
export interface MiniStoryOut {
    /**
     * 
     * @type {string}
     * @memberof MiniStoryOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MiniStoryOut
     */
    'title_raw': string;
    /**
     * 
     * @type {string}
     * @memberof MiniStoryOut
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof MiniStoryOut
     */
    'published': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MiniStoryOut
     */
    'featured'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MiniStoryOut
     */
    'slug': string;
    /**
     * 
     * @type {MiniStoryCreatorOut}
     * @memberof MiniStoryOut
     */
    'creator': MiniStoryCreatorOut;
    /**
     * 
     * @type {string}
     * @memberof MiniStoryOut
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof MiniStoryOut
     */
    'content_html': string;
    /**
     * 
     * @type {string}
     * @memberof MiniStoryOut
     */
    'content_plain': string;
}
/**
 * 
 * @export
 * @interface MiniUserRoleOut
 */
export interface MiniUserRoleOut {
    /**
     * 
     * @type {MiniRoleOut}
     * @memberof MiniUserRoleOut
     */
    'role': MiniRoleOut;
}
/**
 * 
 * @export
 * @interface NotificationOut
 */
export interface NotificationOut {
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    'story_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationOut
     */
    'date_created': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const OrderBy = {
    Created: 'DATE_CREATED',
    Modified: 'DATE_MODIFIED'
} as const;

export type OrderBy = typeof OrderBy[keyof typeof OrderBy];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const OrderDirection = {
    Desc: 'DESC',
    Asc: 'ASC'
} as const;

export type OrderDirection = typeof OrderDirection[keyof typeof OrderDirection];


/**
 * 
 * @export
 * @interface ProfileOut
 */
export interface ProfileOut {
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'last_name_raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'first_name_raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'avatar_url': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'country_of_residence'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'instagram_handle'?: string;
    /**
     * 
     * @type {MiniFileObjectOut}
     * @memberof ProfileOut
     */
    'photo_file'?: MiniFileObjectOut;
    /**
     * 
     * @type {string}
     * @memberof ProfileOut
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface ReaderCreatorOut
 */
export interface ReaderCreatorOut {
    /**
     * 
     * @type {string}
     * @memberof ReaderCreatorOut
     */
    'reader_id': string;
    /**
     * 
     * @type {CreatorOut}
     * @memberof ReaderCreatorOut
     */
    'creator': CreatorOut;
}
/**
 * 
 * @export
 * @interface ReaderOut
 */
export interface ReaderOut {
    /**
     * 
     * @type {string}
     * @memberof ReaderOut
     */
    'id': string;
    /**
     * 
     * @type {UserOut}
     * @memberof ReaderOut
     */
    'user'?: UserOut;
    /**
     * 
     * @type {string}
     * @memberof ReaderOut
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReaderOut
     */
    'nickname'?: string;
    /**
     * 
     * @type {Array<ReaderTopics>}
     * @memberof ReaderOut
     */
    'reader_topics': Array<ReaderTopics>;
    /**
     * 
     * @type {string}
     * @memberof ReaderOut
     */
    'referral_token'?: string;
}
/**
 * 
 * @export
 * @interface ReaderStreakOut
 */
export interface ReaderStreakOut {
    /**
     * 
     * @type {string}
     * @memberof ReaderStreakOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReaderStreakOut
     */
    'reader_id': string;
    /**
     * 
     * @type {number}
     * @memberof ReaderStreakOut
     */
    'streaks_earned': number;
    /**
     * 
     * @type {string}
     * @memberof ReaderStreakOut
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ReaderStreakOut
     */
    'last_date_increased'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReaderStreakOut
     */
    'last_date_updated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReaderStreakOut
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface ReaderTopics
 */
export interface ReaderTopics {
    /**
     * 
     * @type {TopicOut}
     * @memberof ReaderTopics
     */
    'topic': TopicOut;
}
/**
 * 
 * @export
 * @interface ReaderUpdate
 */
export interface ReaderUpdate {
    /**
     * 
     * @type {string}
     * @memberof ReaderUpdate
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReaderUpdate
     */
    'nickname'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReaderUpdate
     */
    'topic_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReferredReadersOut
 */
export interface ReferredReadersOut {
    /**
     * 
     * @type {string}
     * @memberof ReferredReadersOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReferredReadersOut
     */
    'reader_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReferredReadersOut
     */
    'referral_code_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReferredReadersOut
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface RegistrationTokens
 */
export interface RegistrationTokens {
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationTokens
     */
    'tokens': Array<string>;
}
/**
 * 
 * @export
 * @interface ReportIn
 */
export interface ReportIn {
    /**
     * 
     * @type {string}
     * @memberof ReportIn
     */
    'main_reason': string;
    /**
     * 
     * @type {string}
     * @memberof ReportIn
     */
    'reader_comment'?: string;
}
/**
 * 
 * @export
 * @interface ReportOut
 */
export interface ReportOut {
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'reader_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'story_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'main_reason': string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'modified_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'admin_comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'date_created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOut
     */
    'date_modified'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ReportStatus = {
    Addressed: 'ADDRESSED',
    Ignored: 'IGNORED',
    Pending: 'PENDING'
} as const;

export type ReportStatus = typeof ReportStatus[keyof typeof ReportStatus];


/**
 * 
 * @export
 * @interface ReportUpdate
 */
export interface ReportUpdate {
    /**
     * 
     * @type {ReportStatus}
     * @memberof ReportUpdate
     */
    'status': ReportStatus;
    /**
     * 
     * @type {string}
     * @memberof ReportUpdate
     */
    'admin_comment'?: string;
}


/**
 * 
 * @export
 * @interface RoleCreate
 */
export interface RoleCreate {
    /**
     * 
     * @type {string}
     * @memberof RoleCreate
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleCreate
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface RoleOut
 */
export interface RoleOut {
    /**
     * 
     * @type {string}
     * @memberof RoleOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleOut
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleOut
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RoleOut
     */
    'can_be_deleted': boolean;
    /**
     * 
     * @type {UserOut}
     * @memberof RoleOut
     */
    'created_by_user': UserOut;
    /**
     * 
     * @type {UserOut}
     * @memberof RoleOut
     */
    'modified_by_user'?: UserOut;
    /**
     * 
     * @type {string}
     * @memberof RoleOut
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof RoleOut
     */
    'date_modified'?: string;
}
/**
 * 
 * @export
 * @interface SharedStoryLinkViewOut
 */
export interface SharedStoryLinkViewOut {
    /**
     * 
     * @type {string}
     * @memberof SharedStoryLinkViewOut
     */
    'main_reader_id': string;
    /**
     * 
     * @type {string}
     * @memberof SharedStoryLinkViewOut
     */
    'viewed_reader_id': string;
    /**
     * 
     * @type {string}
     * @memberof SharedStoryLinkViewOut
     */
    'story_id': string;
}
/**
 * 
 * @export
 * @interface SharedStoryLinkViewSummaryOut
 */
export interface SharedStoryLinkViewSummaryOut {
    /**
     * 
     * @type {string}
     * @memberof SharedStoryLinkViewSummaryOut
     */
    'reader_id': string;
    /**
     * 
     * @type {string}
     * @memberof SharedStoryLinkViewSummaryOut
     */
    'story_id': string;
    /**
     * 
     * @type {number}
     * @memberof SharedStoryLinkViewSummaryOut
     */
    'total_number_of_views': number;
    /**
     * 
     * @type {string}
     * @memberof SharedStoryLinkViewSummaryOut
     */
    'last_date_updated': string;
    /**
     * 
     * @type {string}
     * @memberof SharedStoryLinkViewSummaryOut
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface SrcSchemasMiniUserOut
 */
export interface SrcSchemasMiniUserOut {
    /**
     * 
     * @type {string}
     * @memberof SrcSchemasMiniUserOut
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof SrcSchemasMiniUserOut
     */
    'is_active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SrcSchemasMiniUserOut
     */
    'is_super_admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SrcSchemasMiniUserOut
     */
    'is_anonymous': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SrcSchemasMiniUserOut
     */
    'is_reader': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SrcSchemasMiniUserOut
     */
    'is_creator': boolean;
}
/**
 * 
 * @export
 * @interface SrcUsersSchemasMiniUserOut
 */
export interface SrcUsersSchemasMiniUserOut {
    /**
     * 
     * @type {string}
     * @memberof SrcUsersSchemasMiniUserOut
     */
    'id': string;
    /**
     * 
     * @type {ProfileOut}
     * @memberof SrcUsersSchemasMiniUserOut
     */
    'profile': ProfileOut;
}
/**
 * 
 * @export
 * @interface StoriesTopic
 */
export interface StoriesTopic {
    /**
     * 
     * @type {TopicOut}
     * @memberof StoriesTopic
     */
    'topic': TopicOut;
}
/**
 * 
 * @export
 * @interface StoryLikedOut
 */
export interface StoryLikedOut {
    /**
     * 
     * @type {string}
     * @memberof StoryLikedOut
     */
    'story_id': string;
    /**
     * 
     * @type {string}
     * @memberof StoryLikedOut
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface StoryOut
 */
export interface StoryOut {
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'content_html': string;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'url': string;
    /**
     * 
     * @type {boolean}
     * @memberof StoryOut
     */
    'comments_disabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoryOut
     */
    'published': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoryOut
     */
    'featured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoryOut
     */
    'alert_readers'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'host_server_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'title_raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'content_plain': string;
    /**
     * 
     * @type {MiniFileObjectOut}
     * @memberof StoryOut
     */
    'image_file'?: MiniFileObjectOut;
    /**
     * 
     * @type {MiniFileObjectOut}
     * @memberof StoryOut
     */
    'thumbnail_file'?: MiniFileObjectOut;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'creator_id': string;
    /**
     * 
     * @type {MiniCreatorOut}
     * @memberof StoryOut
     */
    'creator': MiniCreatorOut;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof StoryOut
     */
    'minute_to_read': number;
    /**
     * 
     * @type {number}
     * @memberof StoryOut
     */
    'total_views'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoryOut
     */
    'total_likes'?: number;
    /**
     * 
     * @type {number}
     * @memberof StoryOut
     */
    'total_shares'?: number;
    /**
     * 
     * @type {object}
     * @memberof StoryOut
     */
    'attributes'?: object;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof StoryOut
     */
    'date_modified'?: string;
    /**
     * 
     * @type {Array<StoriesTopic>}
     * @memberof StoryOut
     */
    'stories_topics': Array<StoriesTopic>;
    /**
     * 
     * @type {SrcSchemasMiniUserOut}
     * @memberof StoryOut
     */
    'created_by_user'?: SrcSchemasMiniUserOut;
    /**
     * 
     * @type {SrcSchemasMiniUserOut}
     * @memberof StoryOut
     */
    'modified_by_user'?: SrcSchemasMiniUserOut;
    /**
     * 
     * @type {boolean}
     * @memberof StoryOut
     */
    'soft_deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface StorySharedOut
 */
export interface StorySharedOut {
    /**
     * 
     * @type {string}
     * @memberof StorySharedOut
     */
    'story_id': string;
    /**
     * 
     * @type {string}
     * @memberof StorySharedOut
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface StoryViewedOut
 */
export interface StoryViewedOut {
    /**
     * 
     * @type {string}
     * @memberof StoryViewedOut
     */
    'story_id': string;
    /**
     * 
     * @type {string}
     * @memberof StoryViewedOut
     */
    'date_created': string;
}
/**
 * 
 * @export
 * @interface SystemScopeOut
 */
export interface SystemScopeOut {
    /**
     * 
     * @type {string}
     * @memberof SystemScopeOut
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SystemScopeOut
     */
    'scopes': Array<string>;
}
/**
 * 
 * @export
 * @interface TopicIn
 */
export interface TopicIn {
    /**
     * 
     * @type {string}
     * @memberof TopicIn
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TopicIn
     */
    'emoji_code': string;
    /**
     * 
     * @type {string}
     * @memberof TopicIn
     */
    'ref_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicIn
     */
    'required_for_readers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicIn
     */
    'visible_to_readers'?: boolean;
}
/**
 * 
 * @export
 * @interface TopicOut
 */
export interface TopicOut {
    /**
     * 
     * @type {string}
     * @memberof TopicOut
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TopicOut
     */
    'emoji_code': string;
    /**
     * 
     * @type {string}
     * @memberof TopicOut
     */
    'ref_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicOut
     */
    'required_for_readers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicOut
     */
    'visible_to_readers'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TopicOut
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicOut
     */
    'is_active': boolean;
    /**
     * 
     * @type {TopicUserOut}
     * @memberof TopicOut
     */
    'created_by_user'?: TopicUserOut;
    /**
     * 
     * @type {TopicUserOut}
     * @memberof TopicOut
     */
    'modified_by_user'?: TopicUserOut;
    /**
     * 
     * @type {string}
     * @memberof TopicOut
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof TopicOut
     */
    'date_modified'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicOut
     */
    'image_file_id'?: string;
    /**
     * 
     * @type {MiniFileObjectOut}
     * @memberof TopicOut
     */
    'image_file'?: MiniFileObjectOut;
}
/**
 * 
 * @export
 * @interface TopicStatistics
 */
export interface TopicStatistics {
    /**
     * 
     * @type {string}
     * @memberof TopicStatistics
     */
    'topic_id': string;
    /**
     * 
     * @type {number}
     * @memberof TopicStatistics
     */
    'readers': number;
    /**
     * 
     * @type {number}
     * @memberof TopicStatistics
     */
    'creators': number;
}
/**
 * 
 * @export
 * @interface TopicUpdate
 */
export interface TopicUpdate {
    /**
     * 
     * @type {string}
     * @memberof TopicUpdate
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicUpdate
     */
    'emoji_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicUpdate
     */
    'make_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TopicUpdate
     */
    'ref_code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicUpdate
     */
    'required_for_readers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicUpdate
     */
    'visible_to_readers'?: boolean;
}
/**
 * 
 * @export
 * @interface TopicUserOut
 */
export interface TopicUserOut {
    /**
     * 
     * @type {string}
     * @memberof TopicUserOut
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicUserOut
     */
    'is_active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicUserOut
     */
    'is_super_admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicUserOut
     */
    'is_reader': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicUserOut
     */
    'is_creator': boolean;
}
/**
 * 
 * @export
 * @interface TopicViewsComparisonOut
 */
export interface TopicViewsComparisonOut {
    /**
     * 
     * @type {number}
     * @memberof TopicViewsComparisonOut
     */
    'current_period_views': number;
    /**
     * 
     * @type {number}
     * @memberof TopicViewsComparisonOut
     */
    'prev_period_views': number;
    /**
     * 
     * @type {number}
     * @memberof TopicViewsComparisonOut
     */
    'percentage_difference': number;
}
/**
 * 
 * @export
 * @interface TotalCreatorFollowers
 */
export interface TotalCreatorFollowers {
    /**
     * 
     * @type {number}
     * @memberof TotalCreatorFollowers
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'access_begin'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'access_end'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'is_super_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'is_anonymous'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'is_reader'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'is_creator'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserDatetimeOut
 */
export interface UserDatetimeOut {
    /**
     * 
     * @type {string}
     * @memberof UserDatetimeOut
     */
    'datetime': string;
}
/**
 * 
 * @export
 * @interface UserOut
 */
export interface UserOut {
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    'access_begin'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    'access_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserOut
     */
    'is_active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOut
     */
    'is_super_admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOut
     */
    'is_reader': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOut
     */
    'is_creator': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOut
     */
    'is_anonymous': boolean;
    /**
     * 
     * @type {Array<MiniUserRoleOut>}
     * @memberof UserOut
     */
    'user_roles'?: Array<MiniUserRoleOut>;
    /**
     * 
     * @type {ProfileOut}
     * @memberof UserOut
     */
    'profile': ProfileOut;
    /**
     * 
     * @type {string}
     * @memberof UserOut
     */
    'last_login'?: string;
}
/**
 * 
 * @export
 * @interface UserOutForCreator
 */
export interface UserOutForCreator {
    /**
     * 
     * @type {string}
     * @memberof UserOutForCreator
     */
    'id': string;
    /**
     * 
     * @type {MiniProfileOut}
     * @memberof UserOutForCreator
     */
    'profile': MiniProfileOut;
}
/**
 * 
 * @export
 * @interface UserPushNotificationTokenOut
 */
export interface UserPushNotificationTokenOut {
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationTokenOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationTokenOut
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationTokenOut
     */
    'user_id': string;
    /**
     * 
     * @type {UserOut}
     * @memberof UserPushNotificationTokenOut
     */
    'user': UserOut;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationTokenOut
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationTokenOut
     */
    'date_updated'?: string;
}
/**
 * 
 * @export
 * @interface UserRoleOut
 */
export interface UserRoleOut {
    /**
     * 
     * @type {RoleOut}
     * @memberof UserRoleOut
     */
    'role': RoleOut;
}
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    'is_super_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    'is_reader'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdate
     */
    'is_creator'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'country_of_residence'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'instagram_handle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'last_login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'access_begin'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'access_end'?: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface VersionOut
 */
export interface VersionOut {
    /**
     * 
     * @type {string}
     * @memberof VersionOut
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VersionOut
     */
    'version_number': string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Expecting code, givenName and familyName.
         * @summary Authorize Apple Mobile
         * @param {string} authorizationCode 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [useBundleId] 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeAppleMobile: async (authorizationCode: string, firstName?: string, lastName?: string, useBundleId?: boolean, referralToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizationCode' is not null or undefined
            assertParamExists('authorizeAppleMobile', 'authorizationCode', authorizationCode)
            const localVarPath = `/authorize-apple-mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorizationCode !== undefined) {
                localVarQueryParameter['authorization_code'] = authorizationCode;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }

            if (useBundleId !== undefined) {
                localVarQueryParameter['use_bundle_id'] = useBundleId;
            }

            if (referralToken !== undefined) {
                localVarQueryParameter['referral_token'] = referralToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Parses and authorize a user\'s token retrieved from Google.
         * @summary Authorize Google Mobile
         * @param {string} token 
         * @param {DeviceType} deviceType 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeGoogleMobile: async (token: string, deviceType: DeviceType, referralToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('authorizeGoogleMobile', 'token', token)
            // verify required parameter 'deviceType' is not null or undefined
            assertParamExists('authorizeGoogleMobile', 'deviceType', deviceType)
            const localVarPath = `/authorize-google-mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['device_type'] = deviceType;
            }

            if (referralToken !== undefined) {
                localVarQueryParameter['referral_token'] = referralToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authenticates with the given credentials. Only users that were created with email and password can call this endpoint. Oauth registered users need to call the correct oauth provider endpoint.  **Note**, passwords are case sensitive.
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('login', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('login', 'password', password)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Expecting code, givenName and familyName.
         * @summary Authorize Apple Mobile
         * @param {string} authorizationCode 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [useBundleId] 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeAppleMobile(authorizationCode: string, firstName?: string, lastName?: string, useBundleId?: boolean, referralToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeAppleMobile(authorizationCode, firstName, lastName, useBundleId, referralToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Parses and authorize a user\'s token retrieved from Google.
         * @summary Authorize Google Mobile
         * @param {string} token 
         * @param {DeviceType} deviceType 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeGoogleMobile(token: string, deviceType: DeviceType, referralToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeGoogleMobile(token, deviceType, referralToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authenticates with the given credentials. Only users that were created with email and password can call this endpoint. Oauth registered users need to call the correct oauth provider endpoint.  **Note**, passwords are case sensitive.
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Expecting code, givenName and familyName.
         * @summary Authorize Apple Mobile
         * @param {string} authorizationCode 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [useBundleId] 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeAppleMobile(authorizationCode: string, firstName?: string, lastName?: string, useBundleId?: boolean, referralToken?: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.authorizeAppleMobile(authorizationCode, firstName, lastName, useBundleId, referralToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Parses and authorize a user\'s token retrieved from Google.
         * @summary Authorize Google Mobile
         * @param {string} token 
         * @param {DeviceType} deviceType 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeGoogleMobile(token: string, deviceType: DeviceType, referralToken?: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.authorizeGoogleMobile(token, deviceType, referralToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Authenticates with the given credentials. Only users that were created with email and password can call this endpoint. Oauth registered users need to call the correct oauth provider endpoint.  **Note**, passwords are case sensitive.
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.login(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Expecting code, givenName and familyName.
     * @summary Authorize Apple Mobile
     * @param {string} authorizationCode 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {boolean} [useBundleId] 
     * @param {string} [referralToken] Create reader with this referral token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeAppleMobile(authorizationCode: string, firstName?: string, lastName?: string, useBundleId?: boolean, referralToken?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authorizeAppleMobile(authorizationCode, firstName, lastName, useBundleId, referralToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Parses and authorize a user\'s token retrieved from Google.
     * @summary Authorize Google Mobile
     * @param {string} token 
     * @param {DeviceType} deviceType 
     * @param {string} [referralToken] Create reader with this referral token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeGoogleMobile(token: string, deviceType: DeviceType, referralToken?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authorizeGoogleMobile(token, deviceType, referralToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authenticates with the given credentials. Only users that were created with email and password can call this endpoint. Oauth registered users need to call the correct oauth provider endpoint.  **Note**, passwords are case sensitive.
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CreatorsApi - axios parameter creator
 * @export
 */
export const CreatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a topic to the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Add Creator Topic
         * @param {CreatorTopicIn} creatorTopicIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCreatorTopic: async (creatorTopicIn: CreatorTopicIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorTopicIn' is not null or undefined
            assertParamExists('addCreatorTopic', 'creatorTopicIn', creatorTopicIn)
            const localVarPath = `/creators/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creatorTopicIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Add Follower
         * @param {string} creatorId 
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFollower: async (creatorId: string, readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('addFollower', 'creatorId', creatorId)
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('addFollower', 'readerId', readerId)
            const localVarPath = `/creators/{creator_id}/add-follower/{reader_id}`
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)))
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the creator off the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:delete, me,  
         * @summary Delete Creator
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreator: async (creatorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('deleteCreator', 'creatorId', creatorId)
            const localVarPath = `/creators/{creator_id}`
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreator: async (creatorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('getCreator', 'creatorId', creatorId)
            const localVarPath = `/creators/{creator_id}`
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator By User Id
         * @param {string} creatorUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorByUserId: async (creatorUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorUserId' is not null or undefined
            assertParamExists('getCreatorByUserId', 'creatorUserId', creatorUserId)
            const localVarPath = `/creators/users/{creator_user_id}`
                .replace(`{${"creator_user_id"}}`, encodeURIComponent(String(creatorUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator By User Id With Api Url
         * @param {string} creatorUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorByUserIdWithApiUrl: async (creatorUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorUserId' is not null or undefined
            assertParamExists('getCreatorByUserIdWithApiUrl', 'creatorUserId', creatorUserId)
            const localVarPath = `/creators/users/{creator_user_id}/with-api-url`
                .replace(`{${"creator_user_id"}}`, encodeURIComponent(String(creatorUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator Statistics
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorStatistics: async (creatorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('getCreatorStatistics', 'creatorId', creatorId)
            const localVarPath = `/creators/{creator_id}/statistics`
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator With Api Url
         * @param {string} creatorId 
         * @param {string} [adminSignupToken] The correct admin token to use admin only features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorWithApiUrl: async (creatorId: string, adminSignupToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('getCreatorWithApiUrl', 'creatorId', creatorId)
            const localVarPath = `/creators/{creator_id}/with-api-url`
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (adminSignupToken != null) {
                localVarHeaderParameter['admin-signup-token'] = String(adminSignupToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of creators in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:read, me,  
         * @summary Get Creators
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [title] Searches and return creators with the title.
         * @param {boolean} [activeCreators] By default, regular user can list creators that are active, inactive creators can only be listed by the super admin.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreators: async (orderDirection: OrderDirection, skip?: number, limit?: number, title?: string, activeCreators?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getCreators', 'orderDirection', orderDirection)
            const localVarPath = `/creators/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (activeCreators !== undefined) {
                localVarQueryParameter['active_creators'] = activeCreators;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Followed Creators
         * @param {string} readerId 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCreators: async (readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getFollowedCreators', 'readerId', readerId)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getFollowedCreators', 'orderDirection', orderDirection)
            const localVarPath = `/creators/{reader_id}/followed`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a topic from the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Remove Creator Topic
         * @param {string} creatorTopicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreatorTopic: async (creatorTopicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorTopicId' is not null or undefined
            assertParamExists('removeCreatorTopic', 'creatorTopicId', creatorTopicId)
            const localVarPath = `/creators/topics/{creator_topic_id}`
                .replace(`{${"creator_topic_id"}}`, encodeURIComponent(String(creatorTopicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Remove Follower
         * @param {string} readerId 
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFollower: async (readerId: string, creatorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('removeFollower', 'readerId', readerId)
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('removeFollower', 'creatorId', creatorId)
            const localVarPath = `/creators/{creator_id}/remove-follower/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)))
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Total Followers
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalFollowers: async (creatorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('totalFollowers', 'creatorId', creatorId)
            const localVarPath = `/creators/{creator_id}/total-followers`
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Creator
         * @param {string} creatorId 
         * @param {CreatorUpdate} creatorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCreator: async (creatorId: string, creatorUpdate: CreatorUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorId' is not null or undefined
            assertParamExists('updateCreator', 'creatorId', creatorId)
            // verify required parameter 'creatorUpdate' is not null or undefined
            assertParamExists('updateCreator', 'creatorUpdate', creatorUpdate)
            const localVarPath = `/creators/{creator_id}`
                .replace(`{${"creator_id"}}`, encodeURIComponent(String(creatorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creatorUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a topic in the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Creator Topic
         * @param {string} creatorTopicId 
         * @param {CreatorTopicUpdate} creatorTopicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCreatorTopic: async (creatorTopicId: string, creatorTopicUpdate: CreatorTopicUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorTopicId' is not null or undefined
            assertParamExists('updateCreatorTopic', 'creatorTopicId', creatorTopicId)
            // verify required parameter 'creatorTopicUpdate' is not null or undefined
            assertParamExists('updateCreatorTopic', 'creatorTopicUpdate', creatorTopicUpdate)
            const localVarPath = `/creators/topics/{creator_topic_id}`
                .replace(`{${"creator_topic_id"}}`, encodeURIComponent(String(creatorTopicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creatorTopicUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreatorsApi - functional programming interface
 * @export
 */
export const CreatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a topic to the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Add Creator Topic
         * @param {CreatorTopicIn} creatorTopicIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCreatorTopic(creatorTopicIn: CreatorTopicIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorTopicOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCreatorTopic(creatorTopicIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Add Follower
         * @param {string} creatorId 
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFollower(creatorId: string, readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderCreatorOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFollower(creatorId, readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the creator off the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:delete, me,  
         * @summary Delete Creator
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCreator(creatorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCreator(creatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreator(creatorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreator(creatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator By User Id
         * @param {string} creatorUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreatorByUserId(creatorUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreatorByUserId(creatorUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator By User Id With Api Url
         * @param {string} creatorUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreatorByUserIdWithApiUrl(creatorUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorOutWithApiURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreatorByUserIdWithApiUrl(creatorUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator Statistics
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreatorStatistics(creatorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreatorStatistics(creatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator With Api Url
         * @param {string} creatorId 
         * @param {string} [adminSignupToken] The correct admin token to use admin only features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreatorWithApiUrl(creatorId: string, adminSignupToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorOutWithApiURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreatorWithApiUrl(creatorId, adminSignupToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the list of creators in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:read, me,  
         * @summary Get Creators
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [title] Searches and return creators with the title.
         * @param {boolean} [activeCreators] By default, regular user can list creators that are active, inactive creators can only be listed by the super admin.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreators(orderDirection: OrderDirection, skip?: number, limit?: number, title?: string, activeCreators?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyCreatorsOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreators(orderDirection, skip, limit, title, activeCreators, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Followed Creators
         * @param {string} readerId 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowedCreators(readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyReaderCreatorOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowedCreators(readerId, orderDirection, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a topic from the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Remove Creator Topic
         * @param {string} creatorTopicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCreatorTopic(creatorTopicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCreatorTopic(creatorTopicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Remove Follower
         * @param {string} readerId 
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFollower(readerId: string, creatorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFollower(readerId, creatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Total Followers
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalFollowers(creatorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalCreatorFollowers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.totalFollowers(creatorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Creator
         * @param {string} creatorId 
         * @param {CreatorUpdate} creatorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCreator(creatorId: string, creatorUpdate: CreatorUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCreator(creatorId, creatorUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a topic in the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Creator Topic
         * @param {string} creatorTopicId 
         * @param {CreatorTopicUpdate} creatorTopicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCreatorTopic(creatorTopicId: string, creatorTopicUpdate: CreatorTopicUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorTopicOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCreatorTopic(creatorTopicId, creatorTopicUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreatorsApi - factory interface
 * @export
 */
export const CreatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreatorsApiFp(configuration)
    return {
        /**
         * Adds a topic to the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Add Creator Topic
         * @param {CreatorTopicIn} creatorTopicIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCreatorTopic(creatorTopicIn: CreatorTopicIn, options?: any): AxiosPromise<CreatorTopicOut> {
            return localVarFp.addCreatorTopic(creatorTopicIn, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Add Follower
         * @param {string} creatorId 
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFollower(creatorId: string, readerId: string, options?: any): AxiosPromise<ReaderCreatorOut> {
            return localVarFp.addFollower(creatorId, readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the creator off the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:delete, me,  
         * @summary Delete Creator
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreator(creatorId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.deleteCreator(creatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreator(creatorId: string, options?: any): AxiosPromise<CreatorOut> {
            return localVarFp.getCreator(creatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator By User Id
         * @param {string} creatorUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorByUserId(creatorUserId: string, options?: any): AxiosPromise<CreatorOut> {
            return localVarFp.getCreatorByUserId(creatorUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator By User Id With Api Url
         * @param {string} creatorUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorByUserIdWithApiUrl(creatorUserId: string, options?: any): AxiosPromise<CreatorOutWithApiURL> {
            return localVarFp.getCreatorByUserIdWithApiUrl(creatorUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator Statistics
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorStatistics(creatorId: string, options?: any): AxiosPromise<CreatorStatistics> {
            return localVarFp.getCreatorStatistics(creatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Creator With Api Url
         * @param {string} creatorId 
         * @param {string} [adminSignupToken] The correct admin token to use admin only features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorWithApiUrl(creatorId: string, adminSignupToken?: string, options?: any): AxiosPromise<CreatorOutWithApiURL> {
            return localVarFp.getCreatorWithApiUrl(creatorId, adminSignupToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of creators in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:read, me,  
         * @summary Get Creators
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [title] Searches and return creators with the title.
         * @param {boolean} [activeCreators] By default, regular user can list creators that are active, inactive creators can only be listed by the super admin.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreators(orderDirection: OrderDirection, skip?: number, limit?: number, title?: string, activeCreators?: boolean, options?: any): AxiosPromise<ManyCreatorsOut> {
            return localVarFp.getCreators(orderDirection, skip, limit, title, activeCreators, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Followed Creators
         * @param {string} readerId 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedCreators(readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, options?: any): AxiosPromise<ManyReaderCreatorOut> {
            return localVarFp.getFollowedCreators(readerId, orderDirection, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a topic from the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Remove Creator Topic
         * @param {string} creatorTopicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCreatorTopic(creatorTopicId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.removeCreatorTopic(creatorTopicId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Remove Follower
         * @param {string} readerId 
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFollower(readerId: string, creatorId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.removeFollower(readerId, creatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Total Followers
         * @param {string} creatorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalFollowers(creatorId: string, options?: any): AxiosPromise<TotalCreatorFollowers> {
            return localVarFp.totalFollowers(creatorId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Creator
         * @param {string} creatorId 
         * @param {CreatorUpdate} creatorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCreator(creatorId: string, creatorUpdate: CreatorUpdate, options?: any): AxiosPromise<CreatorOut> {
            return localVarFp.updateCreator(creatorId, creatorUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a topic in the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Creator Topic
         * @param {string} creatorTopicId 
         * @param {CreatorTopicUpdate} creatorTopicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCreatorTopic(creatorTopicId: string, creatorTopicUpdate: CreatorTopicUpdate, options?: any): AxiosPromise<CreatorTopicOut> {
            return localVarFp.updateCreatorTopic(creatorTopicId, creatorTopicUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreatorsApi - object-oriented interface
 * @export
 * @class CreatorsApi
 * @extends {BaseAPI}
 */
export class CreatorsApi extends BaseAPI {
    /**
     * Adds a topic to the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Add Creator Topic
     * @param {CreatorTopicIn} creatorTopicIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public addCreatorTopic(creatorTopicIn: CreatorTopicIn, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).addCreatorTopic(creatorTopicIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Add Follower
     * @param {string} creatorId 
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public addFollower(creatorId: string, readerId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).addFollower(creatorId, readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the creator off the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:delete, me,  
     * @summary Delete Creator
     * @param {string} creatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public deleteCreator(creatorId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).deleteCreator(creatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Creator
     * @param {string} creatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public getCreator(creatorId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).getCreator(creatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Creator By User Id
     * @param {string} creatorUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public getCreatorByUserId(creatorUserId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).getCreatorByUserId(creatorUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Creator By User Id With Api Url
     * @param {string} creatorUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public getCreatorByUserIdWithApiUrl(creatorUserId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).getCreatorByUserIdWithApiUrl(creatorUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Creator Statistics
     * @param {string} creatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public getCreatorStatistics(creatorId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).getCreatorStatistics(creatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Creator With Api Url
     * @param {string} creatorId 
     * @param {string} [adminSignupToken] The correct admin token to use admin only features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public getCreatorWithApiUrl(creatorId: string, adminSignupToken?: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).getCreatorWithApiUrl(creatorId, adminSignupToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of creators in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> creator:read, me,  
     * @summary Get Creators
     * @param {OrderDirection} orderDirection 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [title] Searches and return creators with the title.
     * @param {boolean} [activeCreators] By default, regular user can list creators that are active, inactive creators can only be listed by the super admin.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public getCreators(orderDirection: OrderDirection, skip?: number, limit?: number, title?: string, activeCreators?: boolean, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).getCreators(orderDirection, skip, limit, title, activeCreators, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Followed Creators
     * @param {string} readerId 
     * @param {OrderDirection} orderDirection 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public getFollowedCreators(readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).getFollowedCreators(readerId, orderDirection, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a topic from the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Remove Creator Topic
     * @param {string} creatorTopicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public removeCreatorTopic(creatorTopicId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).removeCreatorTopic(creatorTopicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Remove Follower
     * @param {string} readerId 
     * @param {string} creatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public removeFollower(readerId: string, creatorId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).removeFollower(readerId, creatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Total Followers
     * @param {string} creatorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public totalFollowers(creatorId: string, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).totalFollowers(creatorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Update Creator
     * @param {string} creatorId 
     * @param {CreatorUpdate} creatorUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public updateCreator(creatorId: string, creatorUpdate: CreatorUpdate, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).updateCreator(creatorId, creatorUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a topic in the list of topics in this creator\'s collection.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Update Creator Topic
     * @param {string} creatorTopicId 
     * @param {CreatorTopicUpdate} creatorTopicUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsApi
     */
    public updateCreatorTopic(creatorTopicId: string, creatorTopicUpdate: CreatorTopicUpdate, options?: AxiosRequestConfig) {
        return CreatorsApiFp(this.configuration).updateCreatorTopic(creatorTopicId, creatorTopicUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeaturesApi - axios parameter creator
 * @export
 */
export const FeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
         * @summary Create Feature
         * @param {FeatureCreate} featureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature: async (featureCreate: FeatureCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureCreate' is not null or undefined
            assertParamExists('createFeature', 'featureCreate', featureCreate)
            const localVarPath = `/features/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
         * @summary Create Version
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion: async (versionNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionNumber' is not null or undefined
            assertParamExists('createVersion', 'versionNumber', versionNumber)
            const localVarPath = `/features/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (versionNumber !== undefined) {
                localVarQueryParameter['version_number'] = versionNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Feature
         * @param {string} featureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature: async (featureId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('deleteFeature', 'featureId', featureId)
            const localVarPath = `/features/{feature_id}`
                .replace(`{${"feature_id"}}`, encodeURIComponent(String(featureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Version
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVersion: async (versionNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionNumber' is not null or undefined
            assertParamExists('deleteVersion', 'versionNumber', versionNumber)
            const localVarPath = `/features/delete-version/{version_number}`
                .replace(`{${"version_number"}}`, encodeURIComponent(String(versionNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Feature
         * @param {string} featureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeature: async (featureId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('getFeature', 'featureId', featureId)
            const localVarPath = `/features/{feature_id}`
                .replace(`{${"feature_id"}}`, encodeURIComponent(String(featureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Features
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [versionNumber] Serches all features related to this version
         * @param {string} [beforeDate] Searches for features created before this date.
         * @param {string} [afterDate] Searches for features created after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures: async (orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, versionNumber?: string, beforeDate?: string, afterDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('getFeatures', 'orderBy', orderBy)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getFeatures', 'orderDirection', orderDirection)
            const localVarPath = `/features/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (versionNumber !== undefined) {
                localVarQueryParameter['version_number'] = versionNumber;
            }

            if (beforeDate !== undefined) {
                localVarQueryParameter['before_date'] = (beforeDate as any instanceof Date) ?
                    (beforeDate as any).toISOString().substring(0,10) :
                    beforeDate;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['after_date'] = (afterDate as any instanceof Date) ?
                    (afterDate as any).toISOString().substring(0,10) :
                    afterDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get versions in the system.
         * @summary Get Versions
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [beforeDate] Searches for versions before this date.
         * @param {string} [afterDate] Searches for versions after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions: async (orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('getVersions', 'orderBy', orderBy)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getVersions', 'orderDirection', orderDirection)
            const localVarPath = `/features/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (beforeDate !== undefined) {
                localVarQueryParameter['before_date'] = (beforeDate as any instanceof Date) ?
                    (beforeDate as any).toISOString().substring(0,10) :
                    beforeDate;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['after_date'] = (afterDate as any instanceof Date) ?
                    (afterDate as any).toISOString().substring(0,10) :
                    afterDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Feature
         * @param {string} featureId 
         * @param {FeatureUpdate} featureUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (featureId: string, featureUpdate: FeatureUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('updateFeature', 'featureId', featureId)
            // verify required parameter 'featureUpdate' is not null or undefined
            assertParamExists('updateFeature', 'featureUpdate', featureUpdate)
            const localVarPath = `/features/{feature_id}`
                .replace(`{${"feature_id"}}`, encodeURIComponent(String(featureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Version
         * @param {string} versionId 
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion: async (versionId: string, versionNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('updateVersion', 'versionId', versionId)
            // verify required parameter 'versionNumber' is not null or undefined
            assertParamExists('updateVersion', 'versionNumber', versionNumber)
            const localVarPath = `/features/update-version/{version_id}`
                .replace(`{${"version_id"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (versionNumber !== undefined) {
                localVarQueryParameter['version_number'] = versionNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesApi - functional programming interface
 * @export
 */
export const FeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
         * @summary Create Feature
         * @param {FeatureCreate} featureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeature(featureCreate: FeatureCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeature(featureCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
         * @summary Create Version
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVersion(versionNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVersion(versionNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Feature
         * @param {string} featureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeature(featureId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeature(featureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Version
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVersion(versionNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVersion(versionNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Feature
         * @param {string} featureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeature(featureId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeature(featureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Features
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [versionNumber] Serches all features related to this version
         * @param {string} [beforeDate] Searches for features created before this date.
         * @param {string} [afterDate] Searches for features created after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatures(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, versionNumber?: string, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyFeaturesOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatures(orderBy, orderDirection, skip, limit, versionNumber, beforeDate, afterDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get versions in the system.
         * @summary Get Versions
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [beforeDate] Searches for versions before this date.
         * @param {string} [afterDate] Searches for versions after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersions(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyVersionOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersions(orderBy, orderDirection, skip, limit, beforeDate, afterDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Feature
         * @param {string} featureId 
         * @param {FeatureUpdate} featureUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(featureId: string, featureUpdate: FeatureUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(featureId, featureUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Version
         * @param {string} versionId 
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVersion(versionId: string, versionNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVersion(versionId, versionNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeaturesApi - factory interface
 * @export
 */
export const FeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesApiFp(configuration)
    return {
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
         * @summary Create Feature
         * @param {FeatureCreate} featureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature(featureCreate: FeatureCreate, options?: any): AxiosPromise<FeatureOut> {
            return localVarFp.createFeature(featureCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
         * @summary Create Version
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion(versionNumber: string, options?: any): AxiosPromise<VersionOut> {
            return localVarFp.createVersion(versionNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Feature
         * @param {string} featureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature(featureId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.deleteFeature(featureId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Version
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVersion(versionNumber: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.deleteVersion(versionNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Feature
         * @param {string} featureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeature(featureId: string, options?: any): AxiosPromise<FeatureOut> {
            return localVarFp.getFeature(featureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Features
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [versionNumber] Serches all features related to this version
         * @param {string} [beforeDate] Searches for features created before this date.
         * @param {string} [afterDate] Searches for features created after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, versionNumber?: string, beforeDate?: string, afterDate?: string, options?: any): AxiosPromise<ManyFeaturesOut> {
            return localVarFp.getFeatures(orderBy, orderDirection, skip, limit, versionNumber, beforeDate, afterDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get versions in the system.
         * @summary Get Versions
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [beforeDate] Searches for versions before this date.
         * @param {string} [afterDate] Searches for versions after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options?: any): AxiosPromise<ManyVersionOut> {
            return localVarFp.getVersions(orderBy, orderDirection, skip, limit, beforeDate, afterDate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Feature
         * @param {string} featureId 
         * @param {FeatureUpdate} featureUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(featureId: string, featureUpdate: FeatureUpdate, options?: any): AxiosPromise<FeatureOut> {
            return localVarFp.updateFeature(featureId, featureUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Version
         * @param {string} versionId 
         * @param {string} versionNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(versionId: string, versionNumber: string, options?: any): AxiosPromise<VersionOut> {
            return localVarFp.updateVersion(versionId, versionNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
export class FeaturesApi extends BaseAPI {
    /**
     * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
     * @summary Create Feature
     * @param {FeatureCreate} featureCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public createFeature(featureCreate: FeatureCreate, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).createFeature(featureCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> appversionhistory:create, me,  
     * @summary Create Version
     * @param {string} versionNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public createVersion(versionNumber: string, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).createVersion(versionNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Delete Feature
     * @param {string} featureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public deleteFeature(featureId: string, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).deleteFeature(featureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> appversionhistory:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Delete Version
     * @param {string} versionNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public deleteVersion(versionNumber: string, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).deleteVersion(versionNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Feature
     * @param {string} featureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeature(featureId: string, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeature(featureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Features
     * @param {OrderBy} orderBy 
     * @param {OrderDirection} orderDirection 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [versionNumber] Serches all features related to this version
     * @param {string} [beforeDate] Searches for features created before this date.
     * @param {string} [afterDate] Searches for features created after this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatures(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, versionNumber?: string, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatures(orderBy, orderDirection, skip, limit, versionNumber, beforeDate, afterDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get versions in the system.
     * @summary Get Versions
     * @param {OrderBy} orderBy 
     * @param {OrderDirection} orderDirection 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [beforeDate] Searches for versions before this date.
     * @param {string} [afterDate] Searches for versions after this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getVersions(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getVersions(orderBy, orderDirection, skip, limit, beforeDate, afterDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Update Feature
     * @param {string} featureId 
     * @param {FeatureUpdate} featureUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public updateFeature(featureId: string, featureUpdate: FeatureUpdate, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).updateFeature(featureId, featureUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> appversionhistory:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Update Version
     * @param {string} versionId 
     * @param {string} versionNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public updateVersion(versionId: string, versionNumber: string, options?: AxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).updateVersion(versionId, versionNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allows a user to create a notification in the system.  Request Payload ` {     notification_body:  \"Notification 1\",     notification_title: \"Notification Title\",     story_id: Optional[UUID] = None     receiver_ids: [ ID, ID ]     notification_link: \"\" (optional) } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> notification:create, me,  
         * @summary Create Notification
         * @param {string} data 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (data: string, fileToUpload: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('createNotification', 'data', data)
            // verify required parameter 'fileToUpload' is not null or undefined
            assertParamExists('createNotification', 'fileToUpload', fileToUpload)
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
            if (fileToUpload !== undefined) { 
                localVarFormParams.append('file_to_upload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Notification Photo
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNotificationPhoto: async (notificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('downloadNotificationPhoto', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notification_id}/download-photo`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Notifications
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Mark Notification Seen
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationSeen: async (notificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('markNotificationSeen', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notification_id}/mark-seen`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Allows a user to create a notification in the system.  Request Payload ` {     notification_body:  \"Notification 1\",     notification_title: \"Notification Title\",     story_id: Optional[UUID] = None     receiver_ids: [ ID, ID ]     notification_link: \"\" (optional) } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> notification:create, me,  
         * @summary Create Notification
         * @param {string} data 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(data: string, fileToUpload: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotification(data, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Notification Photo
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadNotificationPhoto(notificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadNotificationPhoto(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Notifications
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyNotificationsOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Mark Notification Seen
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationSeen(notificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationSeen(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Allows a user to create a notification in the system.  Request Payload ` {     notification_body:  \"Notification 1\",     notification_title: \"Notification Title\",     story_id: Optional[UUID] = None     receiver_ids: [ ID, ID ]     notification_link: \"\" (optional) } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> notification:create, me,  
         * @summary Create Notification
         * @param {string} data 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(data: string, fileToUpload: File, options?: any): AxiosPromise<NotificationOut> {
            return localVarFp.createNotification(data, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Notification Photo
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNotificationPhoto(notificationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadNotificationPhoto(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Notifications
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(skip?: number, limit?: number, options?: any): AxiosPromise<ManyNotificationsOut> {
            return localVarFp.getNotifications(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Mark Notification Seen
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationSeen(notificationId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.markNotificationSeen(notificationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Allows a user to create a notification in the system.  Request Payload ` {     notification_body:  \"Notification 1\",     notification_title: \"Notification Title\",     story_id: Optional[UUID] = None     receiver_ids: [ ID, ID ]     notification_link: \"\" (optional) } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> notification:create, me,  
     * @summary Create Notification
     * @param {string} data 
     * @param {File} fileToUpload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createNotification(data: string, fileToUpload: File, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createNotification(data, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Notification Photo
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public downloadNotificationPhoto(notificationId: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).downloadNotificationPhoto(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Notifications
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotifications(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Mark Notification Seen
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public markNotificationSeen(notificationId: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).markNotificationSeen(notificationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReadersApi - axios parameter creator
 * @export
 */
export const ReadersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Add Topic
         * @param {string} readerId 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTopic: async (readerId: string, topicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('addTopic', 'readerId', readerId)
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('addTopic', 'topicId', topicId)
            const localVarPath = `/readers/{reader_id}/add-topic`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (topicId !== undefined) {
                localVarQueryParameter['topic_id'] = topicId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Check Update Reader Streak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUpdateReaderStreak: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readers/reader-streak`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> reader:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Reader User
         * @param {string} readerId 
         * @param {string} [feedback] Why do you want to delete this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReaderUser: async (readerId: string, feedback?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('deleteReaderUser', 'readerId', readerId)
            const localVarPath = `/readers/{reader_id}/delete-reader`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (feedback !== undefined) {
                localVarQueryParameter['feedback'] = feedback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Delete Terminated Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTerminatedReaderStreak: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('deleteTerminatedReaderStreak', 'readerId', readerId)
            const localVarPath = `/readers/reader-streak/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the reader data for the current user. If the user is not a reader, a 403 error is thrown.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get My Reader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReader: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readers/my-reader`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Reader
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReader: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getReader', 'readerId', readerId)
            const localVarPath = `/readers/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Only admin users are allowed to request for another readers streak                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderStreak: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getReaderStreak', 'readerId', readerId)
            const localVarPath = `/readers/reader-streak/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of readers in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> reader:read, me,  
         * @summary Get Readers
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [onlyAnonymous] Search for only anonymous readers.
         * @param {boolean} [withAssociatedUser] Returns readers with or without an associated user. Note users that have deleted their account will only have a reader object de-associated from their user instance, they can not be linked to any reader object in the system when their account is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaders: async (orderDirection: OrderDirection, skip?: number, limit?: number, onlyAnonymous?: boolean, withAssociatedUser?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getReaders', 'orderDirection', orderDirection)
            const localVarPath = `/readers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (onlyAnonymous !== undefined) {
                localVarQueryParameter['only_anonymous'] = onlyAnonymous;
            }

            if (withAssociatedUser !== undefined) {
                localVarQueryParameter['with_associated_user'] = withAssociatedUser;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Readers Streaks
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReadersStreaks: async (orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('getReadersStreaks', 'orderBy', orderBy)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getReadersStreaks', 'orderDirection', orderDirection)
            const localVarPath = `/readers/readers-streaks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Referred Readers
         * @param {string} readerId 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [beforeDate] Searches for referred readers before this date.
         * @param {string} [afterDate] Searches for referred readers after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferredReaders: async (readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getReferredReaders', 'readerId', readerId)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getReferredReaders', 'orderDirection', orderDirection)
            const localVarPath = `/readers/{reader_id}/referred_readers`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (beforeDate !== undefined) {
                localVarQueryParameter['before_date'] = (beforeDate as any instanceof Date) ?
                    (beforeDate as any).toISOString().substring(0,10) :
                    beforeDate;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['after_date'] = (afterDate as any instanceof Date) ?
                    (afterDate as any).toISOString().substring(0,10) :
                    afterDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Play Terminated Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playTerminatedReaderStreak: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('playTerminatedReaderStreak', 'readerId', readerId)
            const localVarPath = `/readers/play-reader-streak/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Remove Topic
         * @param {string} readerId 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTopic: async (readerId: string, topicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('removeTopic', 'readerId', readerId)
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('removeTopic', 'topicId', topicId)
            const localVarPath = `/readers/{reader_id}/remove-topic`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (topicId !== undefined) {
                localVarQueryParameter['topic_id'] = topicId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Terminate Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateReaderStreak: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('terminateReaderStreak', 'readerId', readerId)
            const localVarPath = `/readers/terminate-reader-streak/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Reader
         * @param {string} readerId 
         * @param {ReaderUpdate} readerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReader: async (readerId: string, readerUpdate: ReaderUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('updateReader', 'readerId', readerId)
            // verify required parameter 'readerUpdate' is not null or undefined
            assertParamExists('updateReader', 'readerUpdate', readerUpdate)
            const localVarPath = `/readers/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readerUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReadersApi - functional programming interface
 * @export
 */
export const ReadersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReadersApiAxiosParamCreator(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Add Topic
         * @param {string} readerId 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTopic(readerId: string, topicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTopic(readerId, topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Check Update Reader Streak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUpdateReaderStreak(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderStreakOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUpdateReaderStreak(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> reader:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Reader User
         * @param {string} readerId 
         * @param {string} [feedback] Why do you want to delete this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReaderUser(readerId: string, feedback?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReaderUser(readerId, feedback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Delete Terminated Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTerminatedReaderStreak(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTerminatedReaderStreak(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the reader data for the current user. If the user is not a reader, a 403 error is thrown.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get My Reader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyReader(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyReader(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Reader
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReader(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReader(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Only admin users are allowed to request for another readers streak                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderStreak(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderStreakOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderStreak(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the list of readers in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> reader:read, me,  
         * @summary Get Readers
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [onlyAnonymous] Search for only anonymous readers.
         * @param {boolean} [withAssociatedUser] Returns readers with or without an associated user. Note users that have deleted their account will only have a reader object de-associated from their user instance, they can not be linked to any reader object in the system when their account is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaders(orderDirection: OrderDirection, skip?: number, limit?: number, onlyAnonymous?: boolean, withAssociatedUser?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyReadersOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaders(orderDirection, skip, limit, onlyAnonymous, withAssociatedUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Readers Streaks
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReadersStreaks(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyReaderStreaksOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReadersStreaks(orderBy, orderDirection, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Referred Readers
         * @param {string} readerId 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [beforeDate] Searches for referred readers before this date.
         * @param {string} [afterDate] Searches for referred readers after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferredReaders(readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyReferredReadersOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferredReaders(readerId, orderDirection, skip, limit, beforeDate, afterDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Play Terminated Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async playTerminatedReaderStreak(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderStreakOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.playTerminatedReaderStreak(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Remove Topic
         * @param {string} readerId 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTopic(readerId: string, topicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTopic(readerId, topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Terminate Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminateReaderStreak(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderStreakOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminateReaderStreak(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Reader
         * @param {string} readerId 
         * @param {ReaderUpdate} readerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReader(readerId: string, readerUpdate: ReaderUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReader(readerId, readerUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReadersApi - factory interface
 * @export
 */
export const ReadersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReadersApiFp(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Add Topic
         * @param {string} readerId 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTopic(readerId: string, topicId: string, options?: any): AxiosPromise<ReaderOut> {
            return localVarFp.addTopic(readerId, topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Check Update Reader Streak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUpdateReaderStreak(options?: any): AxiosPromise<ReaderStreakOut> {
            return localVarFp.checkUpdateReaderStreak(options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> reader:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Reader User
         * @param {string} readerId 
         * @param {string} [feedback] Why do you want to delete this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReaderUser(readerId: string, feedback?: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.deleteReaderUser(readerId, feedback, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Delete Terminated Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTerminatedReaderStreak(readerId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.deleteTerminatedReaderStreak(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the reader data for the current user. If the user is not a reader, a 403 error is thrown.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get My Reader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReader(options?: any): AxiosPromise<ReaderOut> {
            return localVarFp.getMyReader(options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Reader
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReader(readerId: string, options?: any): AxiosPromise<ReaderOut> {
            return localVarFp.getReader(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Only admin users are allowed to request for another readers streak                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderStreak(readerId: string, options?: any): AxiosPromise<ReaderStreakOut> {
            return localVarFp.getReaderStreak(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of readers in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> reader:read, me,  
         * @summary Get Readers
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [onlyAnonymous] Search for only anonymous readers.
         * @param {boolean} [withAssociatedUser] Returns readers with or without an associated user. Note users that have deleted their account will only have a reader object de-associated from their user instance, they can not be linked to any reader object in the system when their account is deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaders(orderDirection: OrderDirection, skip?: number, limit?: number, onlyAnonymous?: boolean, withAssociatedUser?: boolean, options?: any): AxiosPromise<ManyReadersOut> {
            return localVarFp.getReaders(orderDirection, skip, limit, onlyAnonymous, withAssociatedUser, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Readers Streaks
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReadersStreaks(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, options?: any): AxiosPromise<ManyReaderStreaksOut> {
            return localVarFp.getReadersStreaks(orderBy, orderDirection, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Referred Readers
         * @param {string} readerId 
         * @param {OrderDirection} orderDirection 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [beforeDate] Searches for referred readers before this date.
         * @param {string} [afterDate] Searches for referred readers after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferredReaders(readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options?: any): AxiosPromise<ManyReferredReadersOut> {
            return localVarFp.getReferredReaders(readerId, orderDirection, skip, limit, beforeDate, afterDate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Play Terminated Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        playTerminatedReaderStreak(readerId: string, options?: any): AxiosPromise<ReaderStreakOut> {
            return localVarFp.playTerminatedReaderStreak(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Remove Topic
         * @param {string} readerId 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTopic(readerId: string, topicId: string, options?: any): AxiosPromise<ReaderOut> {
            return localVarFp.removeTopic(readerId, topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Terminate Reader Streak
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateReaderStreak(readerId: string, options?: any): AxiosPromise<ReaderStreakOut> {
            return localVarFp.terminateReaderStreak(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Reader
         * @param {string} readerId 
         * @param {ReaderUpdate} readerUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReader(readerId: string, readerUpdate: ReaderUpdate, options?: any): AxiosPromise<ReaderOut> {
            return localVarFp.updateReader(readerId, readerUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReadersApi - object-oriented interface
 * @export
 * @class ReadersApi
 * @extends {BaseAPI}
 */
export class ReadersApi extends BaseAPI {
    /**
     * <strong>Scopes: </strong> me,
     * @summary Add Topic
     * @param {string} readerId 
     * @param {string} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public addTopic(readerId: string, topicId: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).addTopic(readerId, topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Check Update Reader Streak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public checkUpdateReaderStreak(options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).checkUpdateReaderStreak(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> reader:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Delete Reader User
     * @param {string} readerId 
     * @param {string} [feedback] Why do you want to delete this account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public deleteReaderUser(readerId: string, feedback?: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).deleteReaderUser(readerId, feedback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Delete Terminated Reader Streak
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public deleteTerminatedReaderStreak(readerId: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).deleteTerminatedReaderStreak(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the reader data for the current user. If the user is not a reader, a 403 error is thrown.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Get My Reader
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public getMyReader(options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).getMyReader(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Reader
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public getReader(readerId: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).getReader(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Only admin users are allowed to request for another readers streak                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Get Reader Streak
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public getReaderStreak(readerId: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).getReaderStreak(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of readers in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> reader:read, me,  
     * @summary Get Readers
     * @param {OrderDirection} orderDirection 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [onlyAnonymous] Search for only anonymous readers.
     * @param {boolean} [withAssociatedUser] Returns readers with or without an associated user. Note users that have deleted their account will only have a reader object de-associated from their user instance, they can not be linked to any reader object in the system when their account is deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public getReaders(orderDirection: OrderDirection, skip?: number, limit?: number, onlyAnonymous?: boolean, withAssociatedUser?: boolean, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).getReaders(orderDirection, skip, limit, onlyAnonymous, withAssociatedUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Readers Streaks
     * @param {OrderBy} orderBy 
     * @param {OrderDirection} orderDirection 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public getReadersStreaks(orderBy: OrderBy, orderDirection: OrderDirection, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).getReadersStreaks(orderBy, orderDirection, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Referred Readers
     * @param {string} readerId 
     * @param {OrderDirection} orderDirection 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [beforeDate] Searches for referred readers before this date.
     * @param {string} [afterDate] Searches for referred readers after this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public getReferredReaders(readerId: string, orderDirection: OrderDirection, skip?: number, limit?: number, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).getReferredReaders(readerId, orderDirection, skip, limit, beforeDate, afterDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Play Terminated Reader Streak
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public playTerminatedReaderStreak(readerId: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).playTerminatedReaderStreak(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Remove Topic
     * @param {string} readerId 
     * @param {string} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public removeTopic(readerId: string, topicId: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).removeTopic(readerId, topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Terminate Reader Streak
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public terminateReaderStreak(readerId: string, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).terminateReaderStreak(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Update Reader
     * @param {string} readerId 
     * @param {ReaderUpdate} readerUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadersApi
     */
    public updateReader(readerId: string, readerUpdate: ReaderUpdate, options?: AxiosRequestConfig) {
        return ReadersApiFp(this.configuration).updateReader(readerId, readerUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesAndPermissionsApi - axios parameter creator
 * @export
 */
export const RolesAndPermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assigns the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Assign Role
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRole: async (roleId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('assignRole', 'roleId', roleId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('assignRole', 'userId', userId)
            const localVarPath = `/roles/{role_id}/assign-role`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows an admin to create a role in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Create Role
         * @param {RoleCreate} roleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreate: RoleCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleCreate' is not null or undefined
            assertParamExists('createRole', 'roleCreate', roleCreate)
            const localVarPath = `/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the role. This endpoint removes the roles from all users that has been previously assigned. The integer returned is the total number of all users the role has been removed from.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:delete, me,  
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (roleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRole', 'roleId', roleId)
            const localVarPath = `/roles/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:update, me,  
         * @summary Edit Role
         * @param {string} roleId 
         * @param {BodyEditRoleRolesRoleIdPut} bodyEditRoleRolesRoleIdPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRole: async (roleId: string, bodyEditRoleRolesRoleIdPut: BodyEditRoleRolesRoleIdPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('editRole', 'roleId', roleId)
            // verify required parameter 'bodyEditRoleRolesRoleIdPut' is not null or undefined
            assertParamExists('editRole', 'bodyEditRoleRolesRoleIdPut', bodyEditRoleRolesRoleIdPut)
            const localVarPath = `/roles/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyEditRoleRolesRoleIdPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary Get Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (roleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('getRole', 'roleId', roleId)
            const localVarPath = `/roles/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets all the roles in the system                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary Get Roles
         * @param {OrderDirection} orderDirection 
         * @param {OrderBy} orderBy 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (orderDirection: OrderDirection, orderBy: OrderBy, skip?: number, limit?: number, title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getRoles', 'orderDirection', orderDirection)
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('getRoles', 'orderBy', orderBy)
            const localVarPath = `/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the users that are assigned to a particular role.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary List Users Assigned To Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersAssignedToRole: async (roleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('listUsersAssignedToRole', 'roleId', roleId)
            const localVarPath = `/roles/{role_id}/list-assigned-users`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unassign the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Unassign Role
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignRole: async (roleId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('unassignRole', 'roleId', roleId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('unassignRole', 'userId', userId)
            const localVarPath = `/roles/{role_id}/unassign-role`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesAndPermissionsApi - functional programming interface
 * @export
 */
export const RolesAndPermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesAndPermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Assigns the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Assign Role
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRole(roleId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRole(roleId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows an admin to create a role in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Create Role
         * @param {RoleCreate} roleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreate: RoleCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(roleCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the role. This endpoint removes the roles from all users that has been previously assigned. The integer returned is the total number of all users the role has been removed from.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:delete, me,  
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(roleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:update, me,  
         * @summary Edit Role
         * @param {string} roleId 
         * @param {BodyEditRoleRolesRoleIdPut} bodyEditRoleRolesRoleIdPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editRole(roleId: string, bodyEditRoleRolesRoleIdPut: BodyEditRoleRolesRoleIdPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editRole(roleId, bodyEditRoleRolesRoleIdPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary Get Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(roleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets all the roles in the system                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary Get Roles
         * @param {OrderDirection} orderDirection 
         * @param {OrderBy} orderBy 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(orderDirection: OrderDirection, orderBy: OrderBy, skip?: number, limit?: number, title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyRolesOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(orderDirection, orderBy, skip, limit, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List the users that are assigned to a particular role.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary List Users Assigned To Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersAssignedToRole(roleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyUserRolesOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersAssignedToRole(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unassign the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Unassign Role
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignRole(roleId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignRole(roleId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesAndPermissionsApi - factory interface
 * @export
 */
export const RolesAndPermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesAndPermissionsApiFp(configuration)
    return {
        /**
         * Assigns the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Assign Role
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRole(roleId: string, userId: string, options?: any): AxiosPromise<UserOut> {
            return localVarFp.assignRole(roleId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows an admin to create a role in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Create Role
         * @param {RoleCreate} roleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreate: RoleCreate, options?: any): AxiosPromise<RoleOut> {
            return localVarFp.createRole(roleCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the role. This endpoint removes the roles from all users that has been previously assigned. The integer returned is the total number of all users the role has been removed from.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:delete, me,  
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(roleId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.deleteRole(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:update, me,  
         * @summary Edit Role
         * @param {string} roleId 
         * @param {BodyEditRoleRolesRoleIdPut} bodyEditRoleRolesRoleIdPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRole(roleId: string, bodyEditRoleRolesRoleIdPut: BodyEditRoleRolesRoleIdPut, options?: any): AxiosPromise<RoleOut> {
            return localVarFp.editRole(roleId, bodyEditRoleRolesRoleIdPut, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary Get Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(roleId: string, options?: any): AxiosPromise<RoleOut> {
            return localVarFp.getRole(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all the roles in the system                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary Get Roles
         * @param {OrderDirection} orderDirection 
         * @param {OrderBy} orderBy 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(orderDirection: OrderDirection, orderBy: OrderBy, skip?: number, limit?: number, title?: string, options?: any): AxiosPromise<ManyRolesOut> {
            return localVarFp.getRoles(orderDirection, orderBy, skip, limit, title, options).then((request) => request(axios, basePath));
        },
        /**
         * List the users that are assigned to a particular role.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
         * @summary List Users Assigned To Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersAssignedToRole(roleId: string, options?: any): AxiosPromise<ManyUserRolesOut> {
            return localVarFp.listUsersAssignedToRole(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Unassign the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
         * @summary Unassign Role
         * @param {string} roleId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignRole(roleId: string, userId: string, options?: any): AxiosPromise<UserOut> {
            return localVarFp.unassignRole(roleId, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesAndPermissionsApi - object-oriented interface
 * @export
 * @class RolesAndPermissionsApi
 * @extends {BaseAPI}
 */
export class RolesAndPermissionsApi extends BaseAPI {
    /**
     * Assigns the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
     * @summary Assign Role
     * @param {string} roleId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public assignRole(roleId: string, userId: string, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).assignRole(roleId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows an admin to create a role in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
     * @summary Create Role
     * @param {RoleCreate} roleCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public createRole(roleCreate: RoleCreate, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).createRole(roleCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the role. This endpoint removes the roles from all users that has been previously assigned. The integer returned is the total number of all users the role has been removed from.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:delete, me,  
     * @summary Delete Role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public deleteRole(roleId: string, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).deleteRole(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:update, me,  
     * @summary Edit Role
     * @param {string} roleId 
     * @param {BodyEditRoleRolesRoleIdPut} bodyEditRoleRolesRoleIdPut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public editRole(roleId: string, bodyEditRoleRolesRoleIdPut: BodyEditRoleRolesRoleIdPut, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).editRole(roleId, bodyEditRoleRolesRoleIdPut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a single role                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
     * @summary Get Role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public getRole(roleId: string, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).getRole(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets all the roles in the system                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
     * @summary Get Roles
     * @param {OrderDirection} orderDirection 
     * @param {OrderBy} orderBy 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public getRoles(orderDirection: OrderDirection, orderBy: OrderBy, skip?: number, limit?: number, title?: string, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).getRoles(orderDirection, orderBy, skip, limit, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the users that are assigned to a particular role.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:read, me,  
     * @summary List Users Assigned To Role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public listUsersAssignedToRole(roleId: string, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).listUsersAssignedToRole(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unassign the role to the specified user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> role:create, me,  
     * @summary Unassign Role
     * @param {string} roleId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public unassignRole(roleId: string, userId: string, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).unassignRole(roleId, userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SharedStoryLinkViewApi - axios parameter creator
 * @export
 */
export const SharedStoryLinkViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Shared Story Link View
         * @param {string} refCode 
         * @param {string} viewedReaderId 
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedStoryLinkView: async (refCode: string, viewedReaderId: string, storyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refCode' is not null or undefined
            assertParamExists('createSharedStoryLinkView', 'refCode', refCode)
            // verify required parameter 'viewedReaderId' is not null or undefined
            assertParamExists('createSharedStoryLinkView', 'viewedReaderId', viewedReaderId)
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('createSharedStoryLinkView', 'storyId', storyId)
            const localVarPath = `/shared-story-link-view/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (refCode !== undefined) {
                localVarQueryParameter['ref_code'] = refCode;
            }

            if (viewedReaderId !== undefined) {
                localVarQueryParameter['viewed_reader_id'] = viewedReaderId;
            }

            if (storyId !== undefined) {
                localVarQueryParameter['story_id'] = storyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Shared Story Link View Summary
         * @param {string} readerId 
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedStoryLinkViewSummary: async (readerId: string, storyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getSharedStoryLinkViewSummary', 'readerId', readerId)
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('getSharedStoryLinkViewSummary', 'storyId', storyId)
            const localVarPath = `/shared-story-link-view/{reader_id}/{story_id}/summary`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)))
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Shared Story Link Views
         * @param {string} readerId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedStoryLinkViews: async (readerId: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getSharedStoryLinkViews', 'readerId', readerId)
            const localVarPath = `/shared-story-link-view/{reader_id}`
                .replace(`{${"reader_id"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharedStoryLinkViewApi - functional programming interface
 * @export
 */
export const SharedStoryLinkViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharedStoryLinkViewApiAxiosParamCreator(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Shared Story Link View
         * @param {string} refCode 
         * @param {string} viewedReaderId 
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSharedStoryLinkView(refCode: string, viewedReaderId: string, storyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedStoryLinkViewOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSharedStoryLinkView(refCode, viewedReaderId, storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Shared Story Link View Summary
         * @param {string} readerId 
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedStoryLinkViewSummary(readerId: string, storyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedStoryLinkViewSummaryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedStoryLinkViewSummary(readerId, storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Shared Story Link Views
         * @param {string} readerId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedStoryLinkViews(readerId: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManySharedStoryLinkViewOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedStoryLinkViews(readerId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SharedStoryLinkViewApi - factory interface
 * @export
 */
export const SharedStoryLinkViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharedStoryLinkViewApiFp(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Shared Story Link View
         * @param {string} refCode 
         * @param {string} viewedReaderId 
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedStoryLinkView(refCode: string, viewedReaderId: string, storyId: string, options?: any): AxiosPromise<SharedStoryLinkViewOut> {
            return localVarFp.createSharedStoryLinkView(refCode, viewedReaderId, storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Shared Story Link View Summary
         * @param {string} readerId 
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedStoryLinkViewSummary(readerId: string, storyId: string, options?: any): AxiosPromise<SharedStoryLinkViewSummaryOut> {
            return localVarFp.getSharedStoryLinkViewSummary(readerId, storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Shared Story Link Views
         * @param {string} readerId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedStoryLinkViews(readerId: string, skip?: number, limit?: number, options?: any): AxiosPromise<ManySharedStoryLinkViewOut> {
            return localVarFp.getSharedStoryLinkViews(readerId, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharedStoryLinkViewApi - object-oriented interface
 * @export
 * @class SharedStoryLinkViewApi
 * @extends {BaseAPI}
 */
export class SharedStoryLinkViewApi extends BaseAPI {
    /**
     * <strong>Scopes: </strong> me,
     * @summary Create Shared Story Link View
     * @param {string} refCode 
     * @param {string} viewedReaderId 
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedStoryLinkViewApi
     */
    public createSharedStoryLinkView(refCode: string, viewedReaderId: string, storyId: string, options?: AxiosRequestConfig) {
        return SharedStoryLinkViewApiFp(this.configuration).createSharedStoryLinkView(refCode, viewedReaderId, storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Shared Story Link View Summary
     * @param {string} readerId 
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedStoryLinkViewApi
     */
    public getSharedStoryLinkViewSummary(readerId: string, storyId: string, options?: AxiosRequestConfig) {
        return SharedStoryLinkViewApiFp(this.configuration).getSharedStoryLinkViewSummary(readerId, storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Shared Story Link Views
     * @param {string} readerId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedStoryLinkViewApi
     */
    public getSharedStoryLinkViews(readerId: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return SharedStoryLinkViewApiFp(this.configuration).getSharedStoryLinkViews(readerId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoriesApi - axios parameter creator
 * @export
 */
export const StoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Comment
         * @param {string} storyId 
         * @param {CommentIn} commentIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (storyId: string, commentIn: CommentIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('createComment', 'storyId', storyId)
            // verify required parameter 'commentIn' is not null or undefined
            assertParamExists('createComment', 'commentIn', commentIn)
            const localVarPath = `/stories/{story_id}/comments`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows a user to create a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:create, me,  
         * @summary Create Story
         * @param {string} data 
         * @param {File} fileToUpload 
         * @param {File} thumbnailFileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStory: async (data: string, fileToUpload: File, thumbnailFileToUpload: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('createStory', 'data', data)
            // verify required parameter 'fileToUpload' is not null or undefined
            assertParamExists('createStory', 'fileToUpload', fileToUpload)
            // verify required parameter 'thumbnailFileToUpload' is not null or undefined
            assertParamExists('createStory', 'thumbnailFileToUpload', thumbnailFileToUpload)
            const localVarPath = `/stories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
            if (fileToUpload !== undefined) { 
                localVarFormParams.append('file_to_upload', fileToUpload as any);
            }
    
            if (thumbnailFileToUpload !== undefined) { 
                localVarFormParams.append('thumbnail_file_to_upload', thumbnailFileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Story Report
         * @param {string} storyId 
         * @param {ReportIn} reportIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoryReport: async (storyId: string, reportIn: ReportIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('createStoryReport', 'storyId', storyId)
            // verify required parameter 'reportIn' is not null or undefined
            assertParamExists('createStoryReport', 'reportIn', reportIn)
            const localVarPath = `/stories/{story_id}/report-story`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> report:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReport', 'reportId', reportId)
            const localVarPath = `/stories/{report_id}/delete-report`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Story Photo
         * @param {string} storyId 
         * @param {ImageFileVersion} [fileVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStoryPhoto: async (storyId: string, fileVersion?: ImageFileVersion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('downloadStoryPhoto', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}/download-photo`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileVersion !== undefined) {
                localVarQueryParameter['file_version'] = fileVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Comment By Id
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentById: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getCommentById', 'commentId', commentId)
            const localVarPath = `/stories/{comment_id}/comments`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Comments
         * @param {OrderDirection} orderDirection 
         * @param {string} [storyId] Returns comments for specified story_id.
         * @param {string} [parentCommentId] Returns story first level comments if none.
         * @param {boolean} [includeHiddenComments] Return all hidden comments with comment.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: async (orderDirection: OrderDirection, storyId?: string, parentCommentId?: string, includeHiddenComments?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getComments', 'orderDirection', orderDirection)
            const localVarPath = `/stories/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (storyId !== undefined) {
                localVarQueryParameter['story_id'] = storyId;
            }

            if (parentCommentId !== undefined) {
                localVarQueryParameter['parent_comment_id'] = parentCommentId;
            }

            if (includeHiddenComments !== undefined) {
                localVarQueryParameter['include_hidden_comments'] = includeHiddenComments;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Featured Stories Unauthenticated
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [forDate] Fetches the featured stories for this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedStoriesUnauthenticated: async (skip?: number, limit?: number, forDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stories/featured-stories-unauthenticated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (forDate !== undefined) {
                localVarQueryParameter['for_date'] = (forDate as any instanceof Date) ?
                    (forDate as any).toISOString().substring(0,10) :
                    forDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> report:view,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/stories/report/{report_id}`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns reports in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> report:view, me,  
         * @summary Get Reports
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {ReportStatus} [status] Searches for reports with this status.
         * @param {string} [mainReason] Searches for reports with this reason.
         * @param {string} [storyId] Searches report for this story.
         * @param {string} [readerId] Searches reports submitted by this reader.
         * @param {string} [afterDate] Searches for reports created after this date.
         * @param {string} [beforeDate] Searches for reports created before this date.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports: async (orderBy: OrderBy, orderDirection: OrderDirection, status?: ReportStatus, mainReason?: string, storyId?: string, readerId?: string, afterDate?: string, beforeDate?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('getReports', 'orderBy', orderBy)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getReports', 'orderDirection', orderDirection)
            const localVarPath = `/stories/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (mainReason !== undefined) {
                localVarQueryParameter['main_reason'] = mainReason;
            }

            if (storyId !== undefined) {
                localVarQueryParameter['story_id'] = storyId;
            }

            if (readerId !== undefined) {
                localVarQueryParameter['reader_id'] = readerId;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['after_date'] = (afterDate as any instanceof Date) ?
                    (afterDate as any).toISOString().substring(0,10) :
                    afterDate;
            }

            if (beforeDate !== undefined) {
                localVarQueryParameter['before_date'] = (beforeDate as any instanceof Date) ?
                    (beforeDate as any).toISOString().substring(0,10) :
                    beforeDate;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the stories in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Stories
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {string} [title] Searches the stories with this title
         * @param {string} [slug] Searches the stories with this slug
         * @param {string} [creatorId] Fetches stories for the specified creator.
         * @param {string} [afterDate] Fetches stories starting from this date onward
         * @param {Array<string>} [topicIds] Fetches stories with these topics
         * @param {string} [beforeDate] Fetches stories before from this date downward
         * @param {string} [createdByUserId] Fetches stories for this particular user. This could be a team member.
         * @param {boolean} [returnViews] Fetches only the stories this reader has viewed
         * @param {boolean} [returnLikes] Fetches only the stories this reader has liked
         * @param {boolean} [returnShares] Fetches only the stories this reader has shared
         * @param {boolean} [returnOnlyPublished] Fetches stories with this published status
         * @param {boolean} [returnActive] Fetches stories with this active status
         * @param {boolean} [returnFeatured] Fetches featured stories.
         * @param {boolean} [returnAlertReaders] Fetches Stories that would trigger alert to the readers.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStories: async (orderBy: OrderBy, orderDirection: OrderDirection, title?: string, slug?: string, creatorId?: string, afterDate?: string, topicIds?: Array<string>, beforeDate?: string, createdByUserId?: string, returnViews?: boolean, returnLikes?: boolean, returnShares?: boolean, returnOnlyPublished?: boolean, returnActive?: boolean, returnFeatured?: boolean, returnAlertReaders?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('getStories', 'orderBy', orderBy)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getStories', 'orderDirection', orderDirection)
            const localVarPath = `/stories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (creatorId !== undefined) {
                localVarQueryParameter['creator_id'] = creatorId;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['after_date'] = (afterDate as any instanceof Date) ?
                    (afterDate as any).toISOString().substring(0,10) :
                    afterDate;
            }

            if (topicIds) {
                localVarQueryParameter['topic_ids'] = topicIds;
            }

            if (beforeDate !== undefined) {
                localVarQueryParameter['before_date'] = (beforeDate as any instanceof Date) ?
                    (beforeDate as any).toISOString().substring(0,10) :
                    beforeDate;
            }

            if (createdByUserId !== undefined) {
                localVarQueryParameter['created_by_user_id'] = createdByUserId;
            }

            if (returnViews !== undefined) {
                localVarQueryParameter['return_views'] = returnViews;
            }

            if (returnLikes !== undefined) {
                localVarQueryParameter['return_likes'] = returnLikes;
            }

            if (returnShares !== undefined) {
                localVarQueryParameter['return_shares'] = returnShares;
            }

            if (returnOnlyPublished !== undefined) {
                localVarQueryParameter['return_only_published'] = returnOnlyPublished;
            }

            if (returnActive !== undefined) {
                localVarQueryParameter['return_active'] = returnActive;
            }

            if (returnFeatured !== undefined) {
                localVarQueryParameter['return_featured'] = returnFeatured;
            }

            if (returnAlertReaders !== undefined) {
                localVarQueryParameter['return_alert_readers'] = returnAlertReaders;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStory: async (storyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('getStory', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Likes Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story likes until this day.
         * @param {string} [storyId] If not provided returns all likes within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryLikesCount: async (startDate: string, stopDate?: string, storyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getStoryLikesCount', 'startDate', startDate)
            const localVarPath = `/stories/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (stopDate !== undefined) {
                localVarQueryParameter['stop_date'] = (stopDate as any instanceof Date) ?
                    (stopDate as any).toISOString().substring(0,10) :
                    stopDate;
            }

            if (storyId !== undefined) {
                localVarQueryParameter['story_id'] = storyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Shares Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story shares until this day.
         * @param {string} [storyId] If not provided returns all shares within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySharesCount: async (startDate: string, stopDate?: string, storyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getStorySharesCount', 'startDate', startDate)
            const localVarPath = `/stories/shares`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (stopDate !== undefined) {
                localVarQueryParameter['stop_date'] = (stopDate as any instanceof Date) ?
                    (stopDate as any).toISOString().substring(0,10) :
                    stopDate;
            }

            if (storyId !== undefined) {
                localVarQueryParameter['story_id'] = storyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Views Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story views until this day.
         * @param {string} [storyId] If not provided returns all views within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryViewsCount: async (startDate: string, stopDate?: string, storyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getStoryViewsCount', 'startDate', startDate)
            const localVarPath = `/stories/views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (stopDate !== undefined) {
                localVarQueryParameter['stop_date'] = (stopDate as any instanceof Date) ?
                    (stopDate as any).toISOString().substring(0,10) :
                    stopDate;
            }

            if (storyId !== undefined) {
                localVarQueryParameter['story_id'] = storyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the story that matches this slug. Please note, this endpoint does not return the full story schema
         * @summary Get Story With Slug
         * @param {string} storySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryWithSlug: async (storySlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storySlug' is not null or undefined
            assertParamExists('getStoryWithSlug', 'storySlug', storySlug)
            const localVarPath = `/stories/slug/{story_slug}`
                .replace(`{${"story_slug"}}`, encodeURIComponent(String(storySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topic Comparison
         * @param {string} topicId get comparison for this topic
         * @param {number} [days] compare the last input days with its previous period.
         * @param {string} [untilDate] End date to count from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicComparison: async (topicId: string, days?: number, untilDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('getTopicComparison', 'topicId', topicId)
            const localVarPath = `/stories/topic-comparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (topicId !== undefined) {
                localVarQueryParameter['topic_id'] = topicId;
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (untilDate !== undefined) {
                localVarQueryParameter['until_date'] = (untilDate as any instanceof Date) ?
                    (untilDate as any).toISOString().substring(0,10) :
                    untilDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Topic Views Count
         * @param {string} startDate 
         * @param {string} topicId Returns all story views related to this topic.
         * @param {string} [stopDate] Returns topic views until this day.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicViewsCount: async (startDate: string, topicId: string, stopDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTopicViewsCount', 'startDate', startDate)
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('getTopicViewsCount', 'topicId', topicId)
            const localVarPath = `/stories/topic-views`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (stopDate !== undefined) {
                localVarQueryParameter['stop_date'] = (stopDate as any instanceof Date) ?
                    (stopDate as any).toISOString().substring(0,10) :
                    stopDate;
            }

            if (topicId !== undefined) {
                localVarQueryParameter['topic_id'] = topicId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Hide Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideComment: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('hideComment', 'commentId', commentId)
            const localVarPath = `/stories/hide-comment/{comment_id}`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * like a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Liked
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryLiked: async (storyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('markStoryLiked', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}/mark-story-liked`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks a story as viewed. The usr must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Read
         * @param {string} storyId 
         * @param {boolean} [updateStreak] Tries to update the reader\&#39;s streak.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryRead: async (storyId: string, updateStreak?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('markStoryRead', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}/mark-story-read`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (updateStreak !== undefined) {
                localVarQueryParameter['update_streak'] = updateStreak;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * shares a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Shared
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryShared: async (storyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('markStoryShared', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}/mark-story-shared`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Mark Story Unliked
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryUnliked: async (storyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('markStoryUnliked', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}/mark-story-unliked`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Send Push Notifications To Devices
         * @param {string} storyId 
         * @param {RegistrationTokens} registrationTokens 
         * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotificationsToDevices: async (storyId: string, registrationTokens: RegistrationTokens, dryRun?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('sendPushNotificationsToDevices', 'storyId', storyId)
            // verify required parameter 'registrationTokens' is not null or undefined
            assertParamExists('sendPushNotificationsToDevices', 'registrationTokens', registrationTokens)
            const localVarPath = `/stories/{story_id}/send-notifications-to-devices`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (dryRun !== undefined) {
                localVarQueryParameter['dry_run'] = dryRun;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationTokens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Send Push Notifications To Subscribed Topic Readers
         * @param {string} storyId 
         * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotificationsToSubscribedTopicReaders: async (storyId: string, dryRun?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('sendPushNotificationsToSubscribedTopicReaders', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}/send-notifications-to-subscribed-topic-readers`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (dryRun !== undefined) {
                localVarQueryParameter['dry_run'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Soft Delete Story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteStory: async (storyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('softDeleteStory', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> report:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Report
         * @param {string} reportId 
         * @param {ReportUpdate} reportUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (reportId: string, reportUpdate: ReportUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReport', 'reportId', reportId)
            // verify required parameter 'reportUpdate' is not null or undefined
            assertParamExists('updateReport', 'reportUpdate', reportUpdate)
            const localVarPath = `/stories/{report_id}/update-report`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows a user to update a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:update, me,  
         * @summary Update Story
         * @param {string} storyId 
         * @param {string} [data] 
         * @param {File} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStory: async (storyId: string, data?: string, fileToUpload?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('updateStory', 'storyId', storyId)
            const localVarPath = `/stories/{story_id}`
                .replace(`{${"story_id"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
            if (fileToUpload !== undefined) { 
                localVarFormParams.append('file_to_upload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Vote Comment Down
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteCommentDown: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('voteCommentDown', 'commentId', commentId)
            const localVarPath = `/stories/{comment_id}/vote-comment-down`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Vote Comment Up
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteCommentUp: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('voteCommentUp', 'commentId', commentId)
            const localVarPath = `/stories/{comment_id}/vote-comment-up`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoriesApi - functional programming interface
 * @export
 */
export const StoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Comment
         * @param {string} storyId 
         * @param {CommentIn} commentIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(storyId: string, commentIn: CommentIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(storyId, commentIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows a user to create a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:create, me,  
         * @summary Create Story
         * @param {string} data 
         * @param {File} fileToUpload 
         * @param {File} thumbnailFileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStory(data: string, fileToUpload: File, thumbnailFileToUpload: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStory(data, fileToUpload, thumbnailFileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Story Report
         * @param {string} storyId 
         * @param {ReportIn} reportIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStoryReport(storyId: string, reportIn: ReportIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStoryReport(storyId, reportIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> report:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Story Photo
         * @param {string} storyId 
         * @param {ImageFileVersion} [fileVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadStoryPhoto(storyId: string, fileVersion?: ImageFileVersion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadStoryPhoto(storyId, fileVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Comment By Id
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentById(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentById(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Comments
         * @param {OrderDirection} orderDirection 
         * @param {string} [storyId] Returns comments for specified story_id.
         * @param {string} [parentCommentId] Returns story first level comments if none.
         * @param {boolean} [includeHiddenComments] Return all hidden comments with comment.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComments(orderDirection: OrderDirection, storyId?: string, parentCommentId?: string, includeHiddenComments?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyCommentOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(orderDirection, storyId, parentCommentId, includeHiddenComments, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Featured Stories Unauthenticated
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [forDate] Fetches the featured stories for this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturedStoriesUnauthenticated(skip?: number, limit?: number, forDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyMiniStoryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturedStoriesUnauthenticated(skip, limit, forDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> report:view,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns reports in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> report:view, me,  
         * @summary Get Reports
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {ReportStatus} [status] Searches for reports with this status.
         * @param {string} [mainReason] Searches for reports with this reason.
         * @param {string} [storyId] Searches report for this story.
         * @param {string} [readerId] Searches reports submitted by this reader.
         * @param {string} [afterDate] Searches for reports created after this date.
         * @param {string} [beforeDate] Searches for reports created before this date.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReports(orderBy: OrderBy, orderDirection: OrderDirection, status?: ReportStatus, mainReason?: string, storyId?: string, readerId?: string, afterDate?: string, beforeDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyReportsOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReports(orderBy, orderDirection, status, mainReason, storyId, readerId, afterDate, beforeDate, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the stories in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Stories
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {string} [title] Searches the stories with this title
         * @param {string} [slug] Searches the stories with this slug
         * @param {string} [creatorId] Fetches stories for the specified creator.
         * @param {string} [afterDate] Fetches stories starting from this date onward
         * @param {Array<string>} [topicIds] Fetches stories with these topics
         * @param {string} [beforeDate] Fetches stories before from this date downward
         * @param {string} [createdByUserId] Fetches stories for this particular user. This could be a team member.
         * @param {boolean} [returnViews] Fetches only the stories this reader has viewed
         * @param {boolean} [returnLikes] Fetches only the stories this reader has liked
         * @param {boolean} [returnShares] Fetches only the stories this reader has shared
         * @param {boolean} [returnOnlyPublished] Fetches stories with this published status
         * @param {boolean} [returnActive] Fetches stories with this active status
         * @param {boolean} [returnFeatured] Fetches featured stories.
         * @param {boolean} [returnAlertReaders] Fetches Stories that would trigger alert to the readers.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStories(orderBy: OrderBy, orderDirection: OrderDirection, title?: string, slug?: string, creatorId?: string, afterDate?: string, topicIds?: Array<string>, beforeDate?: string, createdByUserId?: string, returnViews?: boolean, returnLikes?: boolean, returnShares?: boolean, returnOnlyPublished?: boolean, returnActive?: boolean, returnFeatured?: boolean, returnAlertReaders?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyStoriesOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStories(orderBy, orderDirection, title, slug, creatorId, afterDate, topicIds, beforeDate, createdByUserId, returnViews, returnLikes, returnShares, returnOnlyPublished, returnActive, returnFeatured, returnAlertReaders, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStory(storyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStory(storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Likes Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story likes until this day.
         * @param {string} [storyId] If not provided returns all likes within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoryLikesCount(startDate: string, stopDate?: string, storyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoryLikesCount(startDate, stopDate, storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Shares Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story shares until this day.
         * @param {string} [storyId] If not provided returns all shares within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStorySharesCount(startDate: string, stopDate?: string, storyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStorySharesCount(startDate, stopDate, storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Views Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story views until this day.
         * @param {string} [storyId] If not provided returns all views within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoryViewsCount(startDate: string, stopDate?: string, storyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoryViewsCount(startDate, stopDate, storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches the story that matches this slug. Please note, this endpoint does not return the full story schema
         * @summary Get Story With Slug
         * @param {string} storySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoryWithSlug(storySlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiniStoryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoryWithSlug(storySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topic Comparison
         * @param {string} topicId get comparison for this topic
         * @param {number} [days] compare the last input days with its previous period.
         * @param {string} [untilDate] End date to count from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicComparison(topicId: string, days?: number, untilDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicViewsComparisonOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicComparison(topicId, days, untilDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Topic Views Count
         * @param {string} startDate 
         * @param {string} topicId Returns all story views related to this topic.
         * @param {string} [stopDate] Returns topic views until this day.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicViewsCount(startDate: string, topicId: string, stopDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicViewsCount(startDate, topicId, stopDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Hide Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideComment(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * like a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Liked
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStoryLiked(storyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryLikedOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markStoryLiked(storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Marks a story as viewed. The usr must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Read
         * @param {string} storyId 
         * @param {boolean} [updateStreak] Tries to update the reader\&#39;s streak.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStoryRead(storyId: string, updateStreak?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryViewedOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markStoryRead(storyId, updateStreak, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * shares a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Shared
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStoryShared(storyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorySharedOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markStoryShared(storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Mark Story Unliked
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markStoryUnliked(storyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markStoryUnliked(storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Send Push Notifications To Devices
         * @param {string} storyId 
         * @param {RegistrationTokens} registrationTokens 
         * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPushNotificationsToDevices(storyId: string, registrationTokens: RegistrationTokens, dryRun?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPushNotificationsToDevices(storyId, registrationTokens, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Send Push Notifications To Subscribed Topic Readers
         * @param {string} storyId 
         * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPushNotificationsToSubscribedTopicReaders(storyId: string, dryRun?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPushNotificationsToSubscribedTopicReaders(storyId, dryRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Soft Delete Story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async softDeleteStory(storyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.softDeleteStory(storyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> report:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Report
         * @param {string} reportId 
         * @param {ReportUpdate} reportUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(reportId: string, reportUpdate: ReportUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(reportId, reportUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows a user to update a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:update, me,  
         * @summary Update Story
         * @param {string} storyId 
         * @param {string} [data] 
         * @param {File} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStory(storyId: string, data?: string, fileToUpload?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStory(storyId, data, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Vote Comment Down
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voteCommentDown(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voteCommentDown(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Vote Comment Up
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voteCommentUp(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voteCommentUp(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoriesApi - factory interface
 * @export
 */
export const StoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoriesApiFp(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Comment
         * @param {string} storyId 
         * @param {CommentIn} commentIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(storyId: string, commentIn: CommentIn, options?: any): AxiosPromise<CommentOut> {
            return localVarFp.createComment(storyId, commentIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows a user to create a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:create, me,  
         * @summary Create Story
         * @param {string} data 
         * @param {File} fileToUpload 
         * @param {File} thumbnailFileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStory(data: string, fileToUpload: File, thumbnailFileToUpload: File, options?: any): AxiosPromise<StoryOut> {
            return localVarFp.createStory(data, fileToUpload, thumbnailFileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Create Story Report
         * @param {string} storyId 
         * @param {ReportIn} reportIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoryReport(storyId: string, reportIn: ReportIn, options?: any): AxiosPromise<ReportOut> {
            return localVarFp.createStoryReport(storyId, reportIn, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> report:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Delete Report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(reportId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.deleteReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Story Photo
         * @param {string} storyId 
         * @param {ImageFileVersion} [fileVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStoryPhoto(storyId: string, fileVersion?: ImageFileVersion, options?: any): AxiosPromise<void> {
            return localVarFp.downloadStoryPhoto(storyId, fileVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Comment By Id
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentById(commentId: string, options?: any): AxiosPromise<CommentOut> {
            return localVarFp.getCommentById(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Comments
         * @param {OrderDirection} orderDirection 
         * @param {string} [storyId] Returns comments for specified story_id.
         * @param {string} [parentCommentId] Returns story first level comments if none.
         * @param {boolean} [includeHiddenComments] Return all hidden comments with comment.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(orderDirection: OrderDirection, storyId?: string, parentCommentId?: string, includeHiddenComments?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<ManyCommentOut> {
            return localVarFp.getComments(orderDirection, storyId, parentCommentId, includeHiddenComments, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Featured Stories Unauthenticated
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [forDate] Fetches the featured stories for this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedStoriesUnauthenticated(skip?: number, limit?: number, forDate?: string, options?: any): AxiosPromise<ManyMiniStoryOut> {
            return localVarFp.getFeaturedStoriesUnauthenticated(skip, limit, forDate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> report:view,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Report
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, options?: any): AxiosPromise<ReportOut> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns reports in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> report:view, me,  
         * @summary Get Reports
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {ReportStatus} [status] Searches for reports with this status.
         * @param {string} [mainReason] Searches for reports with this reason.
         * @param {string} [storyId] Searches report for this story.
         * @param {string} [readerId] Searches reports submitted by this reader.
         * @param {string} [afterDate] Searches for reports created after this date.
         * @param {string} [beforeDate] Searches for reports created before this date.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports(orderBy: OrderBy, orderDirection: OrderDirection, status?: ReportStatus, mainReason?: string, storyId?: string, readerId?: string, afterDate?: string, beforeDate?: string, skip?: number, limit?: number, options?: any): AxiosPromise<ManyReportsOut> {
            return localVarFp.getReports(orderBy, orderDirection, status, mainReason, storyId, readerId, afterDate, beforeDate, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the stories in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Stories
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {string} [title] Searches the stories with this title
         * @param {string} [slug] Searches the stories with this slug
         * @param {string} [creatorId] Fetches stories for the specified creator.
         * @param {string} [afterDate] Fetches stories starting from this date onward
         * @param {Array<string>} [topicIds] Fetches stories with these topics
         * @param {string} [beforeDate] Fetches stories before from this date downward
         * @param {string} [createdByUserId] Fetches stories for this particular user. This could be a team member.
         * @param {boolean} [returnViews] Fetches only the stories this reader has viewed
         * @param {boolean} [returnLikes] Fetches only the stories this reader has liked
         * @param {boolean} [returnShares] Fetches only the stories this reader has shared
         * @param {boolean} [returnOnlyPublished] Fetches stories with this published status
         * @param {boolean} [returnActive] Fetches stories with this active status
         * @param {boolean} [returnFeatured] Fetches featured stories.
         * @param {boolean} [returnAlertReaders] Fetches Stories that would trigger alert to the readers.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStories(orderBy: OrderBy, orderDirection: OrderDirection, title?: string, slug?: string, creatorId?: string, afterDate?: string, topicIds?: Array<string>, beforeDate?: string, createdByUserId?: string, returnViews?: boolean, returnLikes?: boolean, returnShares?: boolean, returnOnlyPublished?: boolean, returnActive?: boolean, returnFeatured?: boolean, returnAlertReaders?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<ManyStoriesOut> {
            return localVarFp.getStories(orderBy, orderDirection, title, slug, creatorId, afterDate, topicIds, beforeDate, createdByUserId, returnViews, returnLikes, returnShares, returnOnlyPublished, returnActive, returnFeatured, returnAlertReaders, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStory(storyId: string, options?: any): AxiosPromise<StoryOut> {
            return localVarFp.getStory(storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Likes Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story likes until this day.
         * @param {string} [storyId] If not provided returns all likes within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryLikesCount(startDate: string, stopDate?: string, storyId?: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.getStoryLikesCount(startDate, stopDate, storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Shares Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story shares until this day.
         * @param {string} [storyId] If not provided returns all shares within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorySharesCount(startDate: string, stopDate?: string, storyId?: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.getStorySharesCount(startDate, stopDate, storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Story Views Count
         * @param {string} startDate 
         * @param {string} [stopDate] Returns Story views until this day.
         * @param {string} [storyId] If not provided returns all views within the period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryViewsCount(startDate: string, stopDate?: string, storyId?: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.getStoryViewsCount(startDate, stopDate, storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the story that matches this slug. Please note, this endpoint does not return the full story schema
         * @summary Get Story With Slug
         * @param {string} storySlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryWithSlug(storySlug: string, options?: any): AxiosPromise<MiniStoryOut> {
            return localVarFp.getStoryWithSlug(storySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topic Comparison
         * @param {string} topicId get comparison for this topic
         * @param {number} [days] compare the last input days with its previous period.
         * @param {string} [untilDate] End date to count from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicComparison(topicId: string, days?: number, untilDate?: string, options?: any): AxiosPromise<TopicViewsComparisonOut> {
            return localVarFp.getTopicComparison(topicId, days, untilDate, options).then((request) => request(axios, basePath));
        },
        /**
         * start_date: Date to start counting from onwards. stop: Date to count to.
         * @summary Get Topic Views Count
         * @param {string} startDate 
         * @param {string} topicId Returns all story views related to this topic.
         * @param {string} [stopDate] Returns topic views until this day.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicViewsCount(startDate: string, topicId: string, stopDate?: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.getTopicViewsCount(startDate, topicId, stopDate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Hide Comment
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideComment(commentId: string, options?: any): AxiosPromise<CommentOut> {
            return localVarFp.hideComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * like a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Liked
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryLiked(storyId: string, options?: any): AxiosPromise<StoryLikedOut> {
            return localVarFp.markStoryLiked(storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Marks a story as viewed. The usr must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Read
         * @param {string} storyId 
         * @param {boolean} [updateStreak] Tries to update the reader\&#39;s streak.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryRead(storyId: string, updateStreak?: boolean, options?: any): AxiosPromise<StoryViewedOut> {
            return localVarFp.markStoryRead(storyId, updateStreak, options).then((request) => request(axios, basePath));
        },
        /**
         * shares a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Mark Story Shared
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryShared(storyId: string, options?: any): AxiosPromise<StorySharedOut> {
            return localVarFp.markStoryShared(storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Mark Story Unliked
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markStoryUnliked(storyId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.markStoryUnliked(storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Send Push Notifications To Devices
         * @param {string} storyId 
         * @param {RegistrationTokens} registrationTokens 
         * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotificationsToDevices(storyId: string, registrationTokens: RegistrationTokens, dryRun?: boolean, options?: any): AxiosPromise<StoryOut> {
            return localVarFp.sendPushNotificationsToDevices(storyId, registrationTokens, dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Send Push Notifications To Subscribed Topic Readers
         * @param {string} storyId 
         * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPushNotificationsToSubscribedTopicReaders(storyId: string, dryRun?: boolean, options?: any): AxiosPromise<StoryOut> {
            return localVarFp.sendPushNotificationsToSubscribedTopicReaders(storyId, dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Soft Delete Story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteStory(storyId: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.softDeleteStory(storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> report:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Update Report
         * @param {string} reportId 
         * @param {ReportUpdate} reportUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(reportId: string, reportUpdate: ReportUpdate, options?: any): AxiosPromise<ReportOut> {
            return localVarFp.updateReport(reportId, reportUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows a user to update a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:update, me,  
         * @summary Update Story
         * @param {string} storyId 
         * @param {string} [data] 
         * @param {File} [fileToUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStory(storyId: string, data?: string, fileToUpload?: File, options?: any): AxiosPromise<StoryOut> {
            return localVarFp.updateStory(storyId, data, fileToUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Vote Comment Down
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteCommentDown(commentId: string, options?: any): AxiosPromise<CommentOut> {
            return localVarFp.voteCommentDown(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Vote Comment Up
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voteCommentUp(commentId: string, options?: any): AxiosPromise<CommentOut> {
            return localVarFp.voteCommentUp(commentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoriesApi - object-oriented interface
 * @export
 * @class StoriesApi
 * @extends {BaseAPI}
 */
export class StoriesApi extends BaseAPI {
    /**
     * <strong>Scopes: </strong> me,
     * @summary Create Comment
     * @param {string} storyId 
     * @param {CommentIn} commentIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public createComment(storyId: string, commentIn: CommentIn, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).createComment(storyId, commentIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows a user to create a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:create, me,  
     * @summary Create Story
     * @param {string} data 
     * @param {File} fileToUpload 
     * @param {File} thumbnailFileToUpload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public createStory(data: string, fileToUpload: File, thumbnailFileToUpload: File, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).createStory(data, fileToUpload, thumbnailFileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Create Story Report
     * @param {string} storyId 
     * @param {ReportIn} reportIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public createStoryReport(storyId: string, reportIn: ReportIn, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).createStoryReport(storyId, reportIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> report:delete,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Delete Report
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public deleteReport(reportId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).deleteReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Story Photo
     * @param {string} storyId 
     * @param {ImageFileVersion} [fileVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public downloadStoryPhoto(storyId: string, fileVersion?: ImageFileVersion, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).downloadStoryPhoto(storyId, fileVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Comment By Id
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getCommentById(commentId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getCommentById(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Comments
     * @param {OrderDirection} orderDirection 
     * @param {string} [storyId] Returns comments for specified story_id.
     * @param {string} [parentCommentId] Returns story first level comments if none.
     * @param {boolean} [includeHiddenComments] Return all hidden comments with comment.
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getComments(orderDirection: OrderDirection, storyId?: string, parentCommentId?: string, includeHiddenComments?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getComments(orderDirection, storyId, parentCommentId, includeHiddenComments, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Featured Stories Unauthenticated
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [forDate] Fetches the featured stories for this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getFeaturedStoriesUnauthenticated(skip?: number, limit?: number, forDate?: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getFeaturedStoriesUnauthenticated(skip, limit, forDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> report:view,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Get Report
     * @param {string} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getReport(reportId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns reports in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> report:view, me,  
     * @summary Get Reports
     * @param {OrderBy} orderBy 
     * @param {OrderDirection} orderDirection 
     * @param {ReportStatus} [status] Searches for reports with this status.
     * @param {string} [mainReason] Searches for reports with this reason.
     * @param {string} [storyId] Searches report for this story.
     * @param {string} [readerId] Searches reports submitted by this reader.
     * @param {string} [afterDate] Searches for reports created after this date.
     * @param {string} [beforeDate] Searches for reports created before this date.
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getReports(orderBy: OrderBy, orderDirection: OrderDirection, status?: ReportStatus, mainReason?: string, storyId?: string, readerId?: string, afterDate?: string, beforeDate?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getReports(orderBy, orderDirection, status, mainReason, storyId, readerId, afterDate, beforeDate, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the stories in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Get Stories
     * @param {OrderBy} orderBy 
     * @param {OrderDirection} orderDirection 
     * @param {string} [title] Searches the stories with this title
     * @param {string} [slug] Searches the stories with this slug
     * @param {string} [creatorId] Fetches stories for the specified creator.
     * @param {string} [afterDate] Fetches stories starting from this date onward
     * @param {Array<string>} [topicIds] Fetches stories with these topics
     * @param {string} [beforeDate] Fetches stories before from this date downward
     * @param {string} [createdByUserId] Fetches stories for this particular user. This could be a team member.
     * @param {boolean} [returnViews] Fetches only the stories this reader has viewed
     * @param {boolean} [returnLikes] Fetches only the stories this reader has liked
     * @param {boolean} [returnShares] Fetches only the stories this reader has shared
     * @param {boolean} [returnOnlyPublished] Fetches stories with this published status
     * @param {boolean} [returnActive] Fetches stories with this active status
     * @param {boolean} [returnFeatured] Fetches featured stories.
     * @param {boolean} [returnAlertReaders] Fetches Stories that would trigger alert to the readers.
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getStories(orderBy: OrderBy, orderDirection: OrderDirection, title?: string, slug?: string, creatorId?: string, afterDate?: string, topicIds?: Array<string>, beforeDate?: string, createdByUserId?: string, returnViews?: boolean, returnLikes?: boolean, returnShares?: boolean, returnOnlyPublished?: boolean, returnActive?: boolean, returnFeatured?: boolean, returnAlertReaders?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getStories(orderBy, orderDirection, title, slug, creatorId, afterDate, topicIds, beforeDate, createdByUserId, returnViews, returnLikes, returnShares, returnOnlyPublished, returnActive, returnFeatured, returnAlertReaders, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Story
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getStory(storyId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getStory(storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * start_date: Date to start counting from onwards. stop: Date to count to.
     * @summary Get Story Likes Count
     * @param {string} startDate 
     * @param {string} [stopDate] Returns Story likes until this day.
     * @param {string} [storyId] If not provided returns all likes within the period.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getStoryLikesCount(startDate: string, stopDate?: string, storyId?: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getStoryLikesCount(startDate, stopDate, storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * start_date: Date to start counting from onwards. stop: Date to count to.
     * @summary Get Story Shares Count
     * @param {string} startDate 
     * @param {string} [stopDate] Returns Story shares until this day.
     * @param {string} [storyId] If not provided returns all shares within the period.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getStorySharesCount(startDate: string, stopDate?: string, storyId?: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getStorySharesCount(startDate, stopDate, storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * start_date: Date to start counting from onwards. stop: Date to count to.
     * @summary Get Story Views Count
     * @param {string} startDate 
     * @param {string} [stopDate] Returns Story views until this day.
     * @param {string} [storyId] If not provided returns all views within the period.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getStoryViewsCount(startDate: string, stopDate?: string, storyId?: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getStoryViewsCount(startDate, stopDate, storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the story that matches this slug. Please note, this endpoint does not return the full story schema
     * @summary Get Story With Slug
     * @param {string} storySlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getStoryWithSlug(storySlug: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getStoryWithSlug(storySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Topic Comparison
     * @param {string} topicId get comparison for this topic
     * @param {number} [days] compare the last input days with its previous period.
     * @param {string} [untilDate] End date to count from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getTopicComparison(topicId: string, days?: number, untilDate?: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getTopicComparison(topicId, days, untilDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * start_date: Date to start counting from onwards. stop: Date to count to.
     * @summary Get Topic Views Count
     * @param {string} startDate 
     * @param {string} topicId Returns all story views related to this topic.
     * @param {string} [stopDate] Returns topic views until this day.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public getTopicViewsCount(startDate: string, topicId: string, stopDate?: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).getTopicViewsCount(startDate, topicId, stopDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Hide Comment
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public hideComment(commentId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).hideComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * like a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Mark Story Liked
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public markStoryLiked(storyId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).markStoryLiked(storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks a story as viewed. The usr must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Mark Story Read
     * @param {string} storyId 
     * @param {boolean} [updateStreak] Tries to update the reader\&#39;s streak.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public markStoryRead(storyId: string, updateStreak?: boolean, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).markStoryRead(storyId, updateStreak, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * shares a story . The user must be a reader.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Mark Story Shared
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public markStoryShared(storyId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).markStoryShared(storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Mark Story Unliked
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public markStoryUnliked(storyId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).markStoryUnliked(storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Send Push Notifications To Devices
     * @param {string} storyId 
     * @param {RegistrationTokens} registrationTokens 
     * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public sendPushNotificationsToDevices(storyId: string, registrationTokens: RegistrationTokens, dryRun?: boolean, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).sendPushNotificationsToDevices(storyId, registrationTokens, dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> push-notifications:create,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Send Push Notifications To Subscribed Topic Readers
     * @param {string} storyId 
     * @param {boolean} [dryRun] A boolean indicating whether to run the operation in dry run mode.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public sendPushNotificationsToSubscribedTopicReaders(storyId: string, dryRun?: boolean, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).sendPushNotificationsToSubscribedTopicReaders(storyId, dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Soft Delete Story
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public softDeleteStory(storyId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).softDeleteStory(storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> report:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Update Report
     * @param {string} reportId 
     * @param {ReportUpdate} reportUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public updateReport(reportId: string, reportUpdate: ReportUpdate, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).updateReport(reportId, reportUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows a user to update a story in the system.  Request Payload ` {     title=\"Story Title\",     content_html=\"<p>Some html content</p>\",     description=\"Precise description\",     url=\"https://story-url.com\",     comments_disabled=False,     published=True,     featured=False,     alert_readers=False,     creator_id=uuid,     topics_ids=[\"uuid\", \"uuid\"] } `                                         <br />                                         <br />                                         <strong>Scopes: </strong> story:update, me,  
     * @summary Update Story
     * @param {string} storyId 
     * @param {string} [data] 
     * @param {File} [fileToUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public updateStory(storyId: string, data?: string, fileToUpload?: File, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).updateStory(storyId, data, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Vote Comment Down
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public voteCommentDown(commentId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).voteCommentDown(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Vote Comment Up
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoriesApi
     */
    public voteCommentUp(commentId: string, options?: AxiosRequestConfig) {
        return StoriesApiFp(this.configuration).voteCommentUp(commentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TopicsApi - axios parameter creator
 * @export
 */
export const TopicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:create, me,  
         * @summary Create Topic
         * @param {TopicIn} topicIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTopic: async (topicIn: TopicIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicIn' is not null or undefined
            assertParamExists('createTopic', 'topicIn', topicIn)
            const localVarPath = `/topics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topicIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Topic Image
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTopicImage: async (topicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('downloadTopicImage', 'topicId', topicId)
            const localVarPath = `/topics/{topic_id}/download-image`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topic
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopic: async (topicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('getTopic', 'topicId', topicId)
            const localVarPath = `/topics/{topic_id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the topics in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Topics
         * @param {OrderDirection} orderDirection 
         * @param {OrderBy} orderBy 
         * @param {boolean} [activeTopics] When set to false, returns all inactive topics. Note only user with the &#x60;topic:read&#x60; scope can make this request.
         * @param {Array<string>} [topicsIds] Returns only topic with these ids.
         * @param {string} [title] Searches the topics with this title
         * @param {string} [refCode] Searches the topics with this ref_code
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [returnOnlyRequiredTopics] Returns only topics required for readers. Only True is not ignored
         * @param {boolean} [visibleToReaders] Returns only visible topics when set to True and the non visible topics when set to False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopics: async (orderDirection: OrderDirection, orderBy: OrderBy, activeTopics?: boolean, topicsIds?: Array<string>, title?: string, refCode?: string, skip?: number, limit?: number, returnOnlyRequiredTopics?: boolean, visibleToReaders?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('getTopics', 'orderDirection', orderDirection)
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('getTopics', 'orderBy', orderBy)
            const localVarPath = `/topics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (activeTopics !== undefined) {
                localVarQueryParameter['active_topics'] = activeTopics;
            }

            if (topicsIds) {
                localVarQueryParameter['topics_ids'] = topicsIds;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (refCode !== undefined) {
                localVarQueryParameter['ref_code'] = refCode;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (returnOnlyRequiredTopics !== undefined) {
                localVarQueryParameter['return_only_required_topics'] = returnOnlyRequiredTopics;
            }

            if (visibleToReaders !== undefined) {
                localVarQueryParameter['visible_to_readers'] = visibleToReaders;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topics Statistics
         * @param {Array<string>} [topicsId] Returns statistics of each topic.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsStatistics: async (topicsId?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/topics/topics-statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (topicsId) {
                localVarQueryParameter['topics_id'] = topicsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows an admin to update a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:update, me,  
         * @summary Update Topic
         * @param {string} topicId 
         * @param {TopicUpdate} topicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopic: async (topicId: string, topicUpdate: TopicUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('updateTopic', 'topicId', topicId)
            // verify required parameter 'topicUpdate' is not null or undefined
            assertParamExists('updateTopic', 'topicUpdate', topicUpdate)
            const localVarPath = `/topics/{topic_id}`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topicUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> topics:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Upload Topic Image
         * @param {string} topicId 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTopicImage: async (topicId: string, fileToUpload: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('uploadTopicImage', 'topicId', topicId)
            // verify required parameter 'fileToUpload' is not null or undefined
            assertParamExists('uploadTopicImage', 'fileToUpload', fileToUpload)
            const localVarPath = `/topics/{topic_id}/upload-image`
                .replace(`{${"topic_id"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('file_to_upload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicsApi - functional programming interface
 * @export
 */
export const TopicsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopicsApiAxiosParamCreator(configuration)
    return {
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:create, me,  
         * @summary Create Topic
         * @param {TopicIn} topicIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTopic(topicIn: TopicIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTopic(topicIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Topic Image
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTopicImage(topicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTopicImage(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topic
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopic(topicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopic(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the topics in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Topics
         * @param {OrderDirection} orderDirection 
         * @param {OrderBy} orderBy 
         * @param {boolean} [activeTopics] When set to false, returns all inactive topics. Note only user with the &#x60;topic:read&#x60; scope can make this request.
         * @param {Array<string>} [topicsIds] Returns only topic with these ids.
         * @param {string} [title] Searches the topics with this title
         * @param {string} [refCode] Searches the topics with this ref_code
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [returnOnlyRequiredTopics] Returns only topics required for readers. Only True is not ignored
         * @param {boolean} [visibleToReaders] Returns only visible topics when set to True and the non visible topics when set to False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopics(orderDirection: OrderDirection, orderBy: OrderBy, activeTopics?: boolean, topicsIds?: Array<string>, title?: string, refCode?: string, skip?: number, limit?: number, returnOnlyRequiredTopics?: boolean, visibleToReaders?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyTopicsOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopics(orderDirection, orderBy, activeTopics, topicsIds, title, refCode, skip, limit, returnOnlyRequiredTopics, visibleToReaders, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topics Statistics
         * @param {Array<string>} [topicsId] Returns statistics of each topic.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicsStatistics(topicsId?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyTopicsStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicsStatistics(topicsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows an admin to update a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:update, me,  
         * @summary Update Topic
         * @param {string} topicId 
         * @param {TopicUpdate} topicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTopic(topicId: string, topicUpdate: TopicUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTopic(topicId, topicUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> topics:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Upload Topic Image
         * @param {string} topicId 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTopicImage(topicId: string, fileToUpload: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTopicImage(topicId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopicsApi - factory interface
 * @export
 */
export const TopicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopicsApiFp(configuration)
    return {
        /**
         * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:create, me,  
         * @summary Create Topic
         * @param {TopicIn} topicIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTopic(topicIn: TopicIn, options?: any): AxiosPromise<TopicOut> {
            return localVarFp.createTopic(topicIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Topic Image
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTopicImage(topicId: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadTopicImage(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topic
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopic(topicId: string, options?: any): AxiosPromise<TopicOut> {
            return localVarFp.getTopic(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the topics in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Topics
         * @param {OrderDirection} orderDirection 
         * @param {OrderBy} orderBy 
         * @param {boolean} [activeTopics] When set to false, returns all inactive topics. Note only user with the &#x60;topic:read&#x60; scope can make this request.
         * @param {Array<string>} [topicsIds] Returns only topic with these ids.
         * @param {string} [title] Searches the topics with this title
         * @param {string} [refCode] Searches the topics with this ref_code
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [returnOnlyRequiredTopics] Returns only topics required for readers. Only True is not ignored
         * @param {boolean} [visibleToReaders] Returns only visible topics when set to True and the non visible topics when set to False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopics(orderDirection: OrderDirection, orderBy: OrderBy, activeTopics?: boolean, topicsIds?: Array<string>, title?: string, refCode?: string, skip?: number, limit?: number, returnOnlyRequiredTopics?: boolean, visibleToReaders?: boolean, options?: any): AxiosPromise<ManyTopicsOut> {
            return localVarFp.getTopics(orderDirection, orderBy, activeTopics, topicsIds, title, refCode, skip, limit, returnOnlyRequiredTopics, visibleToReaders, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get Topics Statistics
         * @param {Array<string>} [topicsId] Returns statistics of each topic.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsStatistics(topicsId?: Array<string>, options?: any): AxiosPromise<ManyTopicsStatistics> {
            return localVarFp.getTopicsStatistics(topicsId, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows an admin to update a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:update, me,  
         * @summary Update Topic
         * @param {string} topicId 
         * @param {TopicUpdate} topicUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTopic(topicId: string, topicUpdate: TopicUpdate, options?: any): AxiosPromise<TopicOut> {
            return localVarFp.updateTopic(topicId, topicUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> topics:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Upload Topic Image
         * @param {string} topicId 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTopicImage(topicId: string, fileToUpload: File, options?: any): AxiosPromise<TopicOut> {
            return localVarFp.uploadTopicImage(topicId, fileToUpload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TopicsApi - object-oriented interface
 * @export
 * @class TopicsApi
 * @extends {BaseAPI}
 */
export class TopicsApi extends BaseAPI {
    /**
     * Allows an admin to create a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:create, me,  
     * @summary Create Topic
     * @param {TopicIn} topicIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApi
     */
    public createTopic(topicIn: TopicIn, options?: AxiosRequestConfig) {
        return TopicsApiFp(this.configuration).createTopic(topicIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Topic Image
     * @param {string} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApi
     */
    public downloadTopicImage(topicId: string, options?: AxiosRequestConfig) {
        return TopicsApiFp(this.configuration).downloadTopicImage(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Topic
     * @param {string} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApi
     */
    public getTopic(topicId: string, options?: AxiosRequestConfig) {
        return TopicsApiFp(this.configuration).getTopic(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the topics in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Get Topics
     * @param {OrderDirection} orderDirection 
     * @param {OrderBy} orderBy 
     * @param {boolean} [activeTopics] When set to false, returns all inactive topics. Note only user with the &#x60;topic:read&#x60; scope can make this request.
     * @param {Array<string>} [topicsIds] Returns only topic with these ids.
     * @param {string} [title] Searches the topics with this title
     * @param {string} [refCode] Searches the topics with this ref_code
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [returnOnlyRequiredTopics] Returns only topics required for readers. Only True is not ignored
     * @param {boolean} [visibleToReaders] Returns only visible topics when set to True and the non visible topics when set to False
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApi
     */
    public getTopics(orderDirection: OrderDirection, orderBy: OrderBy, activeTopics?: boolean, topicsIds?: Array<string>, title?: string, refCode?: string, skip?: number, limit?: number, returnOnlyRequiredTopics?: boolean, visibleToReaders?: boolean, options?: AxiosRequestConfig) {
        return TopicsApiFp(this.configuration).getTopics(orderDirection, orderBy, activeTopics, topicsIds, title, refCode, skip, limit, returnOnlyRequiredTopics, visibleToReaders, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get Topics Statistics
     * @param {Array<string>} [topicsId] Returns statistics of each topic.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApi
     */
    public getTopicsStatistics(topicsId?: Array<string>, options?: AxiosRequestConfig) {
        return TopicsApiFp(this.configuration).getTopicsStatistics(topicsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows an admin to update a topic in the system.                                         <br />                                         <br />                                         <strong>Scopes: </strong> topics:update, me,  
     * @summary Update Topic
     * @param {string} topicId 
     * @param {TopicUpdate} topicUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApi
     */
    public updateTopic(topicId: string, topicUpdate: TopicUpdate, options?: AxiosRequestConfig) {
        return TopicsApiFp(this.configuration).updateTopic(topicId, topicUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> topics:update,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Upload Topic Image
     * @param {string} topicId 
     * @param {File} fileToUpload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicsApi
     */
    public uploadTopicImage(topicId: string, fileToUpload: File, options?: AxiosRequestConfig) {
        return TopicsApiFp(this.configuration).uploadTopicImage(topicId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Admin Change User Password
         * @param {string} userId 
         * @param {ChangePassword} changePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChangeUserPassword: async (userId: string, changePassword: ChangePassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminChangeUserPassword', 'userId', userId)
            // verify required parameter 'changePassword' is not null or undefined
            assertParamExists('adminChangeUserPassword', 'changePassword', changePassword)
            const localVarPath = `/users/{user_id}/admin-change-user-password`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint will also set the user account to active.
         * @summary Change User Password
         * @param {ChangePasswordWithToken} changePasswordWithToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (changePasswordWithToken: ChangePasswordWithToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordWithToken' is not null or undefined
            assertParamExists('changeUserPassword', 'changePasswordWithToken', changePasswordWithToken)
            const localVarPath = `/users/change-user-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordWithToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows a user to create another user in the system. The user is made active if the correct admin-signup-token or reader-sign-up-token is provided, and no email will be sent to the user. The endpoint does not send an email to anonymous user.
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {string} [adminSignupToken] The correct admin token to use admin only features. If this is provided, an email will not be sent to the user.
         * @param {string} [readerSignupToken] Provide the correct value to set the is_anonymous of the reader to true, else the user\&#39;s active status will be set to false and is_anonymous to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreate: UserCreate, referralToken?: string, adminSignupToken?: string, readerSignupToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('createUser', 'userCreate', userCreate)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referralToken !== undefined) {
                localVarQueryParameter['referral_token'] = referralToken;
            }

            if (adminSignupToken != null) {
                localVarHeaderParameter['admin-signup-token'] = String(adminSignupToken);
            }

            if (readerSignupToken != null) {
                localVarHeaderParameter['reader-signup-token'] = String(readerSignupToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download User Photo
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserPhoto: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('downloadUserPhoto', 'userId', userId)
            const localVarPath = `/users/{user_id}/download-photo`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Active Readers Stats
         * @param {number} [minPostViews] Minimum views to be an active user
         * @param {number} [minStoryLikes] Minimum stories likes to be an active user
         * @param {number} [minShares] Minimum views to be an active user
         * @param {string} [startDate] last activities to be an active user
         * @param {string} [endDate] latest activities to be an active user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveReadersStats: async (minPostViews?: number, minStoryLikes?: number, minShares?: number, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/active_readers_stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (minPostViews !== undefined) {
                localVarQueryParameter['min_post_views'] = minPostViews;
            }

            if (minStoryLikes !== undefined) {
                localVarQueryParameter['min_story_likes'] = minStoryLikes;
            }

            if (minShares !== undefined) {
                localVarQueryParameter['min_shares'] = minShares;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get User Local Time
         * @param {string} [myDatetime] Provide a datetime to convert or None to return the users current local datetime.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalTime: async (myDatetime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/local-datetime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (myDatetime !== undefined) {
                localVarQueryParameter['my_datetime'] = (myDatetime as any instanceof Date) ?
                    (myDatetime as any).toISOString() :
                    myDatetime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary List Scopes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScopes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/list-scopes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me", "me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Vyse Account for the guest user (requesting user). The access token returned is that of the new user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Migrate Guest To Vyse User
         * @param {MigrateGuestUser} migrateGuestUser 
         * @param {boolean} [usedGoogleOrAppleSignIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateGuestToVyseUser: async (migrateGuestUser: MigrateGuestUser, usedGoogleOrAppleSignIn?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrateGuestUser' is not null or undefined
            assertParamExists('migrateGuestToVyseUser', 'migrateGuestUser', migrateGuestUser)
            const localVarPath = `/users/migrate-to-vyse-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (usedGoogleOrAppleSignIn !== undefined) {
                localVarQueryParameter['used_google_or_apple_sign_in'] = usedGoogleOrAppleSignIn;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateGuestUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> accountdeletionfeedback:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Accounts Deletion Feedbacks
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [reason] Searches for feedbacks with this reason.
         * @param {string} [beforeDate] Returns feedbacks submitted before this date.
         * @param {string} [afterDate] Returns feedbacks submitted after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccountsDeletionFeedbacks: async (skip?: number, limit?: number, reason?: string, beforeDate?: string, afterDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/account_deletion_feedbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }

            if (beforeDate !== undefined) {
                localVarQueryParameter['before_date'] = (beforeDate as any instanceof Date) ?
                    (beforeDate as any).toISOString().substring(0,10) :
                    beforeDate;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['after_date'] = (afterDate as any instanceof Date) ?
                    (afterDate as any).toISOString().substring(0,10) :
                    afterDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Read User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('readUser', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsers: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> userpushnotificationtoken:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Users Push Notifications Token
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {string} [userId] Searches for push notification that belongs to this user.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersPushNotificationsToken: async (orderBy: OrderBy, orderDirection: OrderDirection, userId?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('readUsersPushNotificationsToken', 'orderBy', orderBy)
            // verify required parameter 'orderDirection' is not null or undefined
            assertParamExists('readUsersPushNotificationsToken', 'orderDirection', orderDirection)
            const localVarPath = `/users/user-push-notification-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request Password Change
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordChange: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('requestPasswordChange', 'email', email)
            const localVarPath = `/users/request-password-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Store Push Notification Token
         * @param {string} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePushNotificationToken: async (userId: string, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('storePushNotificationToken', 'userId', userId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('storePushNotificationToken', 'token', token)
            const localVarPath = `/users/{user_id}/user_notification_token`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update User
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, userUpdate: UserUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('updateUser', 'userUpdate', userUpdate)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Users With Missing Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsersWithMissingToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/update-readers-with-missing-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Upload User Photo
         * @param {string} userId 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUserPhoto: async (userId: string, fileToUpload: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadUserPhoto', 'userId', userId)
            // verify required parameter 'fileToUpload' is not null or undefined
            assertParamExists('uploadUserPhoto', 'fileToUpload', fileToUpload)
            const localVarPath = `/users/{user_id}/upload-photo`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", ["me"], configuration)


            if (fileToUpload !== undefined) { 
                localVarFormParams.append('file_to_upload', fileToUpload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Admin Change User Password
         * @param {string} userId 
         * @param {ChangePassword} changePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminChangeUserPassword(userId: string, changePassword: ChangePassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminChangeUserPassword(userId, changePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint will also set the user account to active.
         * @summary Change User Password
         * @param {ChangePasswordWithToken} changePasswordWithToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(changePasswordWithToken: ChangePasswordWithToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(changePasswordWithToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows a user to create another user in the system. The user is made active if the correct admin-signup-token or reader-sign-up-token is provided, and no email will be sent to the user. The endpoint does not send an email to anonymous user.
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {string} [adminSignupToken] The correct admin token to use admin only features. If this is provided, an email will not be sent to the user.
         * @param {string} [readerSignupToken] Provide the correct value to set the is_anonymous of the reader to true, else the user\&#39;s active status will be set to false and is_anonymous to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreate: UserCreate, referralToken?: string, adminSignupToken?: string, readerSignupToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreate, referralToken, adminSignupToken, readerSignupToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download User Photo
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUserPhoto(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUserPhoto(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Active Readers Stats
         * @param {number} [minPostViews] Minimum views to be an active user
         * @param {number} [minStoryLikes] Minimum stories likes to be an active user
         * @param {number} [minShares] Minimum views to be an active user
         * @param {string} [startDate] last activities to be an active user
         * @param {string} [endDate] latest activities to be an active user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveReadersStats(minPostViews?: number, minStoryLikes?: number, minShares?: number, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveReadersStats(minPostViews, minStoryLikes, minShares, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get User Local Time
         * @param {string} [myDatetime] Provide a datetime to convert or None to return the users current local datetime.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLocalTime(myDatetime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDatetimeOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLocalTime(myDatetime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary List Scopes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScopes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManySystemScopeOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScopes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Vyse Account for the guest user (requesting user). The access token returned is that of the new user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Migrate Guest To Vyse User
         * @param {MigrateGuestUser} migrateGuestUser 
         * @param {boolean} [usedGoogleOrAppleSignIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateGuestToVyseUser(migrateGuestUser: MigrateGuestUser, usedGoogleOrAppleSignIn?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateGuestToVyseUser(migrateGuestUser, usedGoogleOrAppleSignIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> accountdeletionfeedback:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Accounts Deletion Feedbacks
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [reason] Searches for feedbacks with this reason.
         * @param {string} [beforeDate] Returns feedbacks submitted before this date.
         * @param {string} [afterDate] Returns feedbacks submitted after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAccountsDeletionFeedbacks(skip?: number, limit?: number, reason?: string, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyAccountDeletionFeedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAccountsDeletionFeedbacks(skip, limit, reason, beforeDate, afterDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Read User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsers(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyUsersInDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsers(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> userpushnotificationtoken:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Users Push Notifications Token
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {string} [userId] Searches for push notification that belongs to this user.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersPushNotificationsToken(orderBy: OrderBy, orderDirection: OrderDirection, userId?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManyUserPushNotificationToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersPushNotificationsToken(orderBy, orderDirection, userId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request Password Change
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPasswordChange(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPasswordChange(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Store Push Notification Token
         * @param {string} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storePushNotificationToken(userId: string, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPushNotificationTokenOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storePushNotificationToken(userId, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update User
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, userUpdate: UserUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Users With Missing Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUsersWithMissingToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUsersWithMissingToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Upload User Photo
         * @param {string} userId 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUserPhoto(userId: string, fileToUpload: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUserPhoto(userId, fileToUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * <strong>Scopes: </strong> me,
         * @summary Admin Change User Password
         * @param {string} userId 
         * @param {ChangePassword} changePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminChangeUserPassword(userId: string, changePassword: ChangePassword, options?: any): AxiosPromise<UserOut> {
            return localVarFp.adminChangeUserPassword(userId, changePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint will also set the user account to active.
         * @summary Change User Password
         * @param {ChangePasswordWithToken} changePasswordWithToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(changePasswordWithToken: ChangePasswordWithToken, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.changeUserPassword(changePasswordWithToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows a user to create another user in the system. The user is made active if the correct admin-signup-token or reader-sign-up-token is provided, and no email will be sent to the user. The endpoint does not send an email to anonymous user.
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {string} [referralToken] Create reader with this referral token.
         * @param {string} [adminSignupToken] The correct admin token to use admin only features. If this is provided, an email will not be sent to the user.
         * @param {string} [readerSignupToken] Provide the correct value to set the is_anonymous of the reader to true, else the user\&#39;s active status will be set to false and is_anonymous to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreate: UserCreate, referralToken?: string, adminSignupToken?: string, readerSignupToken?: string, options?: any): AxiosPromise<UserOut> {
            return localVarFp.createUser(userCreate, referralToken, adminSignupToken, readerSignupToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download User Photo
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUserPhoto(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadUserPhoto(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Get Active Readers Stats
         * @param {number} [minPostViews] Minimum views to be an active user
         * @param {number} [minStoryLikes] Minimum stories likes to be an active user
         * @param {number} [minShares] Minimum views to be an active user
         * @param {string} [startDate] last activities to be an active user
         * @param {string} [endDate] latest activities to be an active user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveReadersStats(minPostViews?: number, minStoryLikes?: number, minShares?: number, startDate?: string, endDate?: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.getActiveReadersStats(minPostViews, minStoryLikes, minShares, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Get User Local Time
         * @param {string} [myDatetime] Provide a datetime to convert or None to return the users current local datetime.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLocalTime(myDatetime?: string, options?: any): AxiosPromise<UserDatetimeOut> {
            return localVarFp.getUserLocalTime(myDatetime, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary List Scopes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScopes(options?: any): AxiosPromise<ManySystemScopeOut> {
            return localVarFp.listScopes(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Vyse Account for the guest user (requesting user). The access token returned is that of the new user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Migrate Guest To Vyse User
         * @param {MigrateGuestUser} migrateGuestUser 
         * @param {boolean} [usedGoogleOrAppleSignIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateGuestToVyseUser(migrateGuestUser: MigrateGuestUser, usedGoogleOrAppleSignIn?: boolean, options?: any): AxiosPromise<AccessToken> {
            return localVarFp.migrateGuestToVyseUser(migrateGuestUser, usedGoogleOrAppleSignIn, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> accountdeletionfeedback:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Accounts Deletion Feedbacks
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [reason] Searches for feedbacks with this reason.
         * @param {string} [beforeDate] Returns feedbacks submitted before this date.
         * @param {string} [afterDate] Returns feedbacks submitted after this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccountsDeletionFeedbacks(skip?: number, limit?: number, reason?: string, beforeDate?: string, afterDate?: string, options?: any): AxiosPromise<ManyAccountDeletionFeedback> {
            return localVarFp.readAccountsDeletionFeedbacks(skip, limit, reason, beforeDate, afterDate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Read User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUser(userId: string, options?: any): AxiosPromise<UserOut> {
            return localVarFp.readUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMe(options?: any): AxiosPromise<UserOut> {
            return localVarFp.readUserMe(options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsers(skip?: number, limit?: number, options?: any): AxiosPromise<ManyUsersInDB> {
            return localVarFp.readUsers(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> userpushnotificationtoken:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
         * @summary Read Users Push Notifications Token
         * @param {OrderBy} orderBy 
         * @param {OrderDirection} orderDirection 
         * @param {string} [userId] Searches for push notification that belongs to this user.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersPushNotificationsToken(orderBy: OrderBy, orderDirection: OrderDirection, userId?: string, skip?: number, limit?: number, options?: any): AxiosPromise<ManyUserPushNotificationToken> {
            return localVarFp.readUsersPushNotificationsToken(orderBy, orderDirection, userId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request Password Change
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordChange(email: string, options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.requestPasswordChange(email, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Store Push Notification Token
         * @param {string} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePushNotificationToken(userId: string, token: string, options?: any): AxiosPromise<UserPushNotificationTokenOut> {
            return localVarFp.storePushNotificationToken(userId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update User
         * @param {string} userId 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, userUpdate: UserUpdate, options?: any): AxiosPromise<UserOut> {
            return localVarFp.updateUser(userId, userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Update Users With Missing Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsersWithMissingToken(options?: any): AxiosPromise<AppResponseModel> {
            return localVarFp.updateUsersWithMissingToken(options).then((request) => request(axios, basePath));
        },
        /**
         * <strong>Scopes: </strong> me,
         * @summary Upload User Photo
         * @param {string} userId 
         * @param {File} fileToUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUserPhoto(userId: string, fileToUpload: File, options?: any): AxiosPromise<ProfileOut> {
            return localVarFp.uploadUserPhoto(userId, fileToUpload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * <strong>Scopes: </strong> me,
     * @summary Admin Change User Password
     * @param {string} userId 
     * @param {ChangePassword} changePassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public adminChangeUserPassword(userId: string, changePassword: ChangePassword, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).adminChangeUserPassword(userId, changePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint will also set the user account to active.
     * @summary Change User Password
     * @param {ChangePasswordWithToken} changePasswordWithToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeUserPassword(changePasswordWithToken: ChangePasswordWithToken, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeUserPassword(changePasswordWithToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows a user to create another user in the system. The user is made active if the correct admin-signup-token or reader-sign-up-token is provided, and no email will be sent to the user. The endpoint does not send an email to anonymous user.
     * @summary Create User
     * @param {UserCreate} userCreate 
     * @param {string} [referralToken] Create reader with this referral token.
     * @param {string} [adminSignupToken] The correct admin token to use admin only features. If this is provided, an email will not be sent to the user.
     * @param {string} [readerSignupToken] Provide the correct value to set the is_anonymous of the reader to true, else the user\&#39;s active status will be set to false and is_anonymous to false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(userCreate: UserCreate, referralToken?: string, adminSignupToken?: string, readerSignupToken?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(userCreate, referralToken, adminSignupToken, readerSignupToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download User Photo
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public downloadUserPhoto(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).downloadUserPhoto(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Get Active Readers Stats
     * @param {number} [minPostViews] Minimum views to be an active user
     * @param {number} [minStoryLikes] Minimum stories likes to be an active user
     * @param {number} [minShares] Minimum views to be an active user
     * @param {string} [startDate] last activities to be an active user
     * @param {string} [endDate] latest activities to be an active user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getActiveReadersStats(minPostViews?: number, minStoryLikes?: number, minShares?: number, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getActiveReadersStats(minPostViews, minStoryLikes, minShares, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Get User Local Time
     * @param {string} [myDatetime] Provide a datetime to convert or None to return the users current local datetime.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserLocalTime(myDatetime?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserLocalTime(myDatetime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary List Scopes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listScopes(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listScopes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Vyse Account for the guest user (requesting user). The access token returned is that of the new user.                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Migrate Guest To Vyse User
     * @param {MigrateGuestUser} migrateGuestUser 
     * @param {boolean} [usedGoogleOrAppleSignIn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public migrateGuestToVyseUser(migrateGuestUser: MigrateGuestUser, usedGoogleOrAppleSignIn?: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).migrateGuestToVyseUser(migrateGuestUser, usedGoogleOrAppleSignIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> accountdeletionfeedback:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Read Accounts Deletion Feedbacks
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [reason] Searches for feedbacks with this reason.
     * @param {string} [beforeDate] Returns feedbacks submitted before this date.
     * @param {string} [afterDate] Returns feedbacks submitted after this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readAccountsDeletionFeedbacks(skip?: number, limit?: number, reason?: string, beforeDate?: string, afterDate?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readAccountsDeletionFeedbacks(skip, limit, reason, beforeDate, afterDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Read User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Read User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readUserMe(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readUserMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> user:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Read Users
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readUsers(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readUsers(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> userpushnotificationtoken:read,                                         <br />                                         <br />                                         <strong>Scopes: </strong> me, 
     * @summary Read Users Push Notifications Token
     * @param {OrderBy} orderBy 
     * @param {OrderDirection} orderDirection 
     * @param {string} [userId] Searches for push notification that belongs to this user.
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readUsersPushNotificationsToken(orderBy: OrderBy, orderDirection: OrderDirection, userId?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readUsersPushNotificationsToken(orderBy, orderDirection, userId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request Password Change
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public requestPasswordChange(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).requestPasswordChange(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Store Push Notification Token
     * @param {string} userId 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public storePushNotificationToken(userId: string, token: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).storePushNotificationToken(userId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Update User
     * @param {string} userId 
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, userUpdate: UserUpdate, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userId, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Update Users With Missing Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUsersWithMissingToken(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUsersWithMissingToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <strong>Scopes: </strong> me,
     * @summary Upload User Photo
     * @param {string} userId 
     * @param {File} fileToUpload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public uploadUserPhoto(userId: string, fileToUpload: File, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).uploadUserPhoto(userId, fileToUpload, options).then((request) => request(this.axios, this.basePath));
    }
}



