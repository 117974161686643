import PostLogin from "../../layout/post-login";
import TopicsList from "../../components/topic-list";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { getSingleReader } from "../../store/reader";
import { Link, useParams } from "react-router-dom";
import { formatDate, getLastSeen } from "../../utils/functions/formatDate";
import EmptyState from "../../components/emptyState";

import { apiUrl } from "../../utils/config";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ReadersDetails() {
  const params = useParams();
  const reader = useSelector((state) => state.readers.singleReader);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Reader Details - Admin Vyse News";
    dispatch(getSingleReader({ id: params.readerId }));
  }, [dispatch, params]);

  return (
    <PostLogin>
      {Object.keys(reader).length !== 0 && (
        <>
          <header>
            <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-[#e6e6f0] px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 border-b border-[#c4c4c4] items-center justify-center">
              <div>
                <div className="flex items-center gap-x-3">
                  <h1 className="flex gap-x-3 text-base leading-7">
                    <span className="font-semibold text-[#101828]">Reader</span>
                    <span className="text-gray-600">/</span>
                    <span className="font-semibold text-[#101828]">
                      Details
                    </span>
                  </h1>
                </div>
                <p className="mt-2 text-xs leading-6 text-gray-600">
                  Reader's personal information and topics
                </p>
              </div>
              <div className="cursor-pointer order-first flex-none rounded-full bg-[#000066]/10 px-2 py-1 text-xs font-medium text-[#000066] ring-1 ring-inset ring-[#000066]/30 sm:order-none">
                <Link to={"/users/details/" + reader.user.id}>View User</Link>
              </div>
            </div>

            {/* Stats */}

            <div className="grid grid-cols-2 bg-[#e6e6f0] lg:grid-cols-4">
              <div
                className={classNames(
                  "col-span-2 flex items-center border-r border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8 gap-x-4"
                )}
              >
                <img
                  className="h-20 w-20 flex-none rounded-full bg-gray-50"
                  src={
                    reader.user?.profile?.photo_file
                      ? `${apiUrl}/users/${reader.user.id}/download-photo`
                      : reader.user?.profile?.avatar_url
                  }
                  alt=""
                />
                <div className="min-w-0">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {reader.user.profile.first_name_raw}{" "}
                    {reader.user.profile.last_name_raw}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {reader.nickname ? reader.nickname : reader.user.email}
                  </p>
                </div>
              </div>

              <div
                className={classNames(
                  "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Date joined
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {formatDate(reader.user.access_begin)}
                  </span>
                </p>
              </div>
              <div
                className={classNames(
                  "border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Last seen
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {getLastSeen(reader.user.last_login)}
                  </span>
                </p>
              </div>
            </div>
          </header>

          {/* Activity list */}
          <div className="mt-16">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-lg">Reader's Topics</h2>
            </div>
            {reader?.reader_topics.length ? (
              <TopicsList topics={reader?.reader_topics} reader />
            ) : (
              <EmptyState StateText="topic" />
            )}
          </div>
        </>
      )}
    </PostLogin>
  );
}
