import React, { useEffect, useRef, useState } from "react";
import PostLogin from "../../layout/post-login";
import Pagination from "../../components/new-pagination";
import CreatorsTable from "../../components/creators/creators-table";
import { useDispatch, useSelector } from "react-redux";
import { getAllCreators, getInActiveCreators } from "../../store/creator";
import EmptySate from "../../components/emptyState";
import CreatorsStatCard from "../../components/creators/creators-stat";
import AddNewCreator from "../../components/creators/new-creator-form";
import InActiveCreatorList from "../../components/creators/inactive";
import { usePrevious } from "../../utils/functions/util";
import { togglePageLoader } from "../../components/loader";

export default function AllCreators() {
  const dispatch = useDispatch();
  const allCreators = useSelector((state) => state.creators.allCreators);
  const totalCreators = useSelector((state) => state.creators.totalCreators);
  const inActiveCreators = useSelector(
    (state) => state.creators.inActiveCreators
  );
  const totalInActiveCreators = useSelector(
    (state) => state.creators.totalInActiveCreators
  );
  const addCreatorStatus = useSelector(
    (state) => state.creators.addCreatorStatus
  );
  const creatorUpdateStatus = useSelector(
    (state) => state.creators.creatorUpdateStatus
  );

  const [range, setRange] = useState({ from: 0, to: 10 });
  const addCreatorRef = useRef(" ");

  useEffect(() => {
    togglePageLoader();
    document.title = "Creators - Admin Vyse News";
    dispatch(getAllCreators({ ...range, orderDirection: "DESC" }));
    dispatch(getInActiveCreators({ ...range, orderDirection: "DESC" }));
    addCreatorStatus === 200 &&
      dispatch(getAllCreators({ ...range, orderDirection: "DESC" }));
    togglePageLoader();
  }, [dispatch, range, addCreatorStatus]);

  const prevStatus = usePrevious(creatorUpdateStatus);
  const creatorUpdated = creatorUpdateStatus !== prevStatus;
  useEffect(() => {
    togglePageLoader();
    if (creatorUpdated) {
      dispatch(getAllCreators({ from: 0, to: 10, orderDirection: "DESC" }));
      dispatch(
        getInActiveCreators({ from: 0, to: 10, orderDirection: "DESC" })
      );
    }
    togglePageLoader();
  }, [dispatch, creatorUpdated]);

  const inactivePubModalRef = useRef("");

  return (
    <PostLogin>
      {totalCreators === 0 ? (
        <EmptySate StateText={"No creator found"} />
      ) : (
        <>
          <InActiveCreatorList
            ref={inactivePubModalRef}
            InActiveCreators={inActiveCreators}
          />
          <AddNewCreator ref={addCreatorRef} />

          <div className=" pb-8 sm:py-11  sm:px-10">
            <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
              Publishers
            </h3>
            <CreatorsStatCard
              openInactivePubModal={() =>
                inactivePubModalRef.current.toggleModal(true)
              }
              stat1={totalCreators + totalInActiveCreators}
              stat2={totalCreators}
              stat3={totalInActiveCreators}
            />

            <div className="bg-white border border-[#EAECF0] rounded-lg shadow-sm mt-12">
              <div className="w-full">
                <CreatorsTable
                  tableData={allCreators}
                  addCreatorHandle={() => addCreatorRef.current.toggleModal()}
                />
              </div>

              <Pagination
                totalItems={totalCreators}
                itemsOnDisplay={allCreators.length}
                range={range}
                setRange={setRange}
                tableName="creators"
              />
            </div>
          </div>
        </>
      )}
    </PostLogin>
  );
}
