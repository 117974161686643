import axios from "axios";
import { apiUrl } from "../utils/config";

const token = localStorage.getItem("token");

let http = axios.create({
  baseURL: `${apiUrl}`,
});

http.interceptors.request.use(
  function (config) {
    config.headers.Authorization = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "";
    }
    return Promise.reject(error);
  }
);

export { http as axios };
