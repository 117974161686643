import {
  BookOpenIcon,
  PencilSquareIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function UserStatCard({ stat1 = 0, stat2 = 0, stat3 = 0 }) {
  const [time, setTime] = useState();
  const [month, setMonth] = useState();
  const [date, setDate] = useState();
  const [year, setYear] = useState();
  const stats = [
    {
      id: 1,
      name: "Total Users",
      stat: stat1,
      icon: UsersIcon,
    },
    {
      id: 2,
      name: "Total Readers",
      stat: stat2,
      icon: BookOpenIcon,
      href: "/readers",
    },
    {
      id: 3,
      name: "Total Publishers",
      stat: stat3,
      icon: PencilSquareIcon,
      href: "/publishers",
    },
  ];

  useEffect(() => {
    const refreshTime = setInterval(() => {
      let today = new Date();
      setTime(
        today.toLocaleString(
          [],
          { hour: "2-digit", minute: "2-digit", second: "2-digit" },
          { hour12: true }
        )
      );
      setMonth(
        today.toLocaleString("en-US", {
          month: "short",
        })
      );
      setDate(
        today.toLocaleString("en-US", {
          day: "2-digit",
        })
      );
      setYear(today.getFullYear());
    }, 1000);
    return () => clearInterval(refreshTime);
  }, []);

  return (
    <div>
      <div className="mt-5 flex flex-wrap gap-5 w-full flex-col-reverse sm:flex-row">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-[#030366] px-4 pb-12 pt-5 shadow sm:px-4 sm:pt-6 w-full sm:w-[40%] lg:w-[27%]"
          >
            <div>
              <div className="absolute rounded-md bg-[#fff] p-3">
                <item.icon
                  className="h-6 w-6 text-[#030366]"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-white">
                {item.name}
              </p>
            </div>
            <div className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p
                className="text-2xl font-semibold text-white"
                data-testid="stat"
              >
                {item.stat}
              </p>
              {item.href && (
                <div className="absolute inset-x-0 bottom-0 bg-[#cccce0] px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <Link
                      to={item.href}
                      className="font-medium text-[#030366] hover:text-[#030366]"
                    >
                      View all
                      <span className="sr-only"> {item.name} stats</span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="rounded-lg w-200px sm:w-[20%] lg:w-[11%] bg-[#cccce0] flex flex-row sm:flex-col justify-center items-center gap-y-1.5 gap-x-1.5 p-1 ring-1 ring-[#030366]">
          <p className="font-extrabold text-xl text-[#030366]">{date}</p>
          <p className="font-semibold text-xs text-[#030366]">
            {month?.toUpperCase()}
          </p>
          <p className="font-semibold text-xs text-[#030366]">{year}</p>
          <div className="border-r-2 border-gray-900 h-4 w-1"></div>
          <p className="font-semibold text-xs text-[#030366]">{time}</p>
        </div>
      </div>
    </div>
  );
}
