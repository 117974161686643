import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UsersApiFactory, Configuration } from "vyse-api-client";
import { axios } from "../utils/http";
import { apiUrl } from "../utils/config";
import { togglePageLoader } from "../components/loader";

const config = new Configuration({
  basePath: apiUrl,
});

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (payload) => {
    try {
      let response = await UsersApiFactory(config, undefined, axios).readUsers(
        payload.range.from,
        payload.range.to
      );
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getUser = createAsyncThunk("users/getUsers", async (payload) => {
  try {
    let response = await UsersApiFactory(config, undefined, axios).readUser(
      payload.id
    );
    return response.data;
  } catch (error) {
    if (!error.response?.data?.detail) {
      // throw error;
    }
  }
});

export const requestPasswordChange = createAsyncThunk(
  "users/requestPasswordChange",
  async (payload) => {
    try {
      let response = await UsersApiFactory(
        config,
        undefined,
        axios
      ).requestPasswordChange(payload.email);
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const toggleUserActiveStatus = createAsyncThunk(
  "User/toggleUserActiveStatus",
  async (payload) => {
    try {
      let response = await UsersApiFactory(config, undefined, axios).updateUser(
        payload.userId,
        payload.userUpdate
      );

      return response.status;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
      return !error.response?.data?.detail;
    }
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState: {
    allUsers: [],
    singleUser: {},
    totalUsers: 0,
    userPhoto: {},
    requestNewPassword: false,
    userAcitveStatus: null,
  },
  extraReducers: (builder) => {
    // builder.addCase(getAllUsers.pending, togglePageLoader);
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload.data;
      state.totalUsers = action.payload.total;
      // togglePageLoader();
    });

    builder.addCase(getUser.pending, togglePageLoader);
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.singleUser = action.payload;
      togglePageLoader();
    });

    builder.addCase(requestPasswordChange.pending, togglePageLoader);
    builder.addCase(requestPasswordChange.fulfilled, (state, action) => {
      state.requestNewPassword = action.payload.detail;
      togglePageLoader();
    });
  },
});

export default userSlice.reducer;
