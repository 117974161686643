import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { formatDate } from "../utils/functions/formatDate";
import {
  convertUnicodeToReference,
  usePrevious,
} from "../utils/functions/util";
import { useSelector } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TopicsList({
  topics,
  handleRemoveModal,
  handleUpdateTopic,
  reader,
}) {
  const creatorUpdateStatus = useSelector(
    (state) => state.creators.creatorUpdateStatus
  );

  const [apiUrl, setApiUrl] = useState("");
  const [addUrl, setAddUrl] = useState(false);

  function handleOpenUrlInput(index) {
    setAddUrl(index);
    setApiUrl("");
  }
  const prevStatus = usePrevious(creatorUpdateStatus);

  useEffect(() => {
    if (prevStatus !== creatorUpdateStatus) {
      setApiUrl("");
      setAddUrl(false);
    }
  }, [creatorUpdateStatus]);

  return (
    <ul className="divide-y divide-gray-100 ">
      {topics.map((topic, index) => (
        <li key={topic.topic.id} className="">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateTopic(apiUrl, topic.id, topic.topic.id);
            }}
          >
            <div className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                <div
                  onClick={() => handleOpenUrlInput(null)}
                  className="flex items-start gap-x-3"
                >
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {topic.topic.title}
                  </p>
                  <span>
                    {convertUnicodeToReference(topic.topic.emoji_code)}
                  </span>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p className="truncate">Created by {topic.created}</p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="whitespace-nowrap">
                    on {formatDate(topic.topic.date_created)}
                  </p>
                </div>
              </div>
              {!reader ? (
                <div className="flex flex-none items-center gap-x-4">
                  {addUrl === index ? (
                    <div className="flex items-center gap-x-2">
                      <button
                        onClick={() => setAddUrl(false)}
                        type="button"
                        className="rounded-full bg-[#000066] p-1 text-white shadow-sm hover:bg-[#000066] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000066]"
                      >
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      <button
                        type="submit"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block coursor:pointer"
                      >
                        Save topic
                        <span className="sr-only">, {topic.topic.name}</span>
                      </button>
                    </div>
                  ) : (
                    <OptionBtn
                      removetopic={() => handleRemoveModal(topic.id)}
                      editTopic={() => handleOpenUrlInput(index)}
                    />
                  )}
                </div>
              ) : (
                <button
                  onClick={() =>
                    (window.location.href = `/topics/details/${topic.topic.id}`)
                  }
                  type="button"
                  className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block coursor:pointer"
                >
                  Veiw topic
                  <span className="sr-only">, {topic.name}</span>
                </button>
              )}
            </div>

            {addUrl === index && (
              <div
                className={`${
                  addUrl === index
                    ? "max-h-200px px-3 pb-1.5 pt-2.5"
                    : "p-0 h-0"
                } duration-500 transition-all rounded-md  shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600}`}
              >
                <label
                  htmlFor="url"
                  className="text-left block text-xs font-medium text-gray-900"
                >
                  URL
                </label>
                <input
                  required
                  type="url"
                  name="apiUrl"
                  id="apiUrl"
                  value={apiUrl}
                  onChange={(e) => setApiUrl(e.target.value)}
                  className="block w-full border-0 p-0 text-gray-900 focus:outline-none placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="https://www.yourtopicurl.com"
                />
              </div>
            )}
          </form>
        </li>
      ))}
    </ul>
  );
}

function OptionBtn({ removetopic, editTopic }) {
  return (
    <Menu as="div" className="relative  inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Options
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute bg-white right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              <div
                onClick={editTopic}
                className={classNames(
                  "text-gray-700 group flex items-center px-4 py-2 text-sm"
                )}
              >
                <PencilSquareIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Edit
              </div>
            </Menu.Item>

            <Menu.Item>
              <div
                onClick={removetopic}
                className={classNames(
                  "text-gray-700 group flex items-center px-4 py-2 text-sm"
                )}
              >
                <TrashIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Delete
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
