import {
  ChartPieIcon,
  NoSymbolIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";

export default function CreatorsStatCard({
  stat1 = 0,
  stat2 = 0,
  stat3 = 0,
  openInactivePubModal,
}) {
  const stats = [
    {
      id: 1,
      name: "Total Publishers",
      stat: stat1,
      icon: PencilSquareIcon,
    },
    {
      id: 2,
      name: "Active Publishers",
      stat: stat2,
      icon: ChartPieIcon,
    },
    {
      id: 3,
      name: "Inactive Publishers",
      stat: stat3,
      icon: NoSymbolIcon,
      view: true,
    },
  ];

  return (
    <div>
      <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-[#030366] px-4  pt-5 shadow sm:px-6 sm:pt-6"
          >
            <div>
              <div className="absolute rounded-md bg-[#fff] p-3">
                <item.icon
                  className="h-6 w-6 text-[#030366]"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-[#fff]">
                {item.name}
              </p>
            </div>
            <div className="ml-16 flex items-baseline pb-16 sm:pb-16">
              <p className="text-2xl font-semibold text-[#FFF]">{item.stat}</p>
              {item.view && (
                <div
                  onClick={() => openInactivePubModal()}
                  className="absolute inset-x-0 bottom-0 bg-[#cccce0] px-4 py-4 sm:px-6 cursor-pointer"
                >
                  <div className="text-sm">
                    <p className="font-medium text-[#000066] hover:text-[#000066]">
                      View
                      <span className="sr-only"> {item.name} stats</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
