import { PlusIcon } from "@heroicons/react/20/solid";
import PostLogin from "../../layout/post-login";
import TopicsList from "../../components/topic-list";
import AllTopics from "../../components/all-topic-list";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  addCreatorsTopic,
  getCreatorFollowers,
  getCreatorStat,
  getSingleCreator,
  removeCreatorsTopic,
  updateCreatorTopic,
  uploadCreatorImage,
} from "../../store/creator";
import { formatDate, getLastSeen } from "../../utils/functions/formatDate";
import EmptyState from "../../components/emptyState";
import { PencilIcon } from "@heroicons/react/24/outline";

import Alert from "../../components/alert";
import { usePrevious } from "../../utils/functions/util";

import DeactivatePublisherDialog from "../../components/creators/deactivate-creator-dialog";
import { toggleUserActiveStatus } from "../../store/users";
import { apiUrl } from "../../utils/config";
import DeleteDialog from "../../components/delete-dialog";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CreatorsDetails() {
  const dispatch = useDispatch();
  const params = useParams();

  const topicModalRef = useRef("");
  const deactivatePubModalRef = useRef("");
  const deletePublisherTopicRef = useRef("");

  const creator = useSelector((state) => state.creators.singleCreator);
  const stat = useSelector((state) => state.creators.singleCreatorStat);
  const followers = useSelector(
    (state) => state.creators.singleCreatorFollower
  );
  const creatorUpdateStatus = useSelector(
    (state) => state.creators.creatorUpdateStatus
  );

  const [removeTopicId, setRemoveTopicId] = useState(null);

  useEffect(() => {
    document.title = "Creator Details - Admin Vyse News";
    dispatch(getSingleCreator({ id: params.publisherId }));
    dispatch(getCreatorStat({ id: params.publisherId }));
    dispatch(getCreatorFollowers({ id: params.publisherId }));
  }, [dispatch, params]);

  const prevStatus = usePrevious(creatorUpdateStatus);
  useEffect(() => {
    if (creatorUpdateStatus !== prevStatus) {
      dispatch(getSingleCreator({ id: params.publisherId }));
    }
  }, [dispatch, creatorUpdateStatus]);

  function addTopic(topicId, apiUrl) {
    dispatch(
      addCreatorsTopic({
        api_url: apiUrl,
        topic_id: topicId,
        creator_id: creator.id,
      })
    );
  }

  function updateTopic(apiUrl, creatorTopicId, topicId) {
    dispatch(
      updateCreatorTopic({
        apiUrl,
        topicId,
        creatorTopicId,
      })
    );
  }

  function removeTopic() {
    if (removeTopicId) {
      dispatch(removeCreatorsTopic({ id: removeTopicId }));
    }
    setRemoveTopicId(null);
    return deletePublisherTopicRef.current.toggleModal(false);
  }

  function handleUploadImage(imgFile, userId) {
    dispatch(
      uploadCreatorImage({
        userId,
        imgFile,
      })
    );
  }

  function handleToggleAcitiveStatus() {
    dispatch(
      toggleUserActiveStatus({
        userId: creator.user.id,
        userUpdate: {
          is_active: !creator.user.is_active,
        },
      })
    );
    return deactivatePubModalRef.current.toggleModal(false);
  }

  return (
    <PostLogin>
      {Object.keys(creator).length !== 0 && (
        <>
          <Alert />

          <AllTopics
            handleAddTopic={(id, apiUrl) => addTopic(id, apiUrl)}
            ref={topicModalRef}
          />

          <DeactivatePublisherDialog
            ref={deactivatePubModalRef}
            handleDeactivate={() => handleToggleAcitiveStatus()}
          />

          <DeleteDialog
            ref={deletePublisherTopicRef}
            title="Delete Publisher's Topic"
            text="Are you sure you want to DELETE this topic? This topic will be be permanently removed for this publisher. This action cannot be undone."
            handleDelete={() => removeTopic()}
          />

          <header>
            {/* Heading */}
            <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-[#e6e6f0] px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 border-b border-[#c4c4c4] items-center justify-center">
              <div>
                <div className="flex items-center gap-x-3">
                  <h1 className="flex gap-x-3 text-base leading-7">
                    <span className="font-semibold text-[#101828]">
                      Publisher
                    </span>
                    <span className="text-gray-600">/</span>
                    <span className="font-semibold text-[#101828]">
                      Details
                    </span>
                  </h1>
                </div>
                <p className="mt-2 text-xs leading-6 text-gray-600">
                  Publisher's personal information and topics
                </p>
              </div>
              <div className="cursor-pointer order-first flex-none rounded-full bg-[#000066]/10 px-2 py-1 text-xs font-medium text-[#000066] ring-1 ring-inset ring-[#000066]/30 sm:order-none">
                <Link to={"/users/details/" + creator.user.id}>View User</Link>
              </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-2 bg-[#e6e6f0] lg:grid-cols-3">
              <div
                className={classNames(
                  "border-r border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Date joined
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {formatDate(creator.user.access_begin)}
                  </span>
                </p>
              </div>
              <div
                className={classNames(
                  "border-r border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Last seen
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {getLastSeen(creator.user.last_login)}
                  </span>
                </p>
              </div>
              <div
                className={classNames(
                  "border-r border-t border-b border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Total Views
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {stat.total_views}
                  </span>
                </p>
              </div>
              <div
                className={classNames(
                  "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Total Shares
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {stat.total_shares}
                  </span>
                </p>
              </div>
              <div
                className={classNames(
                  "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Total Likes
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {stat.total_likes}
                  </span>
                </p>
              </div>
              <div
                className={classNames(
                  "border-r border-t border-[#c4c4c4] py-6 px-4 sm:px-6 lg:px-8"
                )}
              >
                <p className="text-sm font-medium leading-6 text-gray-600">
                  Followers
                </p>
                <p className="mt-2 flex items-center gap-x-2">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    {followers.total}
                  </span>
                </p>
              </div>
            </div>

            {/* publishers details */}
            <div
              className={classNames(
                "flex items-center  flex-wrap justify-between mt-8"
              )}
            >
              <div className="flex items-start gap-x-8">
                <div className={classNames("flex items-center  gap-x-4")}>
                  <div className="h-14 w-14 rounded-full relative overflow-hidden">
                    <img
                      className="h-full w-full rounded-full flex-none object-cover bg-gray-50"
                      src={
                        creator.user?.profile?.photo_file
                          ? `${apiUrl}/users/${creator.user.id}/download-photo`
                          : creator.user?.profile?.avatar_url
                      }
                      alt=""
                    />
                    <label
                      htmlFor="file-input"
                      className="w-full h-4 flex items-center justify-center py-1 absolute bottom-0 bg-[#000066]/30  hover:h-full transition-all duration-300"
                    >
                      <PencilIcon
                        className="h-4 w-4 text-white"
                        aria-hidden="true"
                      />
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      name="image"
                      onChange={(e) => {
                        handleUploadImage(e.target.files[0], creator.user.id);
                      }}
                      className="hidden"
                    />
                  </div>

                  <div className="min-w-0">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {creator.user.profile.first_name_raw}{" "}
                      {creator.user.profile.last_name_raw}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {creator.user.email}
                    </p>
                  </div>
                </div>
                {creator.user.is_active ? (
                  <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    <svg
                      className="h-1.5 w-1.5 fill-green-400"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    Active
                  </span>
                ) : (
                  <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                    <svg
                      className="h-1.5 w-1.5 fill-red-400"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    In Active
                  </span>
                )}
              </div>
              <div>
                {creator.user.is_active ? (
                  <button
                    onClick={() =>
                      deactivatePubModalRef.current.toggleModal(true)
                    }
                    type="button"
                    className="mt-6 rounded-md bg-white px-3 py-2 text-sm font-semibold text-rose-500 shadow-sm ring-1 ring-inset ring-rose-400 hover:bg-gray-50"
                  >
                    Deactivate publisher
                  </button>
                ) : (
                  <button
                    onClick={() => handleToggleAcitiveStatus()}
                    type="button"
                    className="mt-6 rounded-md bg-white px-3 py-2 text-sm font-semibold text-green-700 shadow-sm ring-1 ring-inset ring-green-600/20 hover:bg-gray-50"
                  >
                    Activate publisher
                  </button>
                )}
              </div>
            </div>
          </header>
          <div className="mt-8 sm:mt-16">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-lg">Publisher's Topics</h2>
              <button
                onClick={() => topicModalRef.current.toggleModal()}
                type="button"
                className="rounded-full bg-[#000066] p-1.5 text-white shadow-sm hover:bg-[#000066da] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            {creator?.creator_topics.length ? (
              <TopicsList
                topic
                handleUpdateTopic={(url, creatorId, topicId) =>
                  updateTopic(url, creatorId, topicId)
                }
                handleRemoveModal={(topicId) => {
                  setRemoveTopicId(topicId);
                  deletePublisherTopicRef.current.toggleModal(true);
                }}
                topics={creator?.creator_topics}
              />
            ) : (
              <EmptyState
                btnFunction={() => topicModalRef.current.toggleModal()}
                StateText="topic"
                canAdd
              />
            )}
          </div>
        </>
      )}
    </PostLogin>
  );
}
