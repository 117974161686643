import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Configuration, StoriesApiFactory } from "vyse-api-client";
import { apiUrl } from "../utils/config";
import { axios } from "../utils/http";
import { togglePageLoader } from "../components/loader";
import { notify } from "../components/alert";

const config = new Configuration({
  basePath: apiUrl,
});

export const getAllStories = createAsyncThunk(
  "stories/allStories",
  async (payload) => {
    try {
      const response = await StoriesApiFactory(
        config,
        undefined,
        axios
      ).getStories(
        payload.date,
        payload.direction,
        payload.title,
        undefined,
        payload.creator_id,
        undefined,
        payload.topics_id,
        undefined,
        payload.created_by_user_id,
        undefined,
        undefined,
        undefined,
        payload?.returnOnlyPublished,
        payload?.returnActive,
        payload?.return_featured,
        payload?.return_alert_readers,
        payload.from,
        payload.to
      );

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getStory = createAsyncThunk(
  "stories/singleStory",
  async (payload) => {
    try {
      const response = await StoriesApiFactory(
        config,
        undefined,
        axios
      ).getStory(payload.id);

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const getAbuseReport = createAsyncThunk(
  "stories/getReport",
  async (payload) => {
    try {
      const response = await StoriesApiFactory(
        config,
        undefined,
        axios
      ).getReports(
        "DATE_CREATED",
        "DESC",
        undefined,
        undefined,
        payload?.storyId,
        payload.readerId,
        undefined,
        undefined,
        payload.from,
        payload.to
      );
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        // throw error;
      }
    }
  }
);

export const deleteStory = createAsyncThunk(
  "stories/deleteStory",
  async (payload) => {
    try {
      const response = await StoriesApiFactory(
        config,
        undefined,
        axios
      ).softDeleteStory(payload?.storyId);

      if (response.status === 200) {
        notify("success", response?.data?.detail);
        window.location.href = "/stories";
      }
    } catch (error) {
      notify("error", error.response?.data?.detail);
    }
  }
);

export const sendPushNotification = createAsyncThunk(
  "stories/sendPushNotification",
  async (payload) => {
    try {
      const response = await StoriesApiFactory(
        config,
        undefined,
        axios
      ).sendPushNotificationsToSubscribedTopicReaders(payload?.storyId, true);

      if (response.status === 200) {
        notify("success", "Notification sent to subscribed topic readers");
      }
    } catch (error) {
      notify(
        "error",
        error.response?.data?.detail[0]?.msg ?? error.response?.data?.detail
      );
    }
  }
);

export const updateStory = createAsyncThunk(
  "stories/updateStory",
  async (payload) => {
    try {
      const response = await StoriesApiFactory(
        config,
        undefined,
        axios
      ).updateStory(payload?.storyId, payload.body, undefined, undefined);

      if (response.status === 200) {
        notify("success", "Story has been marked featured");
      }
    } catch (error) {
      notify(
        "error",
        error.response?.data?.detail[0]?.msg ?? error.response?.data?.detail
      );
    }
  }
);

export const storiesSlices = createSlice({
  name: "stories",
  initialState: {
    allStories: [],
    totalStories: 0,
    report: [],
    story: {},
  },
  extraReducers: (builder) => {
    // builder.addCase(getAllStories.pending, togglePageLoader);
    builder.addCase(getAllStories.fulfilled, (state, action) => {
      state.allStories = action.payload.stories;
      state.totalStories = action.payload.total;
      // togglePageLoader();
    });

    builder.addCase(getStory.pending, togglePageLoader);
    builder.addCase(getStory.fulfilled, (state, action) => {
      state.story = action.payload;
      togglePageLoader();
    });

    builder.addCase(getAbuseReport.pending);
    builder.addCase(getAbuseReport.fulfilled, (state, action) => {
      state.report = action.payload.reports;
    });

    builder.addCase(deleteStory.pending, togglePageLoader);
    builder.addCase(deleteStory.fulfilled, (state, action) => {
      // state.story = action.payload;
      togglePageLoader();
    });

    builder.addCase(sendPushNotification.pending, togglePageLoader);
    builder.addCase(sendPushNotification.fulfilled, (state, action) => {
      // state.story = action.payload;
      togglePageLoader();
    });
    builder.addCase(updateStory.pending, togglePageLoader);
    builder.addCase(updateStory.fulfilled, (state, action) => {
      state.story = action.payload;
      togglePageLoader();
    });
  },
});

export default storiesSlices.reducer;
