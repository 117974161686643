import {
  BookOpenIcon,
  DocumentCheckIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
// import { Link } from "react-router-dom";

export default function ReadersStatCard({ stat1 = 0, stat2 = 0, stat3 = 0 }) {
  const stats = [
    {
      id: 1,
      name: "Total Readers",
      stat: stat1,
      icon: BookOpenIcon,
    },
    {
      id: 2,
      name: "Guest Readers",
      stat: stat2,
      icon: QuestionMarkCircleIcon,
    },
    {
      id: 3,
      name: "Known Readers",
      stat: stat3,
      icon: DocumentCheckIcon,
    },
  ];

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-[#030366] px-4  pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-[#fff] p-3">
                <item.icon
                  className="h-6 w-6 text-[#030366]"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-white">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-white">{item.stat}</p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
