import Story from "../../components/stories/story";
import PostLogin from "../../layout/post-login";

export default function StoryDetails() {
  document.title = "Story Details - Admin Vyse News";

  return (
    <PostLogin>
      <Story />
    </PostLogin>
  );
}
