import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReaders,
  getAnonymousReaders,
  getKnownReaders,
} from "../../store/reader";
// import { usePrevious } from "../../utils/functions/util";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ReadersFilter({
  readersQuery,
  setReadersQuery,

  setTableData,
  range,
}) {
  const dispatch = useDispatch();
  const allReaders = useSelector((state) => state.readers.allReaders);
  const totalReader = useSelector((state) => state.readers.totalReaders);
  const knownReaders = useSelector((state) => state.readers.knownReaders);
  const totalKnownReaders = useSelector(
    (state) => state.readers.totalKnownReaders
  );
  const anonynmousReaders = useSelector(
    (state) => state.readers.anonynmousReaders
  );
  const totalAnonynmousReaders = useSelector(
    (state) => state.readers.totalAnonymousReaders
  );

  const [filters, setFilters] = useState([
    { key: "", value: "true", label: "Guest Readers", checked: false },
    { key: "", value: "false", label: "Known Readers", checked: false },
  ]);
  const [sortOptions, setSortOptions] = useState([
    {
      key: "direction",
      value: "ASC",
      name: "Ascending",
      current: false,
    },
    {
      key: "direction",
      value: "DESC",
      name: "Descending",
      current: true,
    },
  ]);

  useEffect(() => {
    const setTabelDataOnfilterChange = () => {
      for (let index = 0; index < filters.length; index++) {
        const filter = filters[index];

        if (filter.label === "Guest Readers" && filter.checked) {
          setTableData((previousState) => ({
            ...previousState,
            data: anonynmousReaders,
            totalItem: totalAnonynmousReaders,
          }));
          return;
        } else if (filter.label === "Known Readers" && filter.checked) {
          setTableData((previousState) => ({
            ...previousState,
            data: knownReaders,
            totalItem: totalKnownReaders,
          }));
          return;
        } else {
          setTableData((previousState) => ({
            ...previousState,
            data: allReaders,
            totalItem: totalReader,
          }));
        }
      }
    };
    setTabelDataOnfilterChange();
  }, [filters, anonynmousReaders, allReaders, knownReaders]);

  useEffect(() => {
    const fetchOnPaginationUpdate = async () => {
      for (let index = 0; index < filters.length; index++) {
        const filter = filters[index];

        if (filter.label === "Guest Readers" && filter.checked) {
          await dispatch(
            getAnonymousReaders({
              ...range,
              orderDirection: readersQuery.direction,
            })
          ).unwrap();

          return;
        } else if (filter.label === "Known Readers" && filter.checked) {
          await dispatch(
            getKnownReaders({
              ...range,
              orderDirection: readersQuery.direction,
            })
          ).unwrap();

          return;
        } else {
          await dispatch(
            getAllReaders({ ...range, orderDirection: readersQuery.direction })
          ).unwrap();
        }
      }
    };

    fetchOnPaginationUpdate();
  }, [range, dispatch, readersQuery]);

  const handleFilterCheck = (inputObject) => {
    const { checkboxValue, labelInput } = inputObject;

    const updatedFilter = filters.map(({ key, value, label }) => ({
      key,
      value,
      label,
      checked: label === labelInput ? checkboxValue : false,
    }));

    setFilters([...updatedFilter]);
  };

  const handleSortOption = (nameInput, key, value) => {
    const updatedSortOptions = sortOptions.map(({ name, key, value }) => ({
      name,
      key,
      value,
      current: name === nameInput && true,
    }));
    setSortOptions(updatedSortOptions);
    setReadersQuery({ ...readersQuery, [key]: value });
  };

  const resetFilter = () => {
    setReadersQuery({
      direction: "DESC",
      date: "DATE_CREATED",
    });
    setFilters([
      { key: "", value: "true", label: "Guest Readers", checked: false },
      { key: "", value: "false", label: "Known Readers", checked: false },
    ]);
  };

  return (
    <div className="bg-white w-full sm:w-full">
      {/* Filters */}
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="grid items-center border-b border-t border-gray-200"
      >
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                <FunnelIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Filters
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              <button
                onClick={() => resetFilter()}
                type="button"
                className="text-gray-500"
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-10">
          <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Readers</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`price-${optionIdx}`}
                        name="price[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        checked={option.checked}
                        onChange={(e) => {
                          handleFilterCheck({
                            checkboxValue: e.target.checked,
                            labelInput: option.label,
                            optionName: "Readers",
                          });
                          // onChangeFilter(e, option.key);
                        }}
                      />
                      <label
                        htmlFor={`price-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </Disclosure.Panel>
        <div className="col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block">
              <div className="flex">
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option, optionIdx) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <span
                            onClick={() =>
                              handleSortOption(
                                option.name,
                                option.key,
                                option.value
                              )
                            }
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {option.name}
                          </span>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </Disclosure>
    </div>
  );
}
