import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeatures, getVersions } from "../../store/features";
import { useState } from "react";
import PostLogin from "../../layout/post-login";
import Pagination from "../../components/new-pagination";
import PageTab from "../../components/features/page-tabs";
import AddNewFeature from "../../components/features/new-feature-form";
import { useRef } from "react";

export default function Features() {
  const dispatch = useDispatch();
  const features = useSelector((state) => state.features.allfeatures);
  const totalfeature = useSelector((state) => state.features.totalfeatures);
  const addNewFeautureStatus = useSelector(
    (state) => state.features.addNewFeautureStatus
  );

  const [range, setRange] = useState({ from: 0, to: 10 });
  useEffect(() => {
    document.title = "Features - Admin Vyse News";
    dispatch(getFeatures({ ...range, useLoader: true }));
    dispatch(getVersions({ ...range }));
  }, [dispatch, range]);

  const newFeatureModal = useRef();

  return (
    <PostLogin>
      <AddNewFeature ref={newFeatureModal} />
      <PageTab
        range={range}
        openFeatureModal={(value) => newFeatureModal.current.toggleModal(value)}
      />

      <Pagination
        totalItems={totalfeature}
        itemsOnDisplay={features.length}
        range={range}
        setRange={setRange}
        tableName="feature"
      />
    </PostLogin>
  );
}
