import { XMarkIcon } from "@heroicons/react/24/outline";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleUserActiveStatus } from "../../store/users";
import { apiUrl } from "../../utils/config";

const InActiveCreatorList = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const inActiveCreators = useSelector(
    (state) => state.creators.inActiveCreators
  );

  useImperativeHandle(ref, () => ({
    toggleModal(value) {
      setOpen(value);
    },
  }));

  function handleToggleAcitiveStatus(userId, activeStatus) {
    dispatch(
      toggleUserActiveStatus({
        userId: userId,
        userUpdate: {
          is_active: activeStatus,
        },
      })
    );
  }

  return (
    <>
      {open && (
        <div className="z-50 fixed inset-0  grid place-items-end sm:place-items-center">
          <div
            onClick={() => setOpen(false)}
            className="absolute h-full w-full bg-black/30"
          ></div>
          <div className="relative z-[60] min-h-[80%] sm:max-h-[90%] w-full sm:w-[80%] lg:w-[55%]  mx-auto bg-white p-5 lg:p-14 overflow-auto rounded-md">
            <XMarkIcon
              onClick={() => setOpen(false)}
              className="cursor-pointer absolute top-5 right-5 sm:top-10 sm:right-10 h-6 w-6 text-gray-900 hover:ring-2 hover:ring-offset-2 rounded-md"
            />
            <p className="text-center font-semibold text-base sm:text-lg text-gray-900 mb-8">
              Inactive Publishers
            </p>
            <ul className="divide-y divide-gray-100">
              {inActiveCreators.map((publisher) => (
                <li
                  key={publisher.user.email}
                  className="flex items-center justify-between gap-x-6 py-5"
                >
                  <div className="flex gap-x-4">
                    <img
                      className="h-10 w-10 sm:h-12 sm:w-12 flex-none rounded-full bg-gray-50"
                      src={
                        publisher.user?.profile?.photo_file
                          ? `${apiUrl}/users/${publisher.user.id}/download-photo`
                          : publisher.user?.profile?.avatar_url
                      }
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {publisher.user.profile.first_name_raw}{" "}
                        {publisher.user.profile.last_name_raw}
                      </p>
                      <p className="mt-0 sm:mt-1 truncate text-xs leading-5 text-gray-500">
                        {publisher.user.email}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      handleToggleAcitiveStatus(
                        publisher.user.id,
                        !publisher.user.is_active
                      )
                    }
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block coursor:pointer"
                  >
                    Activate
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
});

export default InActiveCreatorList;
