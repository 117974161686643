import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import usersReducer from "./users";
import creatorsReducer from "./creator";
import readersReducer from "./reader";
import topicReducer from "./topics";
import storiesReducer from "./stories";
import featuresReducer from "./features";

export default configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    creators: creatorsReducer,
    readers: readersReducer,
    topics: topicReducer,
    stories: storiesReducer,
    features: featuresReducer,
  },
});
