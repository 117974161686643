import { Fragment, useCallback, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCreators } from "../../store/creator";
import { getTopics } from "../../store/topics";
import { convertUnicodeToReference } from "../../utils/functions/util";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const initialFilter = {
  published: [
    {
      key: "returnOnlyPublished",
      value: "true",
      label: "Published stories",
      checked: false,
    },
    {
      key: "returnOnlyPublished",
      value: "false",
      label: "Unpublished stories",
      checked: false,
    },
  ],
  featured: [
    {
      key: "return_featured",
      value: "true",
      label: "Featured stories",
      checked: false,
    },
    {
      key: "return_featured",
      value: "false",
      label: "Not featured stories",
      checked: false,
    },
  ],

  publishers: [],
  topics: [],
  others: [
    {
      key: "return_alert_readers",
      value: true,
      label: "Stories that trigger alert",
      checked: false,
    },
    {
      key: "return_alert_readers",
      value: false,
      label: "Stories that don't trigger alert",
      checked: false,
    },
  ],
};

const initialSort = [
  {
    key: "direction",
    value: "ASC",
    name: "Ascending",
    current: false,
  },
  {
    key: "direction",
    value: "DESC",
    name: "Descending",
    current: true,
  },
];

export default function FeaturesFilter({ storiesQuery, setStoriesQuery }) {
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics.allTopics);
  const creators = useSelector((state) => state.creators.allCreators);
  const [filters, setFilters] = useState(initialFilter);
  const [sortOptions, setSortOptions] = useState(initialSort);

  useEffect(() => {
    dispatch(getTopics({ from: 0, to: 20 }));
    dispatch(getAllCreators({ orderDirection: "DESC", from: 0, to: 20 }));
  }, [dispatch]);

  const populateTopicFilterOption = useCallback(() => {
    let topicsOptions = [];
    for (let index = 0; index < topics.length; index++) {
      topicsOptions.push({
        value: topics[index].id,
        key: "topics_id",
        label:
          topics[index].title +
          " " +
          convertUnicodeToReference(topics[index].emoji_code),
        checked: false,
      });
    }
    setFilters((prev) => ({
      ...prev,
      topics: [...topicsOptions],
    }));
  }, [topics]);

  const populateCreatorsFilterOption = useCallback(() => {
    let creatorsOptions = [];
    for (let index = 0; index < creators.length; index++) {
      creatorsOptions.push({
        value: creators[index].id,
        key: "creator_id",
        label: `${creators[index].user.profile.first_name_raw} ${creators[index].user.profile.last_name_raw}`,
        checked: false,
      });
    }
    setFilters((prev) => ({
      ...prev,
      publishers: [...creatorsOptions],
    }));
  }, [creators]);

  useEffect(() => {
    populateCreatorsFilterOption();

    populateTopicFilterOption();
  }, [populateCreatorsFilterOption, populateTopicFilterOption]);

  const handleFilterCheck = (inputObject) => {
    const { checkboxValue, labelInput, optionName } = inputObject;

    const updatedFilter = {
      ...filters,
      [optionName]:
        optionName === "topics"
          ? filters[optionName].map(({ key, value, label, checked }) => ({
              key,
              value,
              label,
              checked: label === labelInput ? checkboxValue : checked,
            }))
          : filters[optionName].map(({ key, value, label }) => ({
              key,
              value,
              label,
              checked: label === labelInput ? checkboxValue : false,
            })),
    };
    setFilters({ ...updatedFilter });
  };

  const onChangeFilter = (e, key) => {
    const queryUpdate = { ...storiesQuery };

    if (e.target.checked) {
      if (key === "topics_id") {
        const selectedTopics = queryUpdate["topics_id"];
        selectedTopics.push(e.target.value);
        queryUpdate["topics_id"] = selectedTopics;
      } else {
        queryUpdate[key] = e.target.value;
      }
    } else {
      if (key === "topics_id") {
        const selectedTopics = queryUpdate["topics_id"];
        selectedTopics.splice(selectedTopics.indexOf(e.target.value), 1);
        queryUpdate["topics_id"] = selectedTopics;
      } else {
        queryUpdate[key] = undefined;
      }
    }
    setStoriesQuery(queryUpdate);
  };

  const handleSortOption = (nameInput, key, value) => {
    const updatedSortOptions = sortOptions.map(({ name, key, value }) => ({
      name,
      key,
      value,
      current: name === nameInput && true,
    }));

    setSortOptions(updatedSortOptions);
    setStoriesQuery({ ...storiesQuery, [key]: value });
  };

  const resetFilter = () => {
    let updatedFilter = {};

    Object.keys(filters).map((optionName) => {
      updatedFilter[optionName] = filters[optionName].map(
        ({ key, value, label }) => ({
          key,
          value,
          label,
          checked: false,
        })
      );
    });

    const updatedSortOptions = sortOptions.map(({ name, key, value }) => ({
      name,
      key,
      value,
      current: false,
    }));

    setSortOptions(updatedSortOptions);

    setFilters(updatedFilter);
    setStoriesQuery({
      direction: "DESC",
      date: "DATE_CREATED",
      title: "",
      creator_id: undefined,
      topics_id: [],
      return_alert_readers: undefined,
      return_featured: undefined,
      created_by_user_id: undefined,
    });
  };

  return (
    <div className="bg-white w-full sm:w-full">
      {/* Filters */}
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="grid items-center border-b border-t border-gray-200"
      >
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                <FunnelIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Filters
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              <button
                onClick={() => resetFilter()}
                type="button"
                className="text-gray-500"
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-10">
          <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Published</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.published.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`price-${optionIdx}`}
                        name="price[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        checked={option.checked}
                        onChange={(e) => {
                          handleFilterCheck({
                            checkboxValue: e.target.checked,
                            labelInput: option.label,
                            optionName: "published",
                          });
                          onChangeFilter(e, option.key);
                        }}
                      />
                      <label
                        htmlFor={`price-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Featured</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.featured.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`color-${optionIdx}`}
                        name="color[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        checked={option.checked}
                        onChange={(e) => {
                          handleFilterCheck({
                            checkboxValue: e.target.checked,
                            labelInput: option.label,
                            optionName: "featured",
                          });
                          onChangeFilter(e, option.key);
                        }}
                      />
                      <label
                        htmlFor={`color-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-3 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Publishers</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.publishers.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`size-${optionIdx}`}
                        name="size[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        checked={option.checked}
                        onChange={(e) => {
                          handleFilterCheck({
                            checkboxValue: e.target.checked,
                            labelInput: option.label,
                            optionName: "publishers",
                          });
                          onChangeFilter(e, option.key);
                        }}
                      />
                      <label
                        htmlFor={`size-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Topics</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.topics.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`category-${optionIdx}`}
                        name="category[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        checked={option.checked}
                        onChange={(e) => {
                          handleFilterCheck({
                            checkboxValue: e.target.checked,
                            labelInput: option.label,
                            optionName: "topics",
                          });
                          onChangeFilter(e, option.key);
                        }}
                      />
                      <label
                        htmlFor={`category-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Others</legend>
                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                  {filters.others.map((option, optionIdx) => (
                    <div
                      key={option.value}
                      className="flex items-center text-base sm:text-sm"
                    >
                      <input
                        id={`category-${optionIdx}`}
                        name="category[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        checked={option.checked}
                        onChange={(e) => {
                          handleFilterCheck({
                            checkboxValue: e.target.checked,
                            labelInput: option.label,
                            optionName: "others",
                          });
                          onChangeFilter(e, option.key);
                        }}
                      />
                      <label
                        htmlFor={`category-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-gray-600"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6"></div>
          </div>
        </Disclosure.Panel>
        <div className="col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block">
              <div className="flex">
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option, optionIdx) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <span
                            onClick={() =>
                              handleSortOption(
                                option.name,
                                option.key,
                                option.value
                              )
                            }
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {option.name}
                          </span>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </Disclosure>
    </div>
  );
}
