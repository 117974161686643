import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FeaturesApiFactory, Configuration } from "vyse-api-client";
import { axios } from "../utils/http";
import { apiUrl } from "../utils/config";
import { togglePageLoader } from "../components/loader";
import { notify } from "../components/alert";

const config = new Configuration({
  basePath: apiUrl,
});

export const getFeatures = createAsyncThunk(
  "features/getFeatures",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).getFeatures(
        "DATE_CREATED",
        "DESC",
        payload.from,
        payload.to,
        undefined,
        undefined,
        undefined
      );

      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        throw error;
      }
    }
  }
);

export const getVersions = createAsyncThunk(
  "features/getVersions",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).getVersions(
        "DATE_CREATED",
        "DESC",
        payload.from,
        payload.to,
        undefined,
        undefined
      );
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        throw error;
      }
    }
  }
);

export const getFeature = createAsyncThunk(
  "features/getFeature",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).getFeature(payload.featureId);
      return response.data;
    } catch (error) {
      if (!error.response?.data?.detail) {
        throw error;
      }
    }
  }
);

export const createFeature = createAsyncThunk(
  "features/createFeature",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).createFeature(payload);
      notify("success", "Feature has been created sucessfully");
      return response.status;
    } catch (error) {
      notify("error", error.response?.data?.detail);

      return error.response?.data?.detail;
    }
  }
);

export const updateFeature = createAsyncThunk(
  "features/updateFeature",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).updateFeature(payload.featureId, payload.featureUpdate);
      notify("success", "Feature has been updated sucessfully");
      return response.status;
    } catch (error) {
      notify("error", error.response?.data?.detail);
      return error.response?.data?.detail;
    }
  }
);

export const deleteFeature = createAsyncThunk(
  "features/deleteFeature",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).deleteFeature(payload.featureId);
      notify("success", "Feature has been deleted sucessfully");
      window.location.href = "/features";
      return response.status;
    } catch (error) {
      notify("error", error?.response?.data?.detail);
      return !error.response?.data?.detail;
    }
  }
);

export const createVersion = createAsyncThunk(
  "features/createVersion",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).createVersion(payload.versionNumber);
      notify("success", "Feature has been created sucessfully");
      return response.status;
    } catch (error) {
      notify("error", error.response?.data?.detail);

      return error.response?.data?.detail;
    }
  }
);

export const updateVersion = createAsyncThunk(
  "features/updateVersion",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).updateVersion(payload.versionId, payload.versionNumber);
      notify("success", "Version has been updated sucessfully");
      return response.status;
    } catch (error) {
      notify("error", error?.response?.data?.detail);
      return error.response?.data?.detail;
    }
  }
);

export const deleteVersion = createAsyncThunk(
  "features/deleteVersion",
  async (payload) => {
    try {
      let response = await FeaturesApiFactory(
        config,
        undefined,
        axios
      ).deleteVersion(payload.versionNumber);
      notify("success", "Version has been deleted sucessfully");
      return response.status;
    } catch (error) {
      notify("error", error?.response?.data?.detail);
      return error.response?.data?.detail;
    }
  }
);

export const featureSlice = createSlice({
  name: "features",
  initialState: {
    allfeatures: [],
    singlefeature: {},
    allversion: [],
    totalfeatures: 0,
    totalversions: 0,
    featurePhoto: {},
    requestNewPassword: false,
    featureAcitveStatus: null,
    addNewFeautureStatus: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getFeatures.pending, togglePageLoader);
    builder.addCase(getFeatures.fulfilled, (state, action) => {
      state.allfeatures = action.payload.features;
      state.totalfeatures = action.payload.total;
      togglePageLoader();
    });

    builder.addCase(getVersions.pending);
    builder.addCase(getVersions.fulfilled, (state, action) => {
      state.allversion = action.payload.versions;
      state.totalversions = action.payload.total;
      // togglePageLoader();
    });

    builder.addCase(getFeature.pending, togglePageLoader);
    builder.addCase(getFeature.fulfilled, (state, action) => {
      state.singlefeature = action.payload;
      togglePageLoader();
    });

    builder.addCase(createFeature.pending, togglePageLoader);
    builder.addCase(createFeature.fulfilled, (state, action) => {
      state.addNewFeautureStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(updateFeature.pending, togglePageLoader);
    builder.addCase(updateFeature.fulfilled, (state, action) => {
      state.addNewFeautureStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(updateVersion.pending, togglePageLoader);
    builder.addCase(updateVersion.fulfilled, (state, action) => {
      state.addNewFeautureStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(deleteFeature.pending, togglePageLoader);
    builder.addCase(deleteFeature.fulfilled, (state, action) => {
      state.addNewFeautureStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(deleteVersion.pending, togglePageLoader);
    builder.addCase(deleteVersion.fulfilled, (state, action) => {
      state.addNewFeautureStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });

    builder.addCase(createVersion.pending, togglePageLoader);
    builder.addCase(createVersion.fulfilled, (state, action) => {
      state.addNewFeautureStatus = action.payload === 200 && Math.random();
      togglePageLoader();
    });
  },
});

export default featureSlice.reducer;
